import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Typography, Avatar, Dropdown, Menu, message } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import "./Navbar.css";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { checkLogged } from "../../store/auth/authSlice";
import { getUserData, getRandomColor } from "../../helper";
const { Header } = Layout;

{
  /* <i class="fa-solid fa-bars"></i>; */
}
const Navbar = ({ headerName }) => {
  let token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [logoColor, setLogoColor] = useState("");
  const [logoText, setlogoText] = useState("");
  const navigate = useNavigate();

  const [userdata, setUserData] = useState({});

  const Subbreadcrum =
    useSelector((state) => state?.subBreadCrum?.subBreadCrum) || "Welcome back";

  const authToken = localStorage.getItem("schoolAuth");

  useEffect(() => {
    if (!authToken) {
      setIsAuthenticated(false);
    }
    findUserData();
  }, [authToken]);

  // Logout handler
  // const handleLogout = () => {
  //   localStorage.removeItem('schoolAuth');

  //   //dispatch(checkLogged());

  //   message.success('Logged out successfully!');
  //   // Add your logout functionality here
  //   console.log('User logged out');
  //   navigate('/');
  // };

  const handleLogout = () => {
    message.success("Logout successfully");
    localStorage.clear(process.env.REACT_APP_JWT_TOKEN);
    sessionStorage.clear();
    // setIsAuthenticated(false);
    dispatch(checkLogged());
    window.location.href = "/";
  };

  const findUserData = () => {
    let data = getUserData();
    let color = getRandomColor();
    console.log(color, "color");
    setUserData(data);
    let logo = data?.username[0].toUpperCase();
    setlogoText(logo);
    setLogoColor(color);
  };

  const toggleSidebar = () => {
    // Get the sidebar and burger icon elements
    const sider = document.getElementsByClassName(
      "ant-layout-sider-collapsed"
    )[0];
    const burgerIcon = document.querySelector(".fa-bars");
    const rootbody = document.getElementById("root");
    // Toggle the display of the sidebar
    if (sider) {
      // If the sidebar is visible, hide it, otherwise show it
      if (sider.style.display === "none") {
        sider.style.display = "block"; // Show the sidebar
        // sider.style.width ="25%";
        // rootbody.style.width ="87%";
      } else {
        sider.style.display = "none"; // Hide the sidebar
        // rootbody.style.width ="100%";
      }
    }
  };
  const menu = [
    {
      key: "1",
      label: "Profile",
      icon: <UserOutlined />,
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: handleLogout,
    },
  ];
  // console.log(userdata, "ftfghbj");

  return (
    <Header
      style={{
        background: "white",
        padding: "10px 50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        background: "#F1F8FF",
        border: "1px solid #F0F7FF",
      }}
    >
      {/* Title Section */}

      <span className="d-md-none d-flex">
        <i className="fa fa-bars" onClick={toggleSidebar}></i>
      </span>
      <h2
        style={{
          fontSize: "20px",
          color: "#333",
          fontFamily: "Poppins",
          fontWeight: "600",
        }}
        className="welcomeheader"
      >
        {Subbreadcrum}
      </h2>
      <div
        className="profileconainer"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Dropdown menu={{ items: menu }} trigger={["click"]}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              gap: "10px",
            }}
          >
            <Avatar size={40} style={{ backgroundColor: logoColor }}>
              {logoText}
            </Avatar>
            <div className="navheader">
              <Typography.Text
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#333",
                }}
              >
                {userdata.username}
              </Typography.Text>

              <Typography.Text
                style={{
                  fontSize: "12px",
                  color: "#888",
                }}
              >
                {userdata.role}
              </Typography.Text>
            </div>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default Navbar;
