import React from "react";
import EmployeeDetails from "../Component/EmployeeDetails";

function Acadamicemployee() {
  return (
    <>
      <EmployeeDetails isEdit={false} />
    </>
  );
}

export default Acadamicemployee;
