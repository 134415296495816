import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Button,
  Badge,
  Avatar,
  Select,
  Space,
  notification,
  Tag,
  Row,
  Col,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import TableSortArrows from "../../Component/tableSortArrows";
import { useDispatch } from "react-redux";
// // import { customPagination } from "../helper";
import Pagination from "../../Component/pagination/index.jsx";
import { SUPER_ADMIN } from "../../constants/roles.js";

const { confirm } = Modal;

const allData = [
  {
    key: 1,
    name: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: SUPER_ADMIN,
    phoneNumber: "+91 9876543210",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=1",
    email: "suganthi.thomas.eng00201@example.com",
  },
  {
    key: 2,
    name: "Arjun Reddy",
    employeeID: "ENG00202",
    role: SUPER_ADMIN,
    phoneNumber: "+91 9876543211",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
    email: "arjun.reddy.eng00202@example.com",
  },
  {
    key: 3,
    name: "Meera Kapoor",
    employeeID: "ENG00203",
    role: SUPER_ADMIN,
    phoneNumber: "+91 9876543212",
    assignedvehicle: "TN 19 AA 2906",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    email: "meera.kapoor.eng00203@example.com",
  },
  {
    key: 4,
    name: "Ishita Sharma",
    employeeID: "ENG00204",
    role: SUPER_ADMIN,
    phoneNumber: "+91 9876543213",
    assignedvehicle: "TN 19 AA 2906",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=4",
    email: "ishita.sharma.eng00204@example.com",
  },
  {
    key: 5,
    name: "Ravi Verma",
    employeeID: "ENG00205",
    role: SUPER_ADMIN,
    phoneNumber: "+91 9876543214",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=5",
    email: "ravi.verma.eng00205@example.com",
  },
  {
    key: 6,
    name: "Ayesha Khan",
    employeeID: "ENG00206",
    role: SUPER_ADMIN,
    phoneNumber: "+91 9876543215",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=6",
    email: "ayesha.khan.eng00206@example.com",
  },
  {
    key: 7,
    name: "Karthik Iyer",
    employeeID: "ENG00207",
    role: SUPER_ADMIN,
    phoneNumber: "+91 9876543216",
    assignedvehicle: "TN 19 AA 2906",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=7",
    email: "karthik.iyer.eng00207@example.com",
  },
  {
    key: 8,
    name: "Priya Patel",
    employeeID: "ENG00208",
    role: SUPER_ADMIN,
    phoneNumber: "+91 9876543217",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=8",
    email: "priya.patel.eng00208@example.com",
  },
  {
    key: 9,
    name: "Rahul Gupta",
    employeeID: "ENG00209",
    role: SUPER_ADMIN,
    phoneNumber: "+91 9876543218",
    assignedvehicle: "TN 19 AA 2906",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=9",
    email: "rahul.gupta.eng00209@example.com",
  },
  {
    key: 10,
    name: "Sneha Roy",
    employeeID: "ENG00210",
    role: SUPER_ADMIN,
    phoneNumber: "+91 9876543219",
    assignedvehicle: "TN 19 AA 2906",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=10",
    email: "sneha.roy.eng00210@example.com",
  },
];

function Drivertable({ userData, isEdit, count }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  // const [userData, setUserData] = useState(allData)
  const [driverDataList, setDriverDataList] = useState(allData);
  const [tableData, setTableData] = useState([]);

  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(false);

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const openOnChange = (value, teb) => {
    if (teb == "edit" || teb == "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb == "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };

  useEffect(() => {
    setTotalcount(count);
  }, [count]);
  const confirmDelete = () => {
    confirm({
      // title: <h5>Confirm Delete</h5>,
      // content: <p>Are you sure you want to Delete?</p>,
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() {
        // console.log('Cancel');
      },
      okButtonProps: {
        className: "custom-save-button",
      },
      cancelButtonProps: {
        className: "custom-cancel-button",
      },
    });
  };
  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedDate({});
  };

  const columns = [
    // ...(isEdit
    //   ? [
    //       {
    //         title: <input type="checkbox" />,
    //         dataIndex: "checkbox",
    //         render: () => <input type="checkbox" />,
    //       },
    //     ]
    //   : []),
    {
      title: "Driver Name",
      dataIndex: "name",
      render: (text, record) => (
        <Space>
          <Avatar src={record.avatarUrl} />
          {text}
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Email ID",
      dataIndex: "email",
      width: 100,
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Assigned vehicle",
      dataIndex: "assignedvehicle",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "#EC7C74"}`,
            color: status === "Active" ? "#fff" : "#fff",
            background:
              status === "Active"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const useronChange = async (value) => {
    if (value) {
      let finded = allData.filter((a) => a.role == value);
      setDriverDataList(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setDriverDataList(allData);
      setTotalcount(allData.length);
    }
  };

  const searchOnChange = (value) => {
    const searchTerm = value.toLowerCase();
    const filteredData = allData.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm) ||
        item.phoneNumber.toLowerCase().includes(searchTerm) ||
        item.email.toLowerCase().includes(searchTerm) ||
        item.assignedvehicle.toLowerCase().includes(searchTerm)
    );
    setDriverDataList(filteredData);
    setTotalcount(filteredData.length);
    setSearch(value);
  };

  //pagination
  const objectLength = Object.keys(driverDataList).length;

  return (
    <div>
      <div className="tablecontainer">
        <div className="tableinnercontainer">
          <div className="d-md-flex justify-content-between align-items-center ">
            <div className="search-id">
              <Input
                onChange={(e) => searchOnChange(e.target.value)}
                value={search}
                placeholder="Search by Driver Name"
                prefix={<SearchOutlined />}
                className="seachAccadamicemployee"
              />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          className="table-datas"
          pagination={false}
          style={{ borderRadius: "8px" }}
        />
        <Pagination
          data={driverDataList}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>
    </div>
  );
}

export default Drivertable;
