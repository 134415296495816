import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import { subBreadCrumreducer } from "../reducer/Subbreadcrumreducer";
import employeeDetailsSlice from "./employeeDetails/employeeDetailsSlice";
import studentsDetailsSlice from "./studentDetails/studentDetails";
import contactDetails from "./contactDetails/contactDetails";
import driverDetails from "./driverDetails/driverDetails";
import circularDetails from "./circularDetails/circularDetails";
import eventDetails from "./eventDetails/eventDetails";

import settingsSubjectSlice from "./settings/subject";
import settingsDepartment from "./settings/department";
import settingsClass from "./settings/class";
import settingsInstitute from "./settings/institute";
import settingsVehicle from "./settings/vehicle";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    subBreadCrum: subBreadCrumreducer,
    employee: employeeDetailsSlice,
    subject: settingsSubjectSlice,
    department: settingsDepartment,
    class: settingsClass,
    institute: settingsInstitute,
    vehicle: settingsVehicle,
    driver: driverDetails,
    students: studentsDetailsSlice,
    contactDetails: contactDetails,
    circular: circularDetails,
    events: eventDetails,
  },
});
