import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";

export const getEmployee = createAsyncThunk(
  "employee/getEmployee",
  async (params, { rejectWithValue, fulfillWithValue }) => {
    let query = convertQueryParams(params);

    let payload = {
      method: "get",
      url: `/employee?${query}`,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

export const addEmployee = createAsyncThunk(
  "employee/addEmployee",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    let payload = {
      method: "post",
      url: `/employee`,
      data,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "employee/updateEmployee",
  async ({ data, selectedId }, { rejectWithValue, fulfillWithValue }) => {
    let payload = {
      method: "put",
      url: `/employee/${selectedId}`,
      data,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

const incorpSlice = createSlice({
  name: "employee",
  initialState: {
    status: "idle",
    isLoading: false,
    data: null,
    isError: false,
    error: null,
    message: null,
  },
  reducers: {
    resetEmployeeErrorAndMessage: (state) => {
      state.error = null;
      state.isError = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    // get employee
    builder.addCase(getEmployee.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
    });

    builder.addCase(getEmployee.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload.data;
    });

    builder.addCase(getEmployee.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });

    // add employee
    builder.addCase(addEmployee.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
      state.message = null;
    });

    builder.addCase(addEmployee.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoading = false;
      // console.log(action.payload);
      switch (action.payload.status) {
        case 201:
          state.message = action.payload.message;
          state.isError = false;
          break;
        case 208:
          state.error = action.payload.message;
          state.isError = true;
          break;
        default:
          state.error = "Failed to process request!";
          state.isError = true;
      }
    });

    builder.addCase(addEmployee.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });

    // update employee
    builder.addCase(updateEmployee.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
      state.message = null;
    });

    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.data.data = state.data.data.map((data) => {
        if (action.payload.data.id === data.id) return action.payload.data;
        else return data;
      });

      switch (action.payload.status) {
        case 200:
          state.message = action.payload.message;
          state.isError = false;
          break;
        case 404:
          state.error = action.payload.message;
          state.isError = true;
          break;
        default:
          state.error = "Failed to process request!";
          state.isError = true;
      }
    });

    builder.addCase(updateEmployee.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });
  },
});

export const { resetEmployeeErrorAndMessage } = incorpSlice.actions;
export default incorpSlice.reducer;
