import React, { useState } from "react";
import StudentTable from "../Departmentadmin/Departmenttable";
import { Modal, Table, Input, Row, Form, Col, Select, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import TableSortArrows from "../../Component/tableSortArrows";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { SearchOutlined } from "@ant-design/icons";
import Pagination from "../pagination/index";
import bin from "../../Assets/Svg/bin.png";
import eye from "../../Assets/Svg/eye.png";
import edit from "../../Assets/Svg/edit.png";
import Classtimetableadd from "./Classtimetableadd";
import { TEACHER } from "../../constants/roles";

const { Option } = Select;

const alldatas = [
  {
    key: 46,
    classno: "11 -A",
    teachername: "Nivatha Thomas",
    employeeID: "ENG00201",
    role: TEACHER,
    phoneNumber: "+91 9876543210",
    status: "Active",
    avatarUrl: null,
  },
  {
    key: 47,
    classno: "12 - A",
    teachername: "Aisha Fatima",
    employeeID: "ENG00202",
    role: TEACHER,
    phoneNumber: "+91 9876543211",
    status: "Inactive",
    avatarUrl: "https://i.pravatar.cc/40?img=2",
  },
  {
    key: 48,
    classno: "10 - A",
    teachername: "Arjun Singh",
    employeeID: "ENG00203",
    role: TEACHER,
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 49,
    classno: "6 - A",
    teachername: "Arjun Singh",
    employeeID: "ENG00203",
    role: TEACHER,
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 50,
    classno: "6 - A",
    teachername: "Arjun Singh",
    employeeID: "ENG00203",
    role: TEACHER,
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 51,
    classno: "6 - A",
    teachername: "Arjun Singh",
    employeeID: "ENG00203",
    role: TEACHER,
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: 52,
    classno: "6 - A",
    teachername: "Arjun Singh",
    employeeID: "ENG00203",
    role: TEACHER,
    phoneNumber: "+91 9876543212",
    status: "Active",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
];

function Classtimetable() {
  const [userArray, setUserArray] = useState(alldatas);
  const [ModuleOpen, setModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [teb, setTeb] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [showStudentTable, setShowStudentTable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [userDataList, setUserDataList] = useState(alldatas);
  const [form] = Form.useForm();

  const resetModalState = () => {
    form.resetFields();
    setOpenModal(false);
  };

  const openOnChange = (value, teb) => {
    if (teb === "edit" || teb === "view") {
      setselectedId(value?.key);
      setModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb === "add") {
      setModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    }
  };

  const handleStudentClick = () => {
    setShowStudentTable(true);
  };

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    {
      title: "Class",
      dataIndex: "classno",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",

      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "#EC7C74"}`,
            color: status === "Active" ? "#fff" : "#fff",
            background:
              status === "Active"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <>
          <img
            src={edit}
            alt="Delete"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange({}, "edit");
            }}
          />
          <img
            src={eye}
            alt="Delete"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange({}, "view");
            }}
          />
          <img
            src={bin}
            alt="Delete"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
          />
        </>
      ),
    },
  ];

  const objectLength = Object.keys(userArray).length;

  return (
    <div>
      {!ModuleOpen ? (
        <div className="tablecontainer">
          <div className="nav-sectionBar">
            <div className="d-flex flex-lg-row flex-column align-items-center gap-3">
              <div className="search-id">
                <Input
                  // onChange={(e) => searchOnChange(e.target.value)}
                  // value={search}
                  placeholder="Search by Student Name or ID"
                  prefix={<SearchOutlined />}
                  className="seachAccadamicemployee"
                />
              </div>
              <div className="wholeselect">
                <Select
                  // value={userType || undefined}
                  // onChange={useronChange}
                  className="selectdiv"
                  allowClear
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Acadamic year"
                  style={{ width: "150px" }}
                  dropdownStyle={{ width: "150px" }}
                  options={[
                    {
                      value: "2020",
                      label: "2020",
                    },
                    {
                      value: "2022",
                      label: "2022",
                    },
                  ]}
                />
              </div>

              <div className="btnaligwitselect">
                <div>
                  <div
                    className="addbtn"
                    onClick={() => {
                      openOnChange({}, "add");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="lg"
                      className="icon"
                      style={{ fontSize: "22px" }}
                    />
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Add
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={userDataList}
            tableLayout="fixed"
            className=""
            pagination={false}
          />
          <Pagination
            customClass="d-none d-xl-flex"
            data={userDataList}
            onPageChange={(data) => {
              setTableData(data);
            }}
            initialItemsPerPage={10}
          />
        </div>
      ) : (
        <Classtimetableadd teb={teb} />
      )}
    </div>
  );
}

export default Classtimetable;
