import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message, Table } from "antd";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ViewStudent from "./ViewStudent";
// import { customPagination } from "../../helper";
import Pagination from "../pagination/index";
import { fetchApi } from "../../helper";
import moment from "moment";

const StudentTable = ({
  setIsOpen,
  isOpen,
  settabVisible,
  curClass,
  curSection,
}) => {
  const [userArray, setUserArray] = useState([]);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [teb, setTeb] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [allUserFullData, setAllUserFullData] = useState([]);

  const calculateAge = (dob) => {
    // Parse the date_of_birth string into a Date object
    const birthDate = new Date(dob);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust if the birthday hasn't occurred yet this year
    const monthDifference = currentDate.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const getStudentsData = async (token) => {
    const payload = {
      method: "GET",
      url: `/student?curclass=${curClass}&section=${curSection}&_start=${0}&_limit=${10}`,
      headers: { Authorization: `Bearer ${token}` },
    };

    await fetchApi(payload)
      .then((response) => {
        const studentsData = response?.data?.data.map((student) => {
          return {
            key: student?.student_id,
            name: `${student?.first_name} ${student?.last_name}`,
            grade: `${student?.selected_class} - ${student?.section}`,
            age: calculateAge(student?.date_of_birth),
            Mobileno: "-------",
            Admission: student?.admission_no,
          };
        });
        setAllUserFullData(response?.data?.data);
        setUserArray(studentsData);
      })
      .catch((error) => {
        console.log(error);
        message.error("Error fetching students details");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useState(() => {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

    getStudentsData(token);
  }, [curClass, curSection]);

  const openOnChange = (value, tab) => {
    settabVisible(false);
    if (tab === "view") {
      value = allUserFullData.find((student) => {
        if (value.key === student.student_id) {
          student.date_of_birth = moment(student.date_of_birth);
          student.date_of_admission = moment(student.date_of_admission);
          return student;
        }
      });
      setModuleOpen(true);
      setSelectedData(value);
    } else {
      setModuleOpen(false);
      setSelectedData({});
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    { title: "Admission", dataIndex: "Admission", key: "Admission" },
    { title: "Age", dataIndex: "age", key: "Mobileno" },
    {
      title: "Action",
      dataIndex: "status",
      align: "center",
      render: (_, record) => (
        <div>
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
        </div>
      ),
    },
  ];
  console.log(isOpen, "isopen");

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const objectLength = Object.keys(userArray).length;

  return (
    <>
      {!moduleOpen ? (
        <div className="studenttable">
          {/* <button onClick={() => setIsOpen(!isOpen)}>Back1</button> */}
          {/* <h4>Student Table</h4> */}

          <Table
            loading={loader}
            dataSource={tableData}
            columns={columns}
            pagination={false}
          />
          <Pagination
            data={userArray}
            onPageChange={(data) => {
              setTableData(data);
            }}
            initialItemsPerPage={10}
          />
        </div>
      ) : (
        <ViewStudent
          data={selectedData}
          isOpen={moduleOpen}
          setIsOpen={setModuleOpen}
          settabVisible={settabVisible}
          pagination={false}
        />
      )}
    </>
  );
};

export default StudentTable;
