import React, { useState } from "react";
import noticepng from "../../Assets/png/noticepng.png";
import { Form, Input, Select, Button, message, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { fetchApi } from "../../helper";
import { DRIVER, STUDENT, TEACHER } from "../../constants/roles";

const { Option } = Select;

const Circularopr = ({ record, onClose, type, selectedId }) => {
  const [form] = Form.useForm();
  const [status, setStatus] = useState("");
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [showDepartment, setShowDepartment] = useState(
    !(record?.audience === "ALL" || record?.audience === DRIVER)
  );

  const handleSubmit = (newStatus) => {
    setStatus(newStatus);
    form.submit();
  };

  const formatDataForReq = async (inputValues) => {
    const circularData = {};

    circularData.circularname = inputValues.circularname;
    circularData.audience = inputValues.audience;
    circularData.department = inputValues.department;
    circularData.content = inputValues.content;
    circularData.status = status;

    return circularData;
  };

  const onFinish = async (values) => {
    setIsFormDisabled(true);

    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
    const data = await formatDataForReq(values);
    // console.log(data);

    const payload = {
      method: "post",
      url: type === "add" ? "/circular" : `/circular/${selectedId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    fetchApi(payload)
      .then((response) => {
        switch (response?.status) {
          case 200:
            message.success("Updated circular successfully!");

            setTimeout(() => {
              window.location.reload();
            }, 1000);
            break;
          case 201:
            message.success("Circular created successfully!");

            setTimeout(() => {
              window.location.reload();
            }, 1000);
            break;
          case 208:
            message.error("Circualr with same name already exist!");
            setIsFormDisabled(false);
            break;
          default:
            message.error("Failed to process request!");
            setIsFormDisabled(false);
        }
      })
      .catch((err) => {
        message.error("Failed to process request!");
        console.log("Failed to process request! ", err);
        setIsFormDisabled(false);
      });
  };

  const handleAudienceChange = (value) => {
    // Hide department if "all" or "driver" is selected
    setShowDepartment(!(value === "ALL" || value === DRIVER));
  };

  return (
    <div className="circuarwholeopr">
      <Spin spinning={isFormDisabled}>
        <Form
          form={form}
          initialValues={record || {}}
          disabled={isFormDisabled}
          layout="vertical"
          onFinish={onFinish}
          className="d-md-flex justifycontent-between "
        >
          <div style={{ flex: 1 }}>
            <Form.Item
              label="Subject"
              name="circularname"
              rules={[
                { required: true, message: "Please enter subject title!" },
              ]}
            >
              <Input placeholder="Enter title" className="inputbox" />
            </Form.Item>

            <Form.Item
              label="Audience"
              name="audience"
              rules={[
                { required: true, message: "Please select an audience!" },
              ]}
            >
              <Select
                placeholder="Select Audience"
                className="inputbox"
                suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                onChange={handleAudienceChange} // Handle audience change
              >
                <Option value={STUDENT}>Students</Option>
                <Option value={TEACHER}>Teachers</Option>
                <Option value={DRIVER}>Driver</Option>
                <Option value="ALL">All</Option>
              </Select>
            </Form.Item>

            {showDepartment && (
              <Form.Item
                label="Select Department"
                name="department"
                rules={[
                  { required: true, message: "Please select a department!" },
                ]}
              >
                <Select
                  placeholder="Select Department"
                  className="inputbox"
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                >
                  <Option value="primary">Primary</Option>
                  <Option value="secondary">Secondary</Option>
                  <Option value="higherSecondary">Higher Secondary</Option>
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="Content"
              name="content"
              rules={[{ required: true, message: "Please give a content!" }]}
            >
              <textarea style={{ width: "100%" }}></textarea>
            </Form.Item>
          </div>
          <div style={{ border: "solid 1px white", marginLeft: "20px" }}></div>
          <div
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={noticepng}
              alt="illustration"
              style={{ marginBottom: "20px", width: 200, height: 200 }}
            />
            <Button
              type="primary"
              style={{
                marginBottom: "10px",
                width: "100px",
                background: "#ffcc00",
                borderColor: "#ffcc00",
              }}
              onClick={() => handleSubmit(record.status || "unpublished")}
            >
              Save
            </Button>
            <Button
              type="primary"
              style={{
                marginBottom: "10px",
                width: "100px",
                background: "#00cccc",
                borderColor: "#00cccc",
              }}
              onClick={() =>
                record.status === "published"
                  ? handleSubmit("unpublished")
                  : handleSubmit("published")
              }
            >
              {record.status === "published" ? "Unpublish" : "Publish"}
            </Button>
            <Button
              style={{
                marginBottom: "10px",
                width: "100px",
                background: "#ff6666",
                borderColor: "#ff6666",
                color: "white",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default Circularopr;
