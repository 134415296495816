import React, { useState } from "react";
import {
  Table,
  Avatar,
  Tag,
  // Typography,
  Select,
  Layout,
  Space,
  Card,
  Typography,
  Button,
} from "antd";
import TableSortArrows from "../../Component/tableSortArrows.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Table } from "antd";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ViewStudent from "../../Component/Departmentadmin/Departmenttable.jsx";
// import { customPagination } from "../../helper";
import Pagination from "../../Component/pagination/index.jsx";

const cardData = {
  AnnualLeave: "3/12",
  MedicalLeave: "2/6",
  CasualLeave: "1/6",
};

const data = [
  {
    key: "1",
    Studentname: "John Doe",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Rejected",
  },
  {
    key: "2",
    Studentname: "Jane Smith",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Approved",
  },
  {
    key: "3",
    Studentname: "Alice Johnson",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Approved",
  },
  {
    key: "4",
    Studentname: "John Doe",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Rejected",
  },
  {
    key: "5",
    Studentname: "John Doe",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Approved",
  },
  {
    key: "6",
    Studentname: "John Doe",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Rejected",
  },
  {
    key: "7",
    Studentname: "John Doe",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Rejected",
  },
  {
    key: "8",
    Studentname: "Alice Johnson",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Rejected",
  },
  {
    key: "9",
    Studentname: "Alice Johnson",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Approved",
  },
  {
    key: "10",
    Studentname: "Alice Johnson",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Rejected",
  },
  {
    key: "11",
    Studentname: "Alice Johnson",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Approved",
  },
  {
    key: "12",
    Studentname: "Alice Johnson",
    LeaveType: "Sick Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
    status: "Approved",
  },
];

const LeaveRequest = ({ setIsOpen, isOpen }) => {
  const [userArray, setUserArray] = useState(data);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [teb, setTeb] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  const openOnChange = (value, teb) => {
    if (teb === "view") {
      setModuleOpen(true);
      setSelectedDate(value);
    } else {
      setModuleOpen(false);
      setSelectedDate({});
    }
  };

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    {
      title: "Student Name",
      dataIndex: "Studentname",
      key: "Studentname",
      render: (text, record) => (
        <Space>
          {record.avatarUrl ? (
            <Avatar src={record.avatarUrl} />
          ) : (
            <Avatar size={40} style={{ backgroundColor: record.color }}>
              {record.logo}
            </Avatar>
          )}
          {text}
        </Space>
      ),
      sorter: (a, b) => a.Studentname.localeCompare(b.Studentname),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    { title: "Leave Type", dataIndex: "LeaveType", key: "LeaveType" },
    { title: "Date", dataIndex: "Date", key: "Date" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "Approved" ? "blue" : "red"}>{status}</Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "status",
      align: "center",
      render: (_, record) => (
        <div>
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
        </div>
      ),
    },
  ];
  console.log(isOpen, "isopen");

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const objectLength = Object.keys(userArray).length;

  return (
    <div>
      <>
        {!moduleOpen ? (
          <div className="studenttable">
            {/* <button onClick={() => setIsOpen(!isOpen)}>Back1</button> */}
            {/* <h4>Student Table</h4> */}

            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
            />
            <Pagination
              data={userArray}
              onPageChange={(data) => {
                setTableData(data);
              }}
              initialItemsPerPage={10}
            />
          </div>
        ) : (
          <ViewStudent
            data={selectedDate}
            isOpen={moduleOpen}
            setIsOpen={setModuleOpen}
            // settabVisible={settabVisible}
            pagination={false}
          />
        )}
      </>
    </div>
  );
};
export default LeaveRequest;
