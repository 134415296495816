import React from "react";
import {
  Table,
  Avatar,
  Tag,
  // Typography,
  Select,
  Layout,
  Card,
  Typography,
  Button,
} from "antd";
import LeaveTable from "../../Pages/Teacher/LeaveTable.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

// const { Title } = Typography;
// const { Option } = Select;

const cardData = {
  AnnualLeave: "3/12",
  MedicalLeave: "2/6",
  CasualLeave: "1/6",
};
const Leave = () => {
  return (
    <div>
      <div className="row g-3 pt-4 pb-5">
        {/* Cards */}
        <div className="col-md-4 col-lg-4">
          <Card
            className="class-data"
            style={{
              background: "linear-gradient(160deg, #00ADC4 0%, #00535E 100%)",
              height: "242px !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-flex flex-column align-items-center">
              <div
                className="carddetailscon"
                style={{
                  fontSize: "2rem",
                  fontWeight: "normal",
                  marginBottom: "8px",
                  color: "#fff",
                  fontFamily: "poppins",
                }}
              >
                {cardData.AnnualLeave}
              </div>
              <div
                className="carddetailscon"
                style={{
                  fontSize: "1rem",
                  fontWeight: "normal",
                  color: "#fff",
                  fontFamily: "poppins",
                }}
              >
                Annual Leave
              </div>
            </div>
            {/* <Statistic title="Total Student's" value={cardData.totalStudents} style={{ justifyContent: 'center', alignItems: 'center', marginTop: '20px' }} /> */}
          </Card>
        </div>
        <div className="col-md-4 col-lg-4">
          <Card
            className="class-data"
            style={{
              background: "#E89494",
              color: "white",
              height: "242px !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-flex flex-column align-items-center">
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "normal",
                  marginBottom: "8px",
                  color: "#fff",
                  fontFamily: "poppins",
                }}
              >
                {cardData.MedicalLeave}
              </div>
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "normal",
                  color: "#fff",
                  fontFamily: "poppins",
                }}
              >
                Medical Leave
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-4 col-lg-4">
          <Card
            className="class-data"
            style={{
              background: "#585858",
              color: "white",
              height: "242px !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-flex flex-column align-items-center">
              <div
                style={{
                  fontSize: "2rem",
                  fontWeight: "normal",
                  marginBottom: "8px",
                  fontFamily: "poppins",
                }}
              >
                {cardData.CasualLeave}
              </div>
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "normal",
                  color: "#fff",
                  fontFamily: "poppins",
                }}
              >
                Casual sLeave
              </div>
            </div>
            {/* <Statistic title="Total Driver's" value={cardData.totalDrivers} style={{marginTop:'20px'}} /> */}
          </Card>
        </div>
      </div>
      <div>
        <LeaveTable />
      </div>
    </div>
  );
};

export default Leave;
