import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  Avatar,
  Tag,
  // Typography,
  Select,
  Layout,
  Card,
  Typography,
  Button,
} from "antd";
const columns = [
  {
    title: "LeaveType",
    dataIndex: "LeaveType",
    key: "LeaveType",
    // render: (text) => <Avatar src={text} size={48} />,
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Description",
    dataIndex: "Description",
    key: "Description",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <Tag color={status === "Approved" ? "blue" : "red"}>{status}</Tag>
    ),
  },
];
const data = [
  {
    key: "1",
    LeaveType: "Medical Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    Description: "Viral Fever",
    status: "Rejected",
  },
  {
    key: "2",
    LeaveType: "Medical Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    Description: "Viral Fever",
    status: "Approved",
  },
  {
    key: "3",
    LeaveType: "Medical Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    Description: "Viral Fever",
    status: "Approved",
  },
  {
    key: "4",
    LeaveType: "Medical Leave",
    Date: "07,Jan2024 - 09,Jan2024",
    Description: "Viral Fever",
    status: "Rejected",
  },

  // Add more data as needed
];
const LeaveTable = () => {
  return (
    <div>
      <div
        style={{
          borderRadius: "8px",
          height: "400px",
          overflow: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <div
          className="head-dept class-student-data "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        ></div>

        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          className="custom-table table"
          style={{ borderRadius: "8px", overflow: "hidden" }}
        />
      </div>
    </div>
  );
};

export default LeaveTable;
