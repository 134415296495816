import React, { useState, useEffect } from "react";
import {
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Table,
  Input,
  Avatar,
  Select,
  Space,
  notification,
  Tag,
  Popover,
  message,
  Pagination,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { SearchOutlined } from "@ant-design/icons";
import Studentopr from "../Component/Student/studentopr.jsx";
import * as XLSX from "xlsx";
import EmptyDataPage from "../Component/EmptyDataPage";
import TableSortArrows from "../Component/tableSortArrows";
import { useDispatch, useSelector } from "react-redux";
import { breadcrum } from "../action/Subbreadcrumaction";
// import Pagination from "../Component/pagination/index.jsx";
import { STUDENT } from "../constants/roles.js";
import { customPagination, fetchApi } from "../helper/index.js";
import moment from "moment";
import { getStudentDetails } from "../store/studentDetails/studentDetails.js";

const { confirm } = Modal;
const { Option } = Select;

function Studentdetails({ isEdit }) {
  const dispatch = useDispatch();
  const studentDetails = useSelector((state) => state.students);

  const [alldatas, setAlldatas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [studentFullData, setStudentFullData] = useState([]);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [userArray, setUserArray] = useState([]);

  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [tab, setTab] = useState("");
  // const [userDataList, setUserDataList] = useState([]);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [excelData, setExcelData] = useState(null);
  const [openUploaddataListing, setOpenUploaddataListing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [open, setOpen] = useState(false);

  // const [search, setSearch] = useState("");
  // const [pageSize, setPageSize] = useState();
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [dragging, setDragging] = useState(false);
  // const [position, setPosition] = useState({ x: 0, y: 0 });
  // const [startDragPosition, setStartDragPosition] = useState({ x: 0, y: 0 });
  // const [loader, setLoader] = useState(false);
  // const [pageSizeTotal, setPageSizeTotal] = useState(10);
  // const [tableData, setTableData] = useState([]);

  useEffect(() => {
    dispatch(getStudentDetails(queryParams));
  }, [queryParams, dispatch]);

  useEffect(() => {
    if (studentDetails?.data) {
      getStudentsList();
    }
  }, [studentDetails?.data]);

  useEffect(() => {
    if (studentDetails.isError) {
      message.error(studentDetails.error);
    }
  }, [studentDetails.isError]);

  useEffect(() => {
    if (searchQuery.length === 0) searchOnChange(searchQuery);
    if (searchQuery.length < 3) return;

    const delaySearch = setTimeout(() => {
      searchOnChange(searchQuery);
    }, 100);

    return () => clearTimeout(delaySearch);
  }, [searchQuery]);

  const getStudentsList = async () => {
    const studentsData = studentDetails?.data?.data.map((std) => {
      return {
        key: std.student_id,
        name: `${std.first_name} ${std.last_name}`,
        admissionNo: std.admission_no,
        role: STUDENT,
        uniqueno: std.student_id,
        Gradesec: `${std.selected_class}-${std.section}`,
        phoneNumber: "+91 9876543210",
        status: std.status,
        avatarUrl: std.image,
      };
    });

    setStudentFullData(studentDetails?.data?.data);
    setUserArray(studentsData);
    // setUserDataList(studentsData);
    setCount(studentDetails?.data?.count);
    setTotalcount(studentDetails?.data?.totalCount);
    setIsLoading(false);
  };

  const openOnChange = (value, tab) => {
    if (tab === "edit" || tab === "view") {
      let curStudentData = studentFullData.find(
        (std) => std.student_id === value.key
      );

      curStudentData = JSON.parse(JSON.stringify(curStudentData));

      curStudentData.date_of_birth = moment(curStudentData.date_of_birth);
      curStudentData.date_of_admission = moment(
        curStudentData.date_of_admission
      );

      setSelectedData(curStudentData);
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTab(tab);
    } else if (tab === "add") {
      seteditModuleOpen(true);
      setTab(tab);
      setSelectedData({});
    } else {
      confirmDelete();
    }
  };

  const confirmDelete = (record) => {
    const isActive = record.status === "Active";
    const status = record.status === "Active" ? "Inactive" : "Active";

    confirm({
      title: isActive ? "Confirm to make Inactive?" : "Confirm to make Active?",
      content: isActive
        ? "Are you sure you want to make this record inactive?"
        : "Are you sure you want to make this record active?",
      className: "custom-confirm",
      centered: true,
      okText: "Confirm",
      async onOk() {
        await updateStudentStatus(record.key, status);
      },
      onCancel() {},
      okButtonProps: {
        className: "custom-save-button",
      },
      cancelButtonProps: {
        className: "custom-cancel-button",
      },
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectedRowsData([]);
      setOpen(false);
    } else {
      const allKeys = userArray.map((item) => item.key);
      setSelectedRows(allKeys);
      setSelectedRowsData(userArray);
      setOpen(true);
    }
    setSelectAll(!selectAll);
  };

  const updateStudentStatus = async (stdId, status) => {
    const payload = {
      method: "patch",
      url: `/student/${stdId}`,
      data: {
        status,
      },
    };

    await fetchApi(payload)
      .then((response) => {
        if (response?.status === 200) {
          message.success("Update successfully!");

          dispatch(getStudentDetails(queryParams));

          // setTableData((students) => {
          //   return students.map((std) =>
          //     std.key === stdId ? { ...std, status } : std
          //   );
          // });
        } else {
          message.error("Error updating employee status");
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("Error updating employee status");
      });
  };

  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedData({});
  };

  const columns = [
    ...(isEdit
      ? [
          {
            title: (
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            ),
            dataIndex: "select",
            render: (_, record) => (
              <input
                type="checkbox"
                checked={selectedRows.includes(record.key)}
                onChange={() => handleCheckboxChange(record)}
              />
            ),
          },
        ]
      : []),

    {
      title: "Student Name",
      dataIndex: "name",
      render: (text, record) => (
        <Space>
          <Avatar
            src={record.avatarUrl}
            crossOrigin="anonymous | use-credentials"
          />
          {text}
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Unique No",
      dataIndex: "uniqueno",
    },
    {
      title: "Admission No",
      dataIndex: "admissionNo",
    },
    {
      title: "Grade & Sec",
      dataIndex: "Gradesec",
      sorter: (a, b) => a.Gradesec.localeCompare(b.Gradesec),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "#EC7C74"}`,
            color: status === "Active" ? "#fff" : "#fff",
            background:
              status === "Active"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  isEdit &&
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange(record, "edit");
              setbreadcrum("Edit Employee Details");
            }}
          />
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange(record, "view");
              setbreadcrum("View Student Details");
            }}
          />
          <FontAwesomeIcon
            icon={record.status === "Active" ? faTrash : faAdd}
            size="lg"
            style={{
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              confirmDelete(record);
            }}
          />
        </div>
      ),
    });

  const handleCheckboxChange = (record) => {
    let updatedSelectedRows;
    let updatedSelectedData;

    if (selectedRows.includes(record.key)) {
      // Remove row if unchecked
      updatedSelectedRows = selectedRows.filter((key) => key !== record.key);
      updatedSelectedData = selectedRowsData.filter(
        (item) => item.key !== record.key
      );
    } else {
      // Add row if checked
      updatedSelectedRows = [...selectedRows, record.key];
      updatedSelectedData = [...selectedRowsData, record];
    }

    setSelectedRows(updatedSelectedRows);
    setSelectedRowsData(updatedSelectedData);
    setSelectAll(updatedSelectedRows.length === userArray.length);

    // Open modal when at least one row is selected
    setOpen(updatedSelectedRows.length > 0);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const searchOnChange = (value) => {
    setIsLoading(true);
    let param = { _start: 0, _limit: 10 };
    param.search = value;
    dispatch(getStudentDetails(param));
    setCurrentPage(1);
  };

  const renderOptions = () => (
    <div className="modelpopover">
      <button
        onClick={() => {
          openOnChange(null, "add");
          setbreadcrum("Add Student");
        }}
        className="manualAdd"
      >
        Add Student
      </button>
      <button
        onClick={() => setOpenUploaddataListing(true)}
        className="bulkAdd"
      >
        Bulk Upload
      </button>
    </div>
  );

  const handleOk = () => {
    try {
      if (excelData.length) {
        setExcelData([]);

        notification.open({
          type: "success",
          message: "data added successfuly",
          className: "",
        });
      } else {
        notification.open({
          type: "error",
          message: "No data",
          className: "",
        });
      }
      setOpenUploaddataListing(false);
    } catch (e) {
      notification.open({
        type: "error",
        message: e.message,
        className: "",
      });
    }
  };

  const handleCancel = () => {
    setExcelData([]);

    setOpenUploaddataListing(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        if (jsonData.length) {
          setExcelData(jsonData);
        } else {
          message.error(
            "Invalid file format. Please upload a valid .xlsx file."
          );
        }
      } catch (err) {
        console.error("Error processing file:", err);
        message.error("Error processing file. Please try again.");
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const xlsxColumns = [
    {
      title: "Teacher Name",
      dataIndex: "Name",
    },

    {
      title: "Employee ID",
      dataIndex: "EMPID",
    },
    {
      title: "Role",
      dataIndex: "Role",
    },
    {
      title: "Phone Number",
      dataIndex: "MobileNumber",
    },
    {
      title: <div> Status</div>,
      dataIndex: "Status",
    },
  ];

  const setbreadcrum = (arg) => {
    dispatch(breadcrum(arg));
  };

  const pageSizeChange = async (page, pageSize) => {
    setIsLoading(true);

    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    dispatch(getStudentDetails(ctr));
    setCurrentPage(page);
    setQueryParams(ctr);
  };

  const useronChange = async (value) => {
    if (value) {
      let finded = alldatas.filter((a) => a.Gradesec === value);
      // setUserDataList(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      // setUserDataList(alldatas);
      setTotalcount(alldatas.length);
      setUserType("");
    }
  };

  return (
    <div>
      {!editModuleOpen ? (
        <>
          <div className="tablecontainer">
            <div className="tableinnercontainer ">
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                <div className="search-id">
                  <Input
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                    placeholder="Search by Studentse Name or ID"
                    prefix={<SearchOutlined />}
                    className="seachAccadamicemployee"
                  />
                </div>
                {isEdit ? (
                  <div>
                    <Select
                      allowClear
                      className="class-select-btns"
                      placeholder="Bulk Select"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      style={{ width: "150px" }}
                      dropdownStyle={{ width: "150px" }}
                      onChange={(value) =>
                        console.log("Bulk action selected:", value)
                      }
                    >
                      <Option value="Active">Active</Option>
                      <Option value="Inactive">Inactive</Option>
                    </Select>
                  </div>
                ) : null}
                <div className="nav-sectionBar">
                  <div className="d-flex flex-column flex-md-column flex-lg-row align-items-center gap-3">
                    <div className="wholeselect">
                      <Select
                        value={userType || undefined}
                        onChange={useronChange}
                        // className="selectdiv"
                        allowClear
                        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                        placeholder="Select Grade"
                        style={{ width: "150px", height: "unset" }}
                        dropdownStyle={{ width: "150px" }}
                        options={[
                          {
                            value: "9-A",
                            label: "9-A",
                          },
                          {
                            value: "9-B",
                            label: "9-B",
                          },
                          {
                            value: "10-A",
                            label: "10-A",
                          },
                          {
                            value: "10-B",
                            label: "10-B",
                          },
                          {
                            value: "11-A",
                            label: "11-A",
                          },
                          {
                            value: "11-B",
                            label: "11-B",
                          },
                          {
                            value: "12-A",
                            label: "12-A",
                          },
                        ]}
                      />
                    </div>

                    <div className="btnaligwitselect">
                      {!isEdit ? (
                        <div>
                          <Popover placement="bottom" content={renderOptions()}>
                            <div className="addbtn">
                              <FontAwesomeIcon
                                icon={faPlus}
                                size="lg"
                                className="icon"
                                style={{ fontSize: "22px" }}
                              />
                              <p
                                style={{
                                  margin: "0px",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Add
                              </p>
                            </div>
                          </Popover>
                        </div>
                      ) : null}
                    </div>
                    <div className="pagination_align">
                      <div className=" d-md-flex d-none">
                        <Pagination
                          total={totalcount}
                          defaultCurrent={1}
                          current={currentPage}
                          defaultPageSize={10}
                          pageSize={queryParams._limit}
                          onChange={pageSizeChange}
                          itemRender={customPagination}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Table
              rowKey="key"
              columns={columns}
              loading={isLoading}
              dataSource={userArray}
              tableLayout="fixed"
              className="table-datas tablecontent"
              pagination={false}
            />
          </div>
          <div className="pagination_align">
            <div className="d-md-flex d-none">
              <span>{`Showing ${
                queryParams._start + 1
              } - ${count} of ${totalcount}`}</span>
            </div>
            <div>
              <Pagination
                total={totalcount}
                defaultCurrent={1}
                current={currentPage}
                defaultPageSize={10}
                pageSize={queryParams._limit}
                pageSizeOptions={["5", "10", "25", "50"]}
                onChange={pageSizeChange}
                itemRender={customPagination}
                showSizeChanger={true}
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          <Studentopr
            record={selectedData}
            onClose={onClose}
            type={tab}
            selectedId={selectedId}
          />
        </div>
      )}
      <Modal
        title="Selected Students"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Apply"
        mask={false}
      >
        <Table
          columns={columns}
          dataSource={selectedRowsData}
          pagination={false}
          rowKey="key"
        />
      </Modal>
      <Modal
        title={"Student Data"}
        className=""
        open={openUploaddataListing}
        width={1000}
        zIndex={100000}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <div className="bulkuploadinnerbtn" style={{ marginBottom: "20px" }}>
          <div className="file-upload-wrapper">
            <label htmlFor="file-upload" className="custom-file-upload-button">
              Upload File
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".xlsx"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
          </div>
          <button
            onClick={() => {
              const link = document.createElement("a");
              link.href = "/path/to/your/template.xlsx"; // Replace with your file's path
              link.download = "Student_Data_Template.xlsx"; // File name for download
              link.click();
            }}
            className="download-template-button"
            style={{
              backgroundColor: "#00ADC4",
              color: "#fff",
              border: "none",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Download Template
          </button>
        </div>
        {excelData?.length ? (
          <Table
            columns={xlsxColumns}
            dataSource={excelData}
            style={{ borderRadius: "2px" }}
            pagination={false}
          />
        ) : (
          <EmptyDataPage description="No records found" />
        )}
      </Modal>
    </div>
  );
}
export default Studentdetails;
