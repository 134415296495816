import React, { useState } from "react";
import { Layout, Card, Typography, Calendar, Button, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ExamPerformance from "../../layout/base/charts/ExamPerformance";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ClassSubjectView from "../../Component/classroom/ClassSubjectView.jsx";
// import Pagination from "../../Component/pagination/index.jsx";
import { Form, Input, Steps, message, Row, Col, Upload } from "antd";
import Pagination from "../../Component/pagination/index.jsx";
import ExamPerformance from "../../layout/base/charts/ExamPerformance";
// import TeacherAttendanceChart from "../../layout/base/charts/TeacherAttenance.jsx";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { faPlus, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Radio } from "antd";
import { Tiny } from "@ant-design/plots";
// import React from "react";
import ReactDOM from "react-dom";
// import "./Register.css";

const data = [
  {
    key: "1",
    Class: "11 - A",
    Examname: "CT - 1",
    grade: "A +",

    Mobileno: "7603992425",
    Admission: "Emp001",
  },
  {
    key: "2",
    Class: "11 - A",
    Examname: "CT - 2",
    grade: "A +",
    age: 17,
    Mobileno: "7603992425",
    Admission: "Emp002",
  },
  {
    key: "3",
    Class: "11 - A",
    Examname: "CT - 3",
    grade: "A +",

    Mobileno: "7603992425",
    Admission: "Emp003",
  },
  {
    key: "4",
    Class: "11 - A",
    Examname: "Model",
    grade: "A +",

    Mobileno: "7603992425",
    Admission: "Emp003",
  },
  {
    key: "5",
    Class: "11 - A",
    Examname: "Quarterly",
    grade: "A +",

    Mobileno: "7603992425",
    Admission: "Emp003",
  },
];
const percent = 0.7;
const config = {
  percent,
  width: 0,
  height: 300,
  color: ["#EC7C74", "#00ADC4"],
  annotations: [
    {
      type: "text",
      style: {
        text: `${percent * 100}%`,
        x: "50%",
        y: "50%",
        textAlign: "center",
        fontSize: 16,
        fontStyle: "600",
      },
    },
  ],
};
const ViewclassRoom = ({ setIsOpen, isOpen, settabVisible }) => {
  const [userArray, setUserArray] = useState(data);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [teb, setTeb] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  const { Text } = Typography;
  const { Step } = Steps;
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [formData, setFormData] = useState({
    schoolName: "",
    primaryEmail: "",
    contactNumber: "",
    schoolAddress: "",
    city: "",
    state: "",
    country: "",
    adminName: "",
    adminEmail: "",
    adminPhoneNumber: "",
    fileName: "",
    schoolType: "",
    password: "",
    confirmPassword: "",
  });
  const addField = () => {
    const newField = {
      id: Date.now(), // Unique ID for the dynamic field
      branchName: "",
      branchAddress: "",
      branchContactNumber: "",
      branchAdminName: "",
      branchAdminEmail: "",
      branchAdminMobileNumber: "",
    };
    setDynamicFields([...dynamicFields, newField]);
  };
  const removeField = (id) => {
    setDynamicFields(dynamicFields.filter((field) => field.id !== id));
  };
  const openOnChange = (value, teb) => {
    // settabVisible(false);
    if (teb === "view") {
      setModuleOpen(true);
      setSelectedDate(value);
    } else {
      setModuleOpen(false);
      setSelectedDate({});
    }
  };

  const columns = [
    { title: "Exam Name", dataIndex: "Examname", key: "Examname" },
    { title: "Admission", dataIndex: "Admission", key: "Admission" },
    { title: "Class", dataIndex: "Class", key: "Class" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    { title: "Mobile No", dataIndex: "Mobileno", key: "Mobileno" },
    {
      title: "Action",
      dataIndex: "status",
      align: "center",
      render: (_, record) => (
        <div>
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
        </div>
      ),
    },
  ];
  console.log(isOpen, "isopen");

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const objectLength = Object.keys(userArray).length;

  return (
    <div>
      <>
        {!moduleOpen ? (
          <div className="studenttable">
            <div className="clas-student_Title d-sm-flex align-items-baseline justify-content-between mb-4">
              <div className="class-student_data d-md-flex align-items-center gap-5">
                <div className="class-data_img">
                  <img
                    src={"https://i.pravatar.cc/40?img=12"}
                    alt="ii"
                    // style={{ width: 200, height: 200 }}
                  />
                </div>

                <div className="clas-data_details d-flex flex-column">
                  <p1>Nivatha Thomas</p1>
                  <p2>Grade 11-A</p2>
                  <div>
                    <p3>24/7 , MVP Nagar , Anna Nagar , Chennai -603203</p3>
                  </div>
                </div>
              </div>
              <div className="class-student_fullDetails">
                <button>View Full Details</button>
              </div>
            </div>
            <div className="classroom-charts m-4">
              <div className="row g-3">
                <div className="col-md-6 flex-column">
                  <div className="d-md-flex align-items-center ">
                    <h5 className="fw-bold">Attendance</h5>
                  </div>
                  <div className="ring-container">
                    <Tiny.Ring {...config} />
                  </div>
                </div>
                <div className="col-md-6">
                  <ExamPerformance />
                </div>
              </div>
            </div>
            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
            />
            <Pagination
              data={userArray}
              onPageChange={(data) => {
                setTableData(data);
              }}
              initialItemsPerPage={10}
            />
          </div>
        ) : (
          <ClassSubjectView
            data={selectedDate}
            isOpen={moduleOpen}
            setIsOpen={setModuleOpen}
            // settabVisible={settabVisible}
            pagination={false}
          />
        )}
      </>
    </div>
  );
};

export default ViewclassRoom;
