import React, { useState } from "react";
import StudentTable from "../Departmentadmin/Departmenttable";
import { Modal, Table, Input, Row, Form, Col, Select, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import TableSortArrows from "../../Component/tableSortArrows";
import Pagination from "../pagination/index";
import { TEACHER } from "../../constants/roles";

const { Option } = Select;

function Student({
  setSearchEnable,
  settabVisible,
  activeTab,
  loader,
  alldatas,
}) {
  const [userArray, setUserArray] = useState(alldatas);
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [teb, setTeb] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [showStudentTable, setShowStudentTable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [tableData, setTableData] = useState(alldatas);
  const [userDataList, setUserDataList] = useState(alldatas);
  const [curClass, setCurClass] = useState("");
  const [curSection, setCurSection] = useState("");
  const [form] = Form.useForm();

  const resetModalState = () => {
    form.resetFields();
    setOpenModal(false);
  };

  const openOnChange = (value, teb) => {
    if (teb === "edit" || teb === "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb === "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    }
  };

  const handleStudentClick = (classInfo) => {
    classInfo = classInfo.split(" ");
    // console.log("classinfo: ", classInfo);
    setCurClass(classInfo[0]);
    setCurSection(classInfo[2]);
    setSearchEnable(true);
    setShowStudentTable(true);
  };

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    {
      title: "Class",
      dataIndex: "classInfo",
      align: "center",
    },
    {
      title: TEACHER,
      dataIndex: "teachername",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "status",
      align: "center",
      render: (_, record) => (
        <div className="btndepadmin">
          <button
            className="btndepadminstu"
            onClick={() => handleStudentClick(record.classInfo)}
          >
            Student
          </button>
          <button className="btndepadminass" onClick={() => setOpenModal(true)}>
            Reassign
          </button>
        </div>
      ),
    },
  ];

  const objectLength = Object.keys(userArray).length;

  return (
    <div>
      {!editModuleOpen && !showStudentTable ? (
        <div className="tablecontainer">
          <Table
            loading={loader}
            columns={columns}
            dataSource={alldatas}
            tableLayout="fixed"
            className="table-datas"
            pagination={false}
          />
          <Pagination
            customClass="d-none d-xl-flex"
            data={alldatas}
            onPageChange={(data) => {
              setTableData(data);
            }}
            initialItemsPerPage={10}
          />
        </div>
      ) : null}

      {showStudentTable ? (
        <div className="tablecontainer">
          <StudentTable
            columns={columns}
            dataSource={userDataList}
            settabVisible={settabVisible}
            tableLayout="fixed"
            className=""
            setIsOpen={setShowStudentTable}
            isOpen={showStudentTable}
            pagination={false}
            curClass={curClass}
            curSection={curSection}
          />
          {/* <Pagination
            data={userDataList}
            onPageChange={(data) => {
              setTableData(data);
            }}
            initialItemsPerPage={10} 
          />*/}
        </div>
      ) : null}

      <Modal
        open={openModal}
        maskClosable={false}
        onCancel={resetModalState}
        onOk={() => form.submit()}
        okText="Save"
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <p className="clasmodelpopup">Class Teacher Assigning</p>
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Class"
                name="class"
                rules={[{ required: true, message: "Please Enter Class!" }]}
              >
                <Input className="inputbox" placeholder="Class" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="assignedclass"
                label="Assigned Class"
                rules={[
                  { required: true, message: "Please Enter Class Teacher!" },
                ]}
              >
                <Select
                  placeholder="Class Teacher"
                  style={{ width: "100%" }}
                  className="inputbox"
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                >
                  <Option value="sam">Sam</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default Student;
