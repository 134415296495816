import React, { useState } from "react";

//import "./App.css";
import {
  Modal,
  Table,
  Input,
  Avatar,
  Select,
  Space,
  message,
  Typography,
} from "antd";
import Navbar from "../../Pages/SuperAdmin/Navbar.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Area } from "@ant-design/plots";
import { Pie } from "@ant-design/plots";
import TableSortArrows from "../../Component/tableSortArrows";
import bin from "../../Assets/Svg/bin.png";
import Feesadd from "../../Component/Fees/Feesadd";
import eye from "../../Assets/Svg/eye.png";
import edit from "../../Assets/Svg/edit.png";
import Company1 from "../../Assets/png/commission-01.png";
import Company2 from "../../Assets/png/Company-01.png";
import Company3 from "../../Assets/png/school-01.png";
import { faFile, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  faPlus,
  faSortDown,
  //   faPenToSquare,
  //   faEye,
  //   faTrash,
  //   faAdd,
} from "@fortawesome/free-solid-svg-icons";

// let { bin, TableSortArrows, eye, edit } = "";
const { Title } = Typography;
const { confirm } = Modal;
const { Option } = Select;

const allData = [
  {
    key: 1,
    SchoolName: "Accadamic Fees",
    Admin: "123",
    EmailID: "ENG00201",
    // role: SUPER_ADMIN,
    phoneNumber: "+91 9876543210",
    TotalStudent: "50 K+",
    TotalTeacher: "10 K+",
    GatewayPercentage: "3%",
    TechHyveShare: "57%",
    SchoolShare: "30%",
    MonthlyRevenue: "40,000",
    LastTransition: "01-Jan-2025",
    // status: "Active",
  },
  {
    key: 2,
    SchoolName: "Accadamic Fees",
    Admin: "123",
    EmailID: "ENG00201",
    // role: SUPER_ADMIN,
    phoneNumber: "+91 9876543210",
    TotalStudent: "50 K+",
    TotalTeacher: "10 K+",
    GatewayPercentage: "3%",
    TechHyveShare: "57%",
    SchoolShare: "30%",
    MonthlyRevenue: "40,000",
    LastTransition: "01-Jan-2025",
  },
  {
    key: 3,
    SchoolName: "Accadamic Fees",
    Admin: "123",
    EmailID: "ENG00201",
    // role: SUPER_ADMIN,
    phoneNumber: "+91 9876543210",
    TotalStudent: "50 K+",
    TotalTeacher: "10 K+",
    GatewayPercentage: "3%",
    TechHyveShare: "57%",
    SchoolShare: "30%",
    MonthlyRevenue: "40,000",
    LastTransition: "01-Jan-2025",
  },
  {
    key: 4,
    SchoolName: "Accadamic Fees",
    Admin: "123",
    EmailID: "ENG00201",
    // role: SUPER_ADMIN,
    phoneNumber: "+91 9876543210",
    TotalStudent: "50 K+",
    TotalTeacher: "10 K+",
    GatewayPercentage: "3%",
    TechHyveShare: "57%",
    SchoolShare: "30%",
    MonthlyRevenue: "40,000",
    LastTransition: "01-Jan-2025",
  },
  {
    key: 5,
    SchoolName: "Accadamic Fees",
    Admin: "123",
    EmailID: "ENG00201",
    // role: SUPER_ADMIN,
    phoneNumber: "+91 9876543210",
    TotalStudent: "50 K+",
    TotalTeacher: "10 K+",
    GatewayPercentage: "3%",
    TechHyveShare: "57%",
    SchoolShare: "30%",
    MonthlyRevenue: "40,000",
    LastTransition: "01-Jan-2025",
  },
  {
    key: 6,
    SchoolName: "Accadamic Fees",
    Admin: "123",
    EmailID: "ENG00201",
    // role: SUPER_ADMIN,
    phoneNumber: "+91 9876543210",
    TotalStudent: "50 K+",
    TotalTeacher: "10 K+",
    GatewayPercentage: "3%",
    TechHyveShare: "57%",
    SchoolShare: "30%",
    MonthlyRevenue: "40,000",
    LastTransition: "01-Jan-2025",
    // name: "Accadamic Fee",
    // employeeID: "ENG00204",
    // // role: SUPER_ADMIN,
    // phoneNumber: "+91 9876543213",
    // status: "Active",
    // avatarUrl: "https://i.pravatar.cc/40?img=4",
    // class: "Class 4", // Added class
    // feesStructure: 40000, // Added fees structure
  },
];
function App() {
  const [tableData, setTableData] = useState("");
  // const [userType, setUserType] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  // const [userDataList, setUserDataList] = useState("");
  // const [totalCount, setTotalcount] = useState("");
  const [type, setType] = useState("");
  // const [search, setSearch] = useState("");
  // const [userData, setUserData] = useState(allData);
  const [selectedId, setselectedId] = useState("");
  const [selectedData, setSelectedData] = useState({});

  const openOnChange = (value, type) => {
    if (type == "edit" || type == "view") {
      setselectedId(value.key);
      setModelOpen(true);
      setType(type);
      setSelectedData(value);
    } else if (type == "add") {
      setModelOpen(true);
      setType(type);
      setSelectedData({});
    }
  };
  const columns = [
    {
      title: <input type="checkbox" />,
      dataIndex: "checkbox",
      render: () => <input type="checkbox" />,
    },
    {
      title: "School Name",
      dataIndex: "SchoolName",

      sorter: (a, b) => a.SchoolName.localeCompare(b.SchoolName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Admin",
      dataIndex: "Admin",

      sorter: (a, b) => a.Admin.localeCompare(b.Admin),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Email ID",
      dataIndex: "EmailID",

      sorter: (a, b) => a.EmailID.localeCompare(b.EmailID),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Phone Number",
      dataIndex: "PhoneNumber",

      sorter: (a, b) => a.PhoneNumber.localeCompare(b.PhoneNumber),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Driver App",
      dataIndex: "DriverApp",

      sorter: (a, b) => a.DriverApp.localeCompare(b.DriverApp),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Total Student",
      dataIndex: "TotalStudent",

      sorter: (a, b) => a.TotalStudent.localeCompare(b.TotalStudent),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Total Teacher",
      dataIndex: "TotalTeacher",

      sorter: (a, b) => a.TotalTeacher.localeCompare(b.TotalTeacher),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Gateway Percentage",
      dataIndex: "GatewayPercentage",

      sorter: (a, b) => a.GatewayPercentage.localeCompare(b.GatewayPercentage),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "TechHyve Share",
      dataIndex: "TechHyveShare",

      sorter: (a, b) => a.TechHyveShare.localeCompare(b.TechHyveShare),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "School Share",
      dataIndex: "SchoolShare",

      sorter: (a, b) => a.SchoolShare.localeCompare(b.SchoolShare),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Monthly Revenue",
      dataIndex: "MonthlyRevenue",

      sorter: (a, b) => a.MonthlyRevenue.localeCompare(b.MonthlyRevenue),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Last Transition",
      dataIndex: "LastTransition",

      sorter: (a, b) => a.LastTransition.localeCompare(b.LastTransition),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <>
          <img
            src={edit}
            alt="Delete"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange({}, "edit");
            }}
          />
          <img
            src={eye}
            alt="Delete"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange({}, "view");
            }}
          />
          <img
            src={bin}
            alt="Delete"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
          />
        </>
      ),
    },
  ];
  const onClose = (action) => {
    setselectedId("");
    setModelOpen(false);

    setSelectedData({});

    // if (action === 'save') {
    //   message.success('Draft saved successfully!');
    // } else if (action === 'publish') {
    //   message.success('Published successfully!');
    // }
  };

  // const YearSelect = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = 2000; year <= currentYear; year++) {
    years.push(year);
  }
  const config = {
    data: [
      { week: "Week1", price: 20 },
      { week: "Week2", price: 25 },
      { week: "Week3", price: 30 },
      { week: "Week4", price: 25 },
      { week: "Week5", price: 35 },
      { week: "Week6", price: 100 },
    ],
    xField: "week",
    yField: "price",
    style: {
      fill: "linear-gradient(-100deg, #5C4EF366 100%, #FFFFFF80 10%)",
    },
    // axis: {
    //   x: { title: "Week" }, // Show X-axis label
    //   y: { title: "Price", labelFormatter: "~s" },
    // },
    tooltip: {
      showMarkers: true,
      showCrosshairs: true, // Show crosshair line on hover
      shared: true, // Ensures tooltip works across the graph
      formatter: (datum) => ({
        name: datum.week, // Show the week (X-axis value)
        value: `Price: ${datum.price}`, // Show the price (Y-axis value)
      }),
      customContent: (title, items) => {
        if (!items || items.length === 0) return "No data"; // Prevents null values
        return `
        <div class="g2-tooltip">
          <div class="g2-tooltip-title">${title}</div>
          <ul class="g2-tooltip-list">
            ${items
              .map(
                (item) =>
                  `<li class="g2-tooltip-list-item">
                    <span class="g2-tooltip-marker" style="background-color:${item.color};"></span>
                    <span class="g2-tooltip-name">Week:</span>
                    <span class="g2-tooltip-list-item-value">${item.data.week}</span><br>
                    <span class="g2-tooltip-name">Price:</span>
                    <span class="g2-tooltip-list-item-value">${item.data.price}</span>
                  </li>`
              )
              .join("")}
          </ul>
        </div>`;
      },
    },
    line: {
      shape: "smooth",
      style: {
        stroke: "#5C4EF3",
        strokeWidth: 2,
        pointerEvents: "auto",
      },
    },
  };

  const configs = {
    data: [
      { type: "1", value: 1 },
      { type: "2", value: 2 },
      { type: "3", value: 3 },
      { type: "4", value: 4 },
      { type: "5", value: 5 },
      { type: "6", value: 6 },
      { type: "7", value: 7 },
      { type: "8", value: 8 },
      { type: "9", value: 9 },
      { type: "10", value: 10 },
      { type: "11", value: 11 },
      { type: "12", value: 12 },
    ],
    angleField: "value",
    colorField: "type",
    innerRadius: 0.6,
    label: {
      text: "value",
      style: {
        fontWeight: "bold",
      },
    },
    legend: false, // Hide the legend
    annotations: [],
  };

  return (
    <div>
      {!modelOpen ? (
        <div className="px-3">
          <div>
            {" "}
            <Navbar />
          </div>
          <div className="row g-4 pb-5">
            <div className="col-md-4 ">
              <div className="dashboard-data bg-1">
                <div className="d-flex flex-column">
                  <p1>50,00000</p1>
                  <p2>Total Commission</p2>
                </div>
                <div className="dashboard-data-img">
                  <img src={Company1} alt="Company Logo" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dashboard-data bg-2">
                <div className="d-flex flex-column">
                  <p1>30,00000</p1>
                  <p2>TechHyve Share</p2>
                </div>
                <div className="dashboard-data-img">
                  <img src={Company2} alt="Company Logo" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dashboard-data bg-3">
                <div className="d-flex flex-column">
                  <p1>20,00000</p1>
                  <p2>School Shares</p2>
                </div>
                <div className="dashboard-data-img">
                  <img src={Company3} alt="Company Logo" />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="dashboard-data-chart">
                <div className="dashboard-chart--title">
                  <div className="d-flex justify-content-between align-items-center p-3">
                    <p>Overall Revenue</p>
                    <Select
                      allowClear
                      className="class-select-btn"
                      placeholder="Select Month"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      style={{ width: "150px" }}
                      dropdownStyle={{ width: "150px" }}
                    >
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </Select>
                  </div>
                </div>
                <div className="chart_table">
                  {" "}
                  <Area {...config} />
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="dashboard-data-chart ">
                <div className="dashboard-chart--title">
                  <div className="d-flex justify-content-between align-items-center p-3">
                    <p>Gateway Charges</p>
                    <Select
                      allowClear
                      className="class-select-btn"
                      placeholder="Select Year"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      style={{ width: "150px" }}
                      dropdownStyle={{ width: "150px" }}
                    >
                      {years.map((year) => (
                        <Option key={year} value={year}>
                          {year}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                {/* <Area {...config} /> */}
                <div className="pie-chart-container">
                  <Pie {...configs} />
                </div>
              </div>
            </div>
            <div className="dashboard-table-btn d-md-flex justify-content-md-between">
              <div className="table_btn gap-4 table-width-1">
                <Input placeholder="Enter school name" />
                <FontAwesomeIcon icon={faSearch} />
              </div>
              <div className="table_btn table-width-3">
                <Select
                  allowClear
                  placeholder="Action"
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  style={{ width: "150px", backgroundColor: "transparent" }}
                  dropdownStyle={{ width: "150px" }}
                  className="custom-select"
                >
                  <Option value="mathematics">A</Option>
                  <Option value="science">B</Option>
                  <Option value="english">C</Option>
                </Select>
                {/* <button>Action</button> */}
              </div>
              <div className="table_btn table-width-3">
                <Select
                  allowClear
                  placeholder="Select Month"
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  style={{ width: "150px", backgroundColor: "transparent" }}
                  dropdownStyle={{ width: "150px" }}
                  className="custom-select"
                >
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </Select>
                {/* <button>Select Month</button> */}
              </div>
              <div className="table_btn table-width-3">
                <button className="d-flex gap-4">
                  Add
                  <FontAwesomeIcon
                    icon={faPlus}
                    size="lg"
                    className="icon"
                    style={{ fontSize: "22px" }}
                  />
                </button>
              </div>
              <div className="table_btn table-width-3">
                <button className="d-flex gap-4">
                  Export
                  <FontAwesomeIcon
                    icon={faFile}
                    color="#37B497"
                    size="lg"
                    // className="icon"
                    style={{ fontSize: "22px" }}
                  />
                </button>
              </div>
            </div>
            <div className="dashboart-data--table">
              <Table
                columns={columns}
                dataSource={tableData}
                tableLayout="fixed"
                className="table-datas"
                pagination={false}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Feesadd
            // selectedData={selectedData}
            tableData={tableData}
            onClose={onClose}
            className="showGst"
            onlyvalue={true}
            type={type}
            selectedId={selectedId}
          />{" "}
        </div>
      )}
    </div>
  );
}

export default App;
