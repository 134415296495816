import { createSlice } from "@reduxjs/toolkit";
import { parseJwt } from "../../helper";

const initialState = {
  isLogged: false,
  authToken: "",
  userDetails: null,
  // Pagination state
  currentPage: 1,
  itemsPerPage: 10,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    checkLogged: (state) => {
      const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
      state.isLogged = Boolean(token);
      state.authToken = token || "";
      state.userDetails = token ? parseJwt(token) : null;
    },
    // Pagination reducers
    // setCurrentPage(state, action) {
    //   state.currentPage = action.payload;
    // },
    // setItemsPerPage(state, action) {
    //   state.itemsPerPage = action.payload;
    // },
  },
});

export const { checkLogged, setCurrentPage, setItemsPerPage } =
  authSlice.actions;

export default authSlice.reducer;
