import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Select,
  Row,
  notification,
  Form,
  Col,
  message,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import {
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { fetchApi, customPagination } from "../../helper";
import Pagination from "../pagination/index";
import { useDispatch, useSelector } from "react-redux";
import { getSubjects } from "../../store/settings/subject";

const { Option } = Select;
const { confirm } = Modal;

function Subject() {
  const dispatch = useDispatch();
  const subject = useSelector((state) => state.subject);

  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [userArray, setUserArray] = useState([]);
  const [userType, setUserType] = useState("");
  const [search, setSearch] = useState("");
  const [userDataList, setUserDataList] = useState([]);
  const [form] = Form.useForm();
  const [subjectDetails, setSubjectDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(true);
  const [modalLoader, setModalLoader] = useState(false);

  useEffect(() => {
    dispatch(getSubjects());
  }, [dispatch]);

  useEffect(() => {
    if (subject?.data) {
      getSubjectsList();
    } else {
      dispatch(getSubjects());
    }
  }, [subject?.data, dispatch]);

  useEffect(() => {
    if (subject.isError) {
      message.error(subject.error);
    }
  }, [subject.isError]);

  const getSubjectsList = () => {
    const subjectData = subject?.data?.data.map((sub) => {
      return {
        key: sub.subject_id,
        subject_name: sub.subject_name,
        subject_code: sub.subject_code,
      };
    });

    setUserData(subjectData || []);
    setFilteredData(subjectData || []);
    setTotalCount(subjectData?.length || 0);
    setUserDataList(subjectData || []);
    setLoader(false);

    // .catch((error) => {
    //   console.log(error);
    //   message.error("Error fetching employee details");
    //   setLoader(false);
    // });
  };

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const resetModalState = () => {
    setModalLoader(false);
    setEditingRecord(null);
    form.resetFields();
    setOpenModal(false);
  };

  const openEditModal = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      subject_name: record.subject_name,
      subject_code: record.subject_code,
    });
    setOpenModal(true);
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      onOk() {
        setUserData((prev) => prev.filter((item) => item.key !== record.key));
        setTotalCount((prev) => prev - 1);
        notification.success({ message: "Record deleted successfully!" });
      },
    });
  };

  const handleSearch = (value) => {
    const filtered = userData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setTotalCount(filtered?.length);
  };

  const useronChange = (value) => {
    if (value) {
      const filtered = userData.filter(
        (a) => a.Gradesec?.toLowerCase() === value?.toLowerCase()
      );
      setUserDataList(filtered);
      setTotalCount(filtered?.length);
      setUserType(value);
    } else {
      setUserDataList(userData);
      setTotalCount(userData?.length);
      setUserType("");
    }
  };

  const searchOnChange = (value) => {
    const data = userData.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.admissionID.toLowerCase().includes(value.toLowerCase())
    );
    setUserDataList(data);
    setSearch(value);
    setTotalCount(data?.length);
  };

  const handleSave = (values) => {
    if (editingRecord) {
      // Edit logic
      setUserData((prev) =>
        prev.map((item) =>
          item.key === editingRecord.key ? { ...item, ...values } : item
        )
      );

      const payload = {
        method: "put",
        url: `api/v1/subject/${editingRecord.key}`,
        data: editingRecord,
      };

      fetchApi(payload)
        .then((res) => {
          if (res.message) {
            notification.open({
              type: "success",
              message: "Record updated successfully!",
              className: "",
            });
          } else if (res?.error) {
            notification.open({
              type: "error",
              message: "Record updated field!",
              className: "",
            });
          }
        })
        .catch((error) => {
          notification.open({
            type: "error",
            message: error.message,
            className: "",
          });
        });
    } else {
      // Add logic

      const newRecord = { ...values, key: Date.now() };

      setUserData((prev) => [...prev, newRecord]);
      let payload = {
        method: "post",
        url: "api/v1/subject",
        data: newRecord,
      };

      fetchApi(payload)
        .then((res) => {
          if (res.message) {
            notification.open({
              type: "success",
              message: "record added successfully!",
              className: "",
            });
          } else if (res?.error) {
            notification.open({
              type: "error",
              message: "record add field!",
              className: "",
            });
          }
        })
        .catch((error) => {
          notification.open({
            type: "error",
            message: error.message,
            className: "",
          });
        });
    }
    resetModalState();
  };

  useEffect(() => {
    setFilteredData(userData);
    setTotalCount(userData?.length);
  }, [userData]);

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    { title: "Subject Name", dataIndex: "subject_name", align: "center" },
    { title: "Subject Code", dataIndex: "subject_code", align: "center" },

    {
      title: "Actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => openEditModal(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => confirmDelete(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
        </div>
      ),
    },
  ];

  // const objectLength = Object.keys(userArray).length;

  const handleSubjectChange = (e) => {
    const { name, value } = e?.target;
    setSubjectDetails({ ...subjectDetails, [name]: value });
  };

  const handleSubjectSubmission = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

    setModalLoader(true);

    await form
      .validateFields()
      .then((data) => {
        console.log(editingRecord);
        const payload = {
          method: editingRecord ? "put" : "post",
          url: editingRecord ? `/subject/${editingRecord?.key}` : "/subject",
          headers: { Authorization: `Bearer ${token}` },
          data,
        };

        fetchApi(payload)
          .then((res) => {
            switch (res?.status) {
              case 201:
                setFilteredData((records) => [
                  {
                    key: res.data[0].subject_id,
                    subject_name: res.data[0].subject_name,
                    subject_code: res.data[0].subject_code,
                  },
                  ...records,
                ]);
                console.log(res);

                message.success("Successfully created subject!");
                resetModalState();
                break;
              case 200:
                setFilteredData((records) =>
                  records.map((record) => {
                    if (record.key === editingRecord.key) {
                      return {
                        key: res.data.subject_id,
                        subject_name: res.data.subject_name,
                        subject_code: res.data.subject_code,
                      };
                    }
                    return record;
                  })
                );
                message.success("Successfully updated subject!");
                resetModalState();
                break;
            }
          })
          .catch((err) => {
            message.error("Error processing the request!");
            console.error("Add Subject Api Error : ", err);
          });
      })
      .catch((err) => {
        message.error("Enter correct values!");
        setModalLoader(false);
      });
  };

  console.log(subjectDetails, "rdxtcfgvhbjnkml");

  return (
    <>
      <div className="tablecontainer">
        <div className="tableinnercontainer">
          <div className="d-md-flex justify-content-between align-items-center ">
            <div className="search-id">
              <Input
                placeholder="Search by Subject Name"
                prefix={<SearchOutlined />}
                onChange={(e) => handleSearch(e.target.value)}
                className="seachAccadamicemployee"
              />
            </div>
            {/* <div className="nav-sectionBar"> */}
            <div className="d-lg-flex  align-items-center ">
              <div className="d-flex justify-content-center px-3">
                <Select
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  className="wholeselect"
                  placeholder="Select type"
                  // style={{ margin: 0, marginLeft: "2rem" }}
                  // onChange={handleChange}
                  allowClear
                  // value={action}
                  options={[
                    {
                      value: "primary",
                      label: "primary",
                    },
                    {
                      value: "secondary",
                      label: "secondary",
                    },
                    {
                      value: "HigherSecondary",
                      label: "HigherSecondary",
                    },
                  ]}
                />
              </div>
              {/* <div className="addbtnaccsettsubject"> */}
              <div className="d-flex justify-content-center px-3">
                <div className="btnacccon">
                  <div className="addbtn" onClick={() => setOpenModal(true)}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="lg"
                      className="icon"
                      style={{ fontSize: "22px" }}
                    />
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Add
                    </p>
                  </div>
                  <Pagination
                    customClass="d-none d-xl-flex"
                    // class-page-show.style.display="none";
                    onlyPagtination={true}
                    data={userDataList}
                    onPageChange={(data) => {
                      setTableData(data);
                    }}
                    initialItemsPerPage={10}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>

        <Spin spinning={loader}>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            className="table-datas"
            style={{ marginTop: "20px", borderRadius: "8px" }}
          />
        </Spin>

        <Pagination
          data={filteredData}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>

      <Modal
        open={openModal}
        onCancel={resetModalState}
        maskClosable={false}
        confirmLoading={modalLoader}
        onOk={async () => {
          await handleSubjectSubmission();
        }}
        okText="Save"
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <h3 style={{ color: "#00ADC4", textAlign: "center" }}>
          {editingRecord ? "Edit Subject" : "Add Subject"}
        </h3>
        <hr />
        <Spin spinning={modalLoader}>
          <Form form={form} layout="vertical" onFinish={handleSave}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Subject Name"
                  name="subject_name"
                  rules={[
                    { required: true, message: "Please enter subject name!" },
                  ]}
                >
                  <Input
                    className="inputbox"
                    name="subject_name"
                    onChange={(e) => handleSubjectChange(e)}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Subject Code"
                  name="subject_code"
                  rules={[
                    { required: true, message: "Please enter subject code!" },
                  ]}
                >
                  <Input
                    className="inputbox"
                    name="subject_code"
                    onChange={(e) => handleSubjectChange(e)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}

export default Subject;
