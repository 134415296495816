import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Avatar,
  Select,
  Space,
  notification,
  Tag,
  message,
  Pagination,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
// import * as XLSX from "xlsx";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
// import './userManagement.css'
import { useParams } from "react-router-dom"; // Import useNavigate
import UserManagementedit from "../Component/Usermanagement/Parentedit";
import TableSortArrows from "../Component/tableSortArrows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
// import { customPagination } from "../helper";
import { PARENT } from "../constants/roles";
import { useDispatch, useSelector } from "react-redux";
import { getContactDetails } from "../store/contactDetails/contactDetails";
import { customPagination } from "../helper";

const { confirm } = Modal;
const { Option } = Select;

function Parent({ isEdit }) {
  // const navigate = useNavigate();
  // const [userArray, setUserArray] = useState([]);
  // const [openUploaddataListing, setOpenUploaddataListing] = useState(null);
  // const [openUploaddataListings, setOpenUploaddataListings] = useState(null);
  // const [userData, setUserData] = useState(allData);
  // const [search, setSearch] = useState("");
  // const [filteredData, setFilteredData] = useState([]);
  // const [excelData, setExcelData] = useState(null);
  // const [userDataList, setUserDataList] = useState(allData);
  // const [pageSizeTotal, setPageSizeTotal] = useState(10);
  // const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const contactDetails = useSelector((state) => state.contactDetails);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [excelData, setExcelData] = useState(null);
  const [userDataList, setUserDataList] = useState(allData);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  const { role } = useParams();

  useEffect(() => {
    dispatch(getContactDetails(queryParams));
  }, [queryParams, dispatch]);

  useEffect(() => {
    if (contactDetails?.data) {
      getContactDetailsList();
    }
  }, [contactDetails?.data]);

  useEffect(() => {
    if (contactDetails.isError) {
      message.error(contactDetails.error);
    }
  }, [contactDetails.isError]);

  useEffect(() => {
    if (searchQuery.length === 0) searchOnChange(searchQuery);
    if (searchQuery.length < 3) return;

    const delaySearch = setTimeout(() => {
      searchOnChange(searchQuery);
    }, 100);

    return () => clearTimeout(delaySearch);
  }, [searchQuery]);

  const getContactDetailsList = () => {
    const parentsData = contactDetails?.data?.data.map((parent) => {
      return {
        key: parent.contact_id,
        name: parent.full_name,
        admissionID: parent.student[0].admission_no,
        role: PARENT,
        Gradesec: "11-A",
        phoneNumber: parent.contact_number,
        status: "Active",
        avatarUrl: "https://i.pravatar.cc/40?img=1",
      };
    });

    setAllData(parentsData);
    setTableData(parentsData);
    setCount(contactDetails?.data?.count);
    setTotalcount(contactDetails?.data?.totalCount);
    setIsLoading(false);
  };

  const openOnChange = (value, teb) => {
    if (teb === "edit" || teb === "view") {
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb === "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate({});
    } else {
      confirmDelete();
    }
  };

  const pageSizeChange = async (page, pageSize) => {
    setIsLoading(true);

    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    dispatch(getContactDetails(ctr));
    setCurrentPage(page);
    setQueryParams(ctr);
  };

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     try {
  //       const data = new Uint8Array(e.target.result);
  //       const workbook = XLSX.read(data, { type: "array" });
  //       const sheetName = workbook.SheetNames[0];
  //       const sheet = workbook.Sheets[sheetName];
  //       const jsonData = XLSX.utils.sheet_to_json(sheet);
  //       if (jsonData.length) {
  //         setExcelData(jsonData);
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };

  //   reader.readAsArrayBuffer(file);
  // };

  useEffect(() => {
    handleRefresh();
  }, [role]);

  const confirmDelete = () => {
    confirm({
      title: <h5>Confirm Delete</h5>,
      content: <p>Are you sure you want to Delete?</p>,
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() {},
    });
  };

  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };

  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);
    setSelectedDate({});
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  const objectLength = Object.keys(userDataList).length;

  const columns = [
    ...(isEdit
      ? [
          {
            title: <input type="checkbox" />,
            render: (record) => <input type="checkbox" />,
          },
        ]
      : []),

    {
      title: "Parent Name",
      dataIndex: "name",
      width: 300,
      render: (text, record) => (
        <Space>
          <Avatar src={record.avatarUrl} />
          {text}
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Student Admission Number",
      dataIndex: "admissionID",
    },
    {
      title: "Grade & Sec",
      dataIndex: "Gradesec",
      sorter: (a, b) => a.Gradesec.localeCompare(b.Gradesec),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "#EC7C74"}`,
            color: status === "Active" ? "#fff" : "#fff",
            background:
              status === "Active"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  isEdit &&
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "delete")}
          />
        </div>
      ),
    });

  const useronChange = (value) => {
    if (value) {
      const filtered = allData.filter(
        (a) => a.Gradesec?.toLowerCase() === value?.toLowerCase()
      );
      // setUserDataList(filtered);
      setTotalcount(filtered.length);
      setUserType(value);
    } else {
      // setUserDataList(allData);
      setTotalcount(allData.length);
      setUserType("");
    }
  };

  const handleRefresh = async () => {
    if (role) {
      let finded = allData.filter(
        (a) => a.role === capitalizeFirstLetter(role)
      );
      // setUserData(finded);
      setTotalcount(finded.length);
      setUserType(role);
    } else {
      // setUserData(allData);
      setTotalcount(allData.length);
    }
  };

  const searchOnChange = (value) => {
    setIsLoading(true);
    let param = { _start: 0, _limit: 10 };
    param.search = value;
    dispatch(getContactDetails(param));
    setCurrentPage(1);
  };

  return (
    <>
      <div>
        {!editModuleOpen ? (
          <div className="tablecontainer">
            <div className="tableinnercontainer">
              <div className="d-md-flex justify-content-between align-items-center ">
                <div className="search-id gap-3">
                  <Input
                    onChange={(e) => searchOnChange(e.target.value)}
                    value={search}
                    placeholder="Search by Admission Number"
                    prefix={<SearchOutlined />}
                    className="seachAccadamicemployee"
                  />
                  <div className="wholeselect ">
                    <Select
                      value={userType}
                      onChange={useronChange}
                      className=""
                      allowClear
                      placeholder="Select Grade"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      style={{ width: "150px", height: "40px" }}
                      dropdownStyle={{ width: "150px" }}
                      options={[
                        { value: "", label: "Select Grade" },
                        { value: "9-A", label: "9-A" },
                        { value: "9-B", label: "9-B" },
                        { value: "10-A", label: "10-A" },
                        { value: "10-B", label: "10-B" },
                        { value: "11-A", label: "11-A" },
                        { value: "11-B", label: "11-B" },
                        { value: "12-A", label: "12-A" },
                      ]}
                    />
                  </div>
                </div>

                {isEdit ? (
                  <div>
                    <Select
                      allowClear
                      className="class-select-btns"
                      placeholder="Bulk Select"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      style={{ width: "150px" }}
                      dropdownStyle={{ width: "150px" }}
                      onChange={(value) =>
                        console.log("Bulk action selected:", value)
                      }
                    >
                      <Option value="Active">Active</Option>
                      <Option value="Inactive">Inactive</Option>
                    </Select>
                  </div>
                ) : null}

                <div className="d-lg-flex align-items-center ">
                  <div className="d-flex justify-content-center px-3 gap-3">
                    <div className="pagination_align">
                      <div className=" d-md-flex d-none">
                        <Pagination
                          total={totalcount}
                          defaultCurrent={1}
                          current={currentPage}
                          defaultPageSize={10}
                          pageSize={queryParams._limit}
                          onChange={pageSizeChange}
                          itemRender={customPagination}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Table
                loading={isLoading}
                columns={columns}
                dataSource={tableData}
                pagination={false}
                className="table-datas tablecontent"
              />
            </div>
            <div className="pagination_align">
              <div className="d-md-flex d-none">
                <span>{`Showing ${
                  queryParams._start + 1
                } - ${count} of ${totalcount}`}</span>
              </div>
              <div>
                <Pagination
                  total={totalcount}
                  defaultCurrent={1}
                  current={currentPage}
                  defaultPageSize={10}
                  pageSize={queryParams._limit}
                  pageSizeOptions={["5", "10", "25", "50"]}
                  onChange={pageSizeChange}
                  itemRender={customPagination}
                  showSizeChanger={true}
                />
              </div>
            </div>
          </div>
        ) : (
          <UserManagementedit
            record={selectedDate}
            onClose={onClose}
            type={teb}
            selectedId={selectedId}
          />
        )}
      </div>
    </>
  );
}

export default Parent;
