import React, { useState } from "react";
import { Row, Col, Form, Input, Table, DatePicker, Modal, Select } from "antd";
import TableSortArrows from "../tableSortArrows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { PlusOutlined } from "@ant-design/icons";
import { Color } from "antd/es/color-picker";
import { StarFilled } from "@ant-design/icons";

const { Option } = Select;

function Feesadd({
  selectedId,
  onClose,
  selectedData,
  type,
  tableData,
  setConsolidateFee,
  onlyvalue,
  showGst,
}) {
  const [model, setModel] = useState(false);

  const handlemodel = () => {
    setModel(true);
  };
  const handleCancel = () => {
    setModel(false);
  };
  const handleOk = () => {
    setModel(false);
  };

  const columns = [
    {
      title: "Fees Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "admissionNo",
    },
    {
      title: "Fixed",
      dataIndex: "role",
    },
    {
      title: "Amount",
      dataIndex: "phoneNumber",
    },
  ];
  console.log(type, tableData, "fessrecor");
  return (
    <div className="feescontainer">
      <div className="feesinnercontainer">
        {type == "edit" ? (
          <div>
            <div className="feesinner">
              <Form layout="vertical" className="p-4">
                {/* First Name Field */}
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8} lg={16}>
                    <Form.Item name="feeName" label="Fees Name">
                      <Input
                        placeholder=""
                        className="inputboxff"
                        defaultValue="Accadamic fee"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Middle Name Fields */}
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="accadamic Year" label="Academic Year">
                      <Input
                        placeholder=""
                        className="inputbox"
                        defaultValue="2024-2025"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="Class" label="Class">
                      {/* <Input placeholder="" className="inputbox" /> */}
                      <Select
                        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      >
                        <Option>LKG</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        ) : type == "add" ? (
          <div>
            <div className="feesinner">
              <Form layout="vertical" className="p-4">
                {/* First Name Field */}
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8} lg={16}>
                    <Form.Item name="feeName" label="Fees Name">
                      <Input placeholder="" className="inputboxff" />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Middle Name Fields */}
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="accadamic Year" label="Academic Year">
                      <Input placeholder="" className="inputbox" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="Class" label="Class">
                      <Input placeholder="" className="inputbox" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        ) : type == "view" ? (
          <div>
            <div className="feesinner">
              <Form layout="vertical" className="p-4">
                {/* First Name Field */}
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8} lg={16}>
                    <Form.Item name="feeName" label="Fees Name">
                      <Input placeholder="" className="inputboxff" />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Middle Name Fields */}
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="accadamic Year" label="Academic Year">
                      <Input placeholder="" className="inputbox" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item name="Class" label="Class">
                      <Input placeholder="" className="inputbox" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        ) : null}

        <>
          <div className="feesinner">
            {/* <h5></h5> */}
            <p className="tablehearder"> Fees Item</p>
            {/* <Table
      columns={columns}
      pagination={false}
      style={{
        height: "400px",
        background: "#ECF0F3",
        position: "relative", 
      }}
      locale={{
        emptyText: (
          <div>
            <p>No Data Found</p>
          </div>
        ),
      }}
    /> */}
            {type == "add" ? (
              <>
                <table className="emtytable">
                  <thead className="theadhover">
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                        backgroundcolor: " #00adc4",
                      }}
                    >
                      <th>Fees Name</th>
                      <th>Description</th>
                      <th>Fixed</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      // minHeight: "200px",
                      // border: "solid 1px blue",
                      display: "block",
                    }}
                  >
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td
                        colSpan={4}
                        style={{
                          height: "200px",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <button className="addfesbtn" onClick={handlemodel}>
                          <PlusOutlined /> Add Another Fees
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : type == "edit" ? (
              <>
                <table className="emtytable">
                  <thead className="theadhover">
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                        backgroundcolor: " #00adc4",
                      }}
                    >
                      <th>Fees Name</th>
                      <th>Description</th>
                      <th>Fixed</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      // minHeight: "200px",
                      // border: "solid 1px blue",
                      display: "block",
                    }}
                  >
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td>Annual Fee</td>
                      <td>Whole Term Fee</td>
                      <td></td>
                      <td> ₹ 50,000</td>
                    </tr>
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td>Term - 1</td>
                      <td> Term Fee</td>
                      <td></td>
                      <td>₹ 50,000</td>
                    </tr>{" "}
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td>Book Fee</td>
                      <td>Subject wise Book </td>
                      <td>
                        <StarFilled
                          style={{ color: "#00ADC4", fontSize: "30px" }}
                        />
                      </td>
                      <td>₹ 10,000</td>
                    </tr>
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td>Stationary Fee</td>
                      <td>Belt , Socks , Shoe , Coat </td>
                      <td>
                        <StarFilled
                          style={{ color: "#00ADC4", fontSize: "30px" }}
                        />
                      </td>
                      <td>₹ 10,000</td>
                    </tr>
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td
                        colSpan={4}
                        style={{
                          height: "200px",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <button className="addfesbtn" onClick={handlemodel}>
                          <PlusOutlined /> Add Another Fee
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <table className="emtytable">
                  <thead className="theadhover">
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                        backgroundcolor: " #00adc4",
                      }}
                    >
                      <th>Fees Name</th>
                      <th>Description</th>
                      <th>Fixed</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      // minHeight: "200px",
                      // border: "solid 1px blue",
                      display: "block",
                    }}
                  >
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td>Annual Fee</td>
                      <td>Whole Term Fee</td>
                      <td></td>
                      <td> ₹ 50,000</td>
                    </tr>
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td>Term - 1</td>
                      <td> Term Fee</td>
                      <td></td>
                      <td>₹ 50,000</td>
                    </tr>{" "}
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td>Book Fee</td>
                      <td>Subject wise Book </td>
                      <td>
                        <StarFilled
                          style={{ color: "#00ADC4", fontSize: "30px" }}
                        />
                      </td>
                      <td>₹ 10,000</td>
                    </tr>
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <td>Stationary Fee</td>
                      <td>Belt , Socks , Shoe , Coat </td>
                      <td>
                        <StarFilled
                          style={{ color: "#00ADC4", fontSize: "30px" }}
                        />
                      </td>
                      <td>₹ 10,000</td>
                    </tr>
                    <tr
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      {/* <td
              colSpan={4}
              style={{
                height: "200px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button className="addfesbtn" onClick={handlemodel}>
                <PlusOutlined /> Add Another Fee
              </button>
            </td> */}
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
          <Form className="p-4">
            {/* First Name Field */}
            <div className="row align-items-end g-5">
              <div className="col-md-6">
                <Form.Item name="dueDate" label="Due Date">
                  <DatePicker
                    format={{
                      format: "YYYY-MM-DD",
                      type: "mask",
                    }}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6 ">
                <Form.Item name="totalfee" label="Total Fee">
                  <Input placeholder="" className="inputboxff" />
                </Form.Item>
              </div>
            </div>
            <div
              className="row flex-column align-items-end class-total-value"
              data={!onlyvalue ? showGst : "d-black"}
            >
              {!onlyvalue && (
                <div className="col-md-6 ">
                  <Form.Item name="GST Value" label="GST Value">
                    <Input placeholder="" className="inputboxff" />
                  </Form.Item>
                </div>
              )}
              {!onlyvalue && (
                <div className="col-md-6 ">
                  <Form.Item name="Total" label="Total   ">
                    <Input placeholder="" className="inputboxff" />
                  </Form.Item>
                </div>
              )}
            </div>
          </Form>
        </>
      </div>
      {/* <div className="addfeesbtncon">
        <button className="addbtncon" onClick={handlemodel}>
          <PlusOutlined />
          Add Another Fee
        </button>
      </div> */}
      {type == "add" ? (
        <div className="feesbtn">
          <button className="feessavebtn" onClick={onClose}>
            Save Draft
          </button>
          <button className="feespublishbtn" onClick={onClose}>
            Published
          </button>
        </div>
      ) : type == "edit" ? (
        <div className="feesbtn">
          <button className="feessavebtn" onClick={onClose}>
            Update Draft
          </button>
          <button className="feespublishbtn" onClick={onClose}>
            Published
          </button>
        </div>
      ) : (
        <div className="feesbtn">
          <button
            style={{
              marginBottom: "10px",
              padding: "5px",
              borderRadius: "5px",
              width: "100px",
              background: "#ff6666",
              border: "none",
              color: "white",
            }}
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      )}
      {/* <div className="feesbtn">
        <button className="feessavebtn" onClick={onClose}>
          Save Draft
        </button>
        <button className="feespublishbtn" onClick={onClose}>
          Published
        </button>
      </div> */}

      <Modal open={model} onCancel={handleCancel} onOk={handleOk}>
        <div className="row">
          <div className="col-md-6 ">
            <Form.Item name="dueDate" label="Fee Name">
              <Input placeholder="" className="inputboxff" />
            </Form.Item>
          </div>
          <div className="col-md-6 ">
            <Form.Item name="dueDate" label="Description">
              <Input placeholder="" className="inputboxff" />
            </Form.Item>
          </div>
          <div className="col-md-6 ">
            <Form.Item name="dueDate" label="Fixed">
              <Input placeholder="" className="inputboxff" />
            </Form.Item>
          </div>
          <div className="col-md-6 ">
            <Form.Item name="dueDate" label="Amount">
              <Input placeholder="" className="inputboxff" />
            </Form.Item>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Feesadd;
