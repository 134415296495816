import React, { useState } from "react";
import { Layout, Card, Typography, Calendar, Button, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExamPerformance from "../../layout/base/charts/ExamPerformance";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ViewStudent from "../../Component/Departmentadmin/Departmenttable.jsx";
import Pagination from "../../Component/pagination/index.jsx";
const data = [
  {
    key: "1",
    Marks: "85",
    grade: "A+",
    Subject: "Tamil",
  },
  {
    key: "2",
    Marks: "85",
    grade: "A+",
    Subject: "English",
  },
  {
    key: "3",
    Marks: "85",
    grade: "A+",
    Subject: "Maths",
  },
  {
    key: "4",
    Marks: "85",
    grade: "A+",
    Subject: "Physics",
  },
  {
    key: "5",
    Marks: "85",
    grade: "A+",
    Subject: "Chemistry",
  },
  {
    key: "6",
    Marks: "85",
    grade: "A+",
    Subject: "Computer Science",
  },
];

const ClassSubjectView = ({ setIsOpen, isOpen, settabVisible }) => {
  const [userArray, setUserArray] = useState(data);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [teb, setTeb] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  const openOnChange = (value, teb) => {
    // settabVisible(false);
    if (teb === "view") {
      setModuleOpen(true);
      setSelectedDate(value);
    } else {
      setModuleOpen(false);
      setSelectedDate({});
    }
  };

  const columns = [
    // { title: "Exam Name", dataIndex: "Examname", key: "Examname" },
    { title: "Subject", dataIndex: "Subject", key: "Subject" },
    { title: "Marks", dataIndex: "Marks", key: "Marks" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    // { title: "Mobile No", dataIndex: "Mobileno", key: "Mobileno" },
    // {
    //   title: "Action",
    //   dataIndex: "status",
    //   align: "center",
    //   render: (_, record) => (
    //     <div>
    //       <FontAwesomeIcon
    //         icon={faEye}
    //         size="lg"
    //         style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
    //         onClick={() => openOnChange(record, "view")}
    //       />
    //     </div>
    //   ),
    // },
  ];
  console.log(isOpen, "isopen");

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const objectLength = Object.keys(userArray).length;

  return (
    <div>
      <>
        <div className="studenttable">
          {/* <button onClick={() => setIsOpen(!isOpen)}>Back1</button> */}
          {/* <h4>Student Table</h4> */}

          <Table dataSource={tableData} columns={columns} pagination={false} />
          <Pagination
            data={userArray}
            onPageChange={(data) => {
              setTableData(data);
            }}
            initialItemsPerPage={10}
          />
        </div>
      </>
    </div>
  );
};
export default ClassSubjectView;
