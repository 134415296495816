import React, { useState, useEffect } from "react";
import Navbar from "../../Pages/SuperAdmin/Navbar.jsx";
import Profile from "../../Assets/png/Profile.png";
import {
  Form,
  Input,
  //   Select,
  //   Checkbox,
  //   Button,
  //   Upload,
  //   form,
  //   DatePicker,
  //   message,
  //   Spin,
} from "antd";
const LoginProfile = () => {
  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <div className="Login-info-data">
          <div className="row p-2">
            <div className="col-md-4 p-3">
              <div className="Login-info-id">
                <div className="d-flex align-items-center justify-content-center mb-4">
                  <img src={Profile} alt="Profile" className="profile-img" />
                </div>

                <h2>Samuel</h2>
                <p className="mb-3">Last Sign in 4 minutes ago</p>
                <p2 className="mb-5">User ID : Samuel_1234567</p2>
                <div className="Login-info-pass">
                  <div className="d-flex align-items-center gap-3">
                    <div className="Login-pass-data"></div>
                    <p1>Change Password</p1>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <div className="Login-pass-data"></div>
                    <p1>Change Password</p1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="Login-info-infor">
                <div>
                  <p>Personal Information</p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="first_name_login"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter First Name!",
                        },
                        {
                          max: 30,
                          message: "First Name cannot exceed 30 characters!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="First Name"
                        className="inputbox"
                        maxLength={30}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="last_name_login"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Last Name!",
                        },
                        {
                          max: 30,
                          message: "Last Name cannot exceed 30 characters!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Last Name"
                        className="inputbox"
                        maxLength={30}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-8">
                    <Form.Item
                      name="email"
                      label="Email Address"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Email Address!",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid Email Address!",
                        },
                      ]}
                    >
                      <Input placeholder="Email Address" className="inputbox" />
                    </Form.Item>
                  </div>
                  <div className="col-md-3 d-flex align-items-center mt-2">
                    <button>Verified</button>
                  </div>
                  <div className="col-md-8">
                    <Form.Item
                      name="phone_number"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Phone Number!",
                        },
                        {
                          max: 18,
                          message: "Phone Number cannot exceed 18 digits!",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Phone Number"
                        className="inputbox"
                        maxLength={18}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-3 d-flex align-items-center vs">
                    <button>Verified</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginProfile;
