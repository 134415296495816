import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";

export const getDriverDetails = createAsyncThunk(
  "driver/getDriverDetails",
  async (params, { rejectWithValue, fulfillWithValue }) => {
    let query = convertQueryParams(params);

    let payload = {
      method: "get",
      url: `/driver?${query}`,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

export const addDriver = createAsyncThunk(
  "driver/addDriver",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    let payload = {
      method: "post",
      url: `/driver`,
      data,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

export const updateDriver = createAsyncThunk(
  "driver/updateDriver",
  async ({ data, selectedId }, { rejectWithValue, fulfillWithValue }) => {
    let payload = {
      method: "put",
      url: `/driver/${selectedId}`,
      data,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

const incorpSlice = createSlice({
  name: "driver",
  initialState: {
    status: "idle",
    isLoading: false,
    data: null,
    isError: false,
    error: null,
    message: null,
  },
  reducers: {
    resetDriverErrorAndMessage: (state) => {
      state.error = null;
      state.isError = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    // get drivers
    builder.addCase(getDriverDetails.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
    });

    builder.addCase(getDriverDetails.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload.data;
    });

    builder.addCase(getDriverDetails.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });

    // add driver
    builder.addCase(addDriver.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
      state.message = null;
    });

    builder.addCase(addDriver.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoading = false;
      // console.log(action.payload);
      switch (action.payload.status) {
        case 201:
          state.message = action.payload.message;
          state.isError = false;
          break;
        case 208:
          state.error = action.payload.message;
          state.isError = true;
          break;
        default:
          state.error = "Failed to process request!";
          state.isError = true;
      }
    });

    builder.addCase(addDriver.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });

    // update employee
    builder.addCase(updateDriver.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
      state.message = null;
    });

    builder.addCase(updateDriver.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.data.data = state.data.data.map((data) => {
        if (action.payload.data.driver_id === data.driver_id)
          return action.payload.data;
        else return data;
      });

      switch (action.payload.status) {
        case 200:
          state.message = action.payload.message;
          state.isError = false;
          break;
        case 404:
          state.error = action.payload.message;
          state.isError = true;
          break;
        default:
          state.error = "Failed to process request!";
          state.isError = true;
      }
    });

    builder.addCase(updateDriver.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });
  },
});

export const { resetDriverErrorAndMessage } = incorpSlice.actions;
export default incorpSlice.reducer;
