import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  Row,
  Col,
  message,
  DatePicker,
  Switch,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import avatar from "../../Assets/png/avatar.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { fetchApi } from "../../helper";
import { SCHOOL_ADMIN, TEACHER } from "../../constants/roles";
import { useDispatch, useSelector } from "react-redux";
import { getSubjects } from "../../store/settings/subject";
import { getDepartments } from "../../store/settings/department";
import { getClassSectionDetails } from "../../store/settings/class";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { faPlus, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  addEmployee,
  getEmployee,
  resetEmployeeErrorAndMessage,
  updateEmployee,
} from "../../store/employeeDetails/employeeDetailsSlice";

const { Option } = Select;

const countryPhoneCodes = {
  "Select Country": { code: "+1", maxLength: 10 }, // Default
  Afghanistan: { code: "+93", maxLength: 9 },
  Albania: { code: "+355", maxLength: 8 },
  Algeria: { code: "+213", maxLength: 9 },
  Andorra: { code: "+376", maxLength: 6 },
  Argentina: { code: "+54", maxLength: 10 },
  Australia: { code: "+61", maxLength: 9 },
  Bangladesh: { code: "+880", maxLength: 10 },
  Belgium: { code: "+32", maxLength: 9 },
  Brazil: { code: "+55", maxLength: 11 },
  Canada: { code: "+1", maxLength: 10 },
  China: { code: "+86", maxLength: 11 },
  Egypt: { code: "+20", maxLength: 10 },
  France: { code: "+33", maxLength: 9 },
  Germany: { code: "+49", maxLength: 11 },
  India: { code: "+91", maxLength: 10 },
  Indonesia: { code: "+62", maxLength: 10 },
  Italy: { code: "+39", maxLength: 10 },
  Japan: { code: "+81", maxLength: 10 },
  Kenya: { code: "+254", maxLength: 9 },
  Malaysia: { code: "+60", maxLength: 10 },
  Mexico: { code: "+52", maxLength: 10 },
  Nepal: { code: "+977", maxLength: 10 },
  Nigeria: { code: "+234", maxLength: 10 },
  Pakistan: { code: "+92", maxLength: 10 },
  Philippines: { code: "+63", maxLength: 10 },
  Russia: { code: "+7", maxLength: 10 },
  Saudi: { code: "+966", maxLength: 9 },
  SouthAfrica: { code: "+27", maxLength: 9 },
  Spain: { code: "+34", maxLength: 9 },
  UK: { code: "+44", maxLength: 10 },
  USA: { code: "+1", maxLength: 10 },
};

const Academicopr = ({ selectedId, onClose, record, type, setUserArray }) => {
  const dispatch = useDispatch();
  const subject = useSelector((state) => state.subject);
  const department = useSelector((state) => state.department);
  const classSections = useSelector((state) => state.class);
  const employee = useSelector((state) => state.employee);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [form] = Form.useForm();
  const [imageSrc, setImageSrc] = useState(avatar);
  const [imgFile, setImgFile] = useState(null);
  const [isNewImg, setIsNewImg] = useState(false);
  const [gender, setGender] = useState("");
  const [showError, setShowError] = useState(false);
  // const [isSubmited, setIsSubmitted] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("USA");
  const [loader, setLoader] = useState(true);
  const [subjectOption, setSubjectOption] = useState([]);
  const [departmentOption, setDepartmentOption] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [maxLength, setMaxLength] = useState(
    countryPhoneCodes["USA"].maxLength
  );

  useEffect(() => {
    if (Object.keys(record).length > 0) {
      setImageSrc(record.image);
    }
  }, [record]);

  useEffect(() => {
    if (subject?.data) {
      setSubjectOptions();
    } else {
      dispatch(getSubjects());
    }
  }, [dispatch, subject?.data]);

  useEffect(() => {
    if (classSections?.data) {
      setSectionOptionsList();
    } else {
      dispatch(getClassSectionDetails(1));
    }
  }, [classSections?.data, dispatch]);

  useEffect(() => {
    if (department?.data) {
      setDepartmentOptions();
    } else {
      dispatch(getDepartments());
    }
  }, [department?.data, dispatch]);

  useEffect(() => {
    if (employee.message && !employee.isLoading && !employee.isError) {
      message.success(employee.message);
      dispatch(getEmployee({ _start: 0, _limit: 10 }));
      onClose();
    }

    dispatch(resetEmployeeErrorAndMessage());
    setIsFormDisabled(false);
    setLoader(false);
  }, [employee.message, employee.error]);

  const setSectionOptionsList = () => {
    let options = [];

    classSections?.data?.data?.forEach((classSection) => {
      options.push(
        <Option key={classSection.class_id} value={classSection.section}>
          {classSection.section}
        </Option>
      );
    });

    setSectionOptions(options);
  };
  //
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [formData, setFormData] = useState({
    schoolName: "",
    primaryEmail: "",
    contactNumber: "",
    schoolAddress: "",
    city: "",
    state: "",
    country: "",
    adminName: "",
    adminEmail: "",
    adminPhoneNumber: "",
    fileName: "",
    schoolType: "",
    password: "",
    confirmPassword: "",
  });

  const addField = () => {
    const newField = {
      id: Date.now(), // Unique ID for the dynamic field
      branchName: "",
      branchAddress: "",
      branchContactNumber: "",
      branchAdminName: "",
      branchAdminEmail: "",
      branchAdminMobileNumber: "",
    };
    setDynamicFields([...dynamicFields, newField]);
  };

  const removeField = (id) => {
    setDynamicFields(dynamicFields.filter((field) => field.id !== id));
  };

  //
  const setSubjectOptions = () => {
    let subjectOptionArr = [];

    subject?.data?.data.forEach((sub) => {
      subjectOptionArr.push(
        <Option key={sub.subject_id} value={sub.subject_id}>
          {sub.subject_name}
        </Option>
      );
    });
    setSubjectOption([subjectOption, ...subjectOptionArr]);
  };

  const setDepartmentOptions = () => {
    let departmentOptionArr = [];

    department?.data?.data.forEach((depart) => {
      departmentOptionArr.push(
        <Option key={depart.department_id} value={depart.department_name}>
          {depart.department_name}
        </Option>
      );
    });

    setDepartmentOption([departmentOption, ...departmentOptionArr]);
    setLoader(false);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setMaxLength(countryPhoneCodes[value]?.maxLength || 10);
  };

  const handleImageUpload = ({ fileList }) => {
    if (fileList.length > 0) {
      const selectedFile = fileList[0].originFileObj;
      setImgFile(selectedFile);
      setIsNewImg(true);

      const reader = new FileReader();

      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setImageSrc(null);
      setImgFile(null);
    }
  };
  const [multiSelectState, setMultiSelectState] = useState({});

  const handleCheckboxChange = (fieldId) => (e) => {
    setMultiSelectState((prev) => ({
      ...prev,
      [fieldId]: e.target.checked,
    }));
  };

  const uploadImage = async () => {
    const formData = new FormData();

    formData.append("file", imgFile);

    const payload = {
      method: "post",
      url: "/upload",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    return await fetchApi(payload)
      .then((response) => {
        if (response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formatDataForReq = async (inputValues) => {
    const empData = {};

    if (Object.hasOwn(inputValues, "access")) {
      empData.access = inputValues.access;
    } else {
      empData.access = true;
    }

    if (Object.keys(record).length === 0) {
      empData.status = "Active";
    } else {
      empData.status = record.status;
    }

    // empData.firstname = inputValues.firstname;
    // empData.middlename = inputValues.middlename;
    // empData.lastname = inputValues.lastname;
    // empData.email = inputValues.email;
    // empData.dob = inputValues.dob;
    // empData.gender = inputValues.gender;
    // empData.mobilenumber = inputValues.mobilenumber;
    // empData.address = inputValues.address;
    // empData.nationality = inputValues.nationality;
    // empData.role = inputValues.role;
    // empData.employmenttype = inputValues.employmenttype;
    // empData.joiningAt = inputValues.joiningat;
    // empData.empId = inputValues.empid;
    // empData.assignedClass = inputValues.assignedclass;
    // empData.section = inputValues.section || "NULL";
    // empData.ClassTeacher = inputValues.ClassTeacher;
    // empData.subject = inputValues.subject;
    // empData.department = inputValues.department;
    // empData.qualification = inputValues.qualification;
    // empData.specialization = inputValues.specialization;
    // empData.experience = inputValues.experience;
    // empData.countrycode = inputValues.countrycode;

    if (type === "add") {
      empData.image = await uploadImage();
    } else if (type === "edit" && isNewImg) {
      empData.image = await uploadImage();
    } else if (type === "edit" && !isNewImg) {
      empData.image = imageSrc;
    }
    return empData;
  };

  const onFinish = (values) => {
    console.log("Original Form Data:", values);
    const basicDetails = {
      firstname: values.firstname,
      lastname: values.lastname,
      gender: values.gender,
      countrycode: values.countrycode,
      mobilenumber: values.mobilenumber,
      role: values.role,
      email: values.email,
      empid: values.empid,
    };
    // Extract static class details
    const staticClassDetails = {
      assignedclass: values.assignedclass,
      Section: values.Section,
      ClassTeacher: values.ClassTeacher ?? "NO",
      Subject: values.Subject,
      Department: values.Department,
    };

    // Extract dynamic class details
    const dynamicClassDetails = {};

    Object.keys(values).forEach((key) => {
      if (
        key.startsWith("assignedclass_") ||
        key.startsWith("Section_") ||
        key.startsWith("ClassTeacher_") ||
        key.startsWith("Subject_") ||
        key.startsWith("Department_")
      )
        if (key.startsWith("ClassTeacher_")) {
          dynamicClassDetails[key] = values[key] ?? "NO";
        } else {
          dynamicClassDetails[key] = values[key];
        }
    });

    // Print structured outputs
    console.log("Basic Details:", basicDetails);
    console.log("Static Class Details:", staticClassDetails);
    console.log("Dynamic Class Details:", dynamicClassDetails);

    // if (type === "add" && !imgFile) {
    //   message.error("Please upload a picture file.");
    //   return;
    // }

    // setLoader(true);
    // setIsFormDisabled(true);
    // const data = await formatDataForReq(values);

    // if (type === "add") dispatch(addEmployee(data));
    // else dispatch(updateEmployee({ data, selectedId }));
  };

  const handleBeforeUpload = (file) => {
    const MAX_FILE_SIZE = 500 * 1024; // 500 KB in bytes

    if (file.size > MAX_FILE_SIZE) {
      message.error("File size exceeds 500 KB. Please upload a smaller file.");
      return Upload.LIST_IGNORE; // Prevent upload
    }
    return false;
  };

  const handleGenderChange = (value) => {
    setGender(value);
    setShowError(false);
  };

  const handleClassSelection = (value) => {
    setLoader(true);
    setSectionOptions([]);
    dispatch(getClassSectionDetails(value));
    setLoader(false);
  };

  const specializations = [
    { label: "Engineering", value: "engineering" },
    { label: "Management", value: "management" },
    { label: "Arts", value: "arts" },
    { label: "Science", value: "science" },
    { label: "Information Technology", value: "it" },
    { label: "Other", value: "other" },
  ];

  return (
    <div className="Accodamicwholeopr">
      <Spin spinning={loader}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          disabled={type === "view" || isFormDisabled}
          initialValues={record || {}}
        >
          <div className="imagewholecontainer">
            <div className="imagedivcon">
              <div className="imagedivinnercon">
                <img
                  src={imageSrc}
                  crossOrigin="anonymous | use-credentials"
                  alt="employe pic"
                />
              </div>
            </div>
            {type !== "view" && (
              <div className="upload">
                <Upload
                  maxCount={1}
                  beforeUpload={handleBeforeUpload}
                  accept="image/*"
                  onChange={handleImageUpload}
                  showUploadList={false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    className="uploadbackground"
                  >
                    Upload Image
                  </Button>
                </Upload>
              </div>
            )}
          </div>
          <h3 className="textformat">Basic Details</h3>
          {/* {(type === 'view' || type === 'edit') && (
          <div style={{ width: 200, height: 200 }}>
            <img src="https://i.pravatar.cc/40?img=12" alt="ii" style={{ width: 200, height: 200 }} />
          </div>
        )} */}
          <div className="whitebagcontainer">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="firstname"
                  label="First Name"
                  rules={[
                    { required: true, message: "Please Enter First Name!" },
                  ]}
                >
                  <Input
                    placeholder="First Name"
                    className="inputbox"
                    maxLength="50"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="middlename" label="Middle Name">
                  <Input placeholder="Middle Name" className="inputbox" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="lastname"
                  label="Last Name"
                  rules={[
                    { required: true, message: "Please Enter Last Name!" },
                  ]}
                >
                  <Input
                    placeholder="Last Name"
                    className="inputbox"
                    maxLength="50"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="dob" label="Date of Birth">
                  <DatePicker
                    placeholder="Select Date"
                    style={{ width: "100%" }}
                    className="inputbox"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[{ required: true, message: "Please Enter Gender!" }]}
                >
                  <Select
                    suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    placeholder="Select Gender"
                    className="inputbox"
                    value={gender}
                    onChange={handleGenderChange}
                  >
                    <Option value="female">Female</Option>
                    <Option value="male">Male</Option>
                  </Select>
                  {showError && (
                    <p className="errorlabelhandle">Select your gender!</p>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item label="Mobile Number" required>
                  <Input.Group compact>
                    <Form.Item name="countrycode" initialValue="+91" noStyle>
                      <Select
                        onChange={handleCountryChange}
                        style={{ width: "30%" }}
                      >
                        {Object.keys(countryPhoneCodes).map((country) => (
                          <Option
                            key={country}
                            value={countryPhoneCodes[country].code}
                          >
                            {countryPhoneCodes[country].code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {/* <Select
                      defaultValue="USA"
                      onChange={handleCountryChange}
                      style={{ width: "30%" }}
                    >
                      {Object.keys(countryPhoneCodes).map((country) => (
                        <Option key={country} value={country}>
                          {countryPhoneCodes[country].code}
                        </Option>
                      ))}
                    </Select> */}
                    <Form.Item
                      name="mobilenumber"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Please enter Mobile Number!",
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Only numbers are allowed!",
                        },
                        {
                          min: maxLength,
                          message: `Mobile number must be at least ${maxLength} digits!`,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Mobile Number"
                        maxLength={maxLength}
                        style={{ width: "70%" }}
                        onPressEnter={(e) => {
                          form
                            .validateFields(["mobilenumber"])
                            .then(() => {
                              console.log(
                                "Valid number entered:",
                                e.target.value
                              );
                            })
                            .catch((err) => {
                              console.log("Validation error:", err);
                            });
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  name="role"
                  label="Employee Role"
                  rules={[
                    { required: true, message: "Please Enter Employee Role!" },
                  ]}
                >
                  <Select
                    suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    placeholder="Select Role"
                    style={{ width: "100%" }}
                    className="inputbox"
                  >
                    <Option value={TEACHER}>Teacher</Option>
                    <Option value={SCHOOL_ADMIN}>Admin</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, message: "Please Enter Email!" }]}
                >
                  <Input placeholder="Email" className="inputbox" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Form.Item name="nationality" label="Nationality">
                  <Input placeholder="Nationality" className="inputbox" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={24}>
                <Form.Item
                  className="student_address"
                  name="address"
                  label="Address"
                  // rules={[{ required: true, message: "Please Enter Address!" }]}
                >
                  {/* <Input
                    placeholder="Address"
                    className="inputboxempadd"
                    style={{ height: "85px" }}
                  /> */}
                  <textarea
                    placeholder="  Address"
                    className="inputboxempadd"
                    style={{ height: "85px" }}
                  ></textarea>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <h3 className="textformat">
            Educational and Professional Information
          </h3>
          <div className="whitebagcontainer">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="qualification" label="Qualification">
                  <Select
                    suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    placeholder="Select Qualification"
                    className="inputbox"
                    style={{ width: "100%" }} // Ensures the dropdown is responsive
                  >
                    <Option value="highschool">High School</Option>
                    <Option value="bachelor">Bachelor's Degree</Option>
                    <Option value="master">Master's Degree</Option>
                    <Option value="phd">PhD</Option>
                    <Option value="diploma">Diploma</Option>
                    <Option value="other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="specialization" label="Specialization">
                  <Select
                    suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    placeholder="Select Specialization"
                    style={{ width: "100%" }}
                    className="inputbox"
                  >
                    {specializations.map((special) => (
                      <Option key={special.value} value={special.value}>
                        {special.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item name="experience" label="Experience">
                  <Input placeholder="Experience" className="inputbox" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <h3 className="textformat">Employment Details</h3>
          <div className="whitebagcontainer">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="joiningat"
                  label="Joining Date"
                  // rules={[
                  //   { required: true, message: "Please Enter Joining Date!" },
                  // ]}
                >
                  <DatePicker
                    placeholder="Select Date"
                    style={{ width: "100%" }}
                    className="inputbox"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="empid"
                  label="Employee ID"
                  rules={[
                    { required: true, message: "Please Enter Employee ID!" },
                  ]}
                >
                  <Input placeholder="Employee ID" className="inputbox" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="employmenttype"
                  label="Employment Type"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Employment Type!",
                  //   },
                  // ]}
                >
                  <Select
                    suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    placeholder="Employment Type"
                    style={{ width: "100%" }}
                    className="inputbox"
                  >
                    <Option value="Full Time">Full Time</Option>
                    <Option value="Part Time">Part Time</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <h3 className="textformat">Class Details</h3>
          <div className="whitebagcontainer">
            <div className="class-classroom_details col-md-12">
              <div>
                {/* dynamic field */}
                {/* <Form layout="vertical"> */}
                {/* Static Fields */}
                <div className="row g-3" style={{ marginBottom: "16px" }}>
                  <div className="col-sm-4">
                    <Form.Item
                      name="assignedclass"
                      label="Assigned Class"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Assigned Class!",
                        },
                      ]}
                    >
                      {/* <Input
                          size="large"
                          placeholder="EnterAssigned Class"
                          style={{ width: "100%" }}
                        /> */}
                      <Select
                        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                        placeholder="Assigned Class"
                        style={{ width: "100%" }}
                        value={formData.contactNumber}
                        // onChange={handleInputChange}
                        onChange={handleClassSelection}
                        className="inputbox"
                      >
                        {Array.from({ length: 12 }, (_, i) => (
                          <Option key={i + 1} value={i + 1}>
                            {i + 1}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-sm-4">
                    <Form.Item
                      label="Section"
                      name="Section"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Section is required!",
                      //   },
                      // ]}
                    >
                      <Select
                        placeholder="Select Section"
                        className="inputbox"
                        value={formData.branchAdminName}
                        onChange={handleInputChange}
                        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      >
                        {sectionOptions}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-sm-4">
                    <Form.Item
                      label="Class Teacher"
                      name="ClassTeacher"
                      // name={`ClassTeacher`}
                    >
                      <Select
                        placeholder="Select Section"
                        className="inputbox"
                        onChange={handleClassSelection}
                        defaultValue="No"
                        value={formData.branchAdminName || "No"} // Ensures "No" is default
                        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-sm-4">
                    <Form.Item
                      name="Subject"
                      // name={`Subject`}
                      rules={[
                        {
                          required: true,
                          message: "Subjects is required!",
                          type: multiSelectState[`subject`]
                            ? "array"
                            : "string",
                        },
                      ]}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <label style={{ fontWeight: "bold" }}>Subjects</label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <label style={{ marginRight: "10px" }}>
                            MultiSelect
                          </label>
                          <input
                            type="checkbox"
                            onChange={handleCheckboxChange(`subject`)}
                          />
                        </div>
                      </div>

                      <Select
                        mode={
                          multiSelectState[`subject`] ? "multiple" : undefined
                        }
                        onChange={(value) =>
                          form.setFieldsValue({
                            [`Subject`]: value,
                          })
                        }
                        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                        placeholder="Select Subjects"
                        style={{ width: "100%", marginTop: "8px" }}
                        className="inputbox"
                      >
                        <Option value="Tamil">Tamil</Option>
                        <Option value="English">English</Option>
                        <Option value="Social">Social</Option>
                        <Option value="Science">Science</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-sm-4">
                    <Form.Item
                      label="Department"
                      name="Department"
                      // name={`Department_`}
                      rules={[
                        {
                          required: true,
                          message: "Department is required!",
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                        placeholder="Department"
                        style={{ width: "100%" }}
                        className="inputbox"
                      >
                        <option value="Primary">Primary</option>
                        <option value="Secondary">Secondary</option>
                        <option value="HigherSecondary">
                          Higher Secondary
                        </option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                {/* Dynamic Fields */}
                {dynamicFields.map((field) => (
                  <div
                    className="row g-3"
                    key={field.id}
                    style={{ marginBottom: "16px" }}
                  >
                    <div className="col-sm-4">
                      <Form.Item
                        label="assigned class"
                        name={`assignedclass_${field.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Assigned Class!",
                          },
                        ]}
                      >
                        <Select
                          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                          placeholder="Assigned Class"
                          style={{ width: "100%" }}
                          // value={formData.contactNumber}
                          // onChange={handleInputChange}
                          onChange={handleClassSelection}
                          className="inputbox"
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-sm-4">
                      <Form.Item
                        label="Section"
                        name={`Section_${field.id}`}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Section is required!",
                        //   },
                        // ]}
                      >
                        <Select
                          placeholder="Select Section"
                          className="inputbox"
                          value={formData.branchAdminName}
                          onChange={handleClassSelection}
                          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                        >
                          {sectionOptions}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-sm-4">
                      <Form.Item
                        label="Class Teacher"
                        name={`ClassTeacher_${field.id}`}
                      >
                        <Select
                          placeholder="Select Section"
                          className="inputbox"
                          onChange={handleClassSelection}
                          defaultValue="No"
                          value={formData.branchAdminName || "No"} // Ensures "No" is default
                          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-sm-4">
                      <Form.Item
                        name={`Subject_${field.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Subjects is required!",
                            type: multiSelectState[`subject_${field.id}`]
                              ? "array"
                              : "string",
                          },
                        ]}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please select your favourite colors!",

                        //   },
                        // ]}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <label style={{ fontWeight: "bold" }}>Subjects</label>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label style={{ marginRight: "10px" }}>
                              MultiSelect
                            </label>
                            <input
                              type="checkbox"
                              onChange={handleCheckboxChange(
                                `subject_${field.id}`
                              )}
                            />
                          </div>
                        </div>

                        <Select
                          mode={
                            multiSelectState[`subject_${field.id}`]
                              ? "multiple"
                              : undefined
                          }
                          onChange={(value) =>
                            form.setFieldsValue({
                              [`Subject_${field.id}`]: value,
                            })
                          }
                          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                          placeholder="Select Subjects"
                          style={{ width: "100%", marginTop: "8px" }}
                          className="inputbox"
                        >
                          <Option value="Tamil">Tamil</Option>
                          <Option value="English">English</Option>
                          <Option value="Social">Social</Option>
                          <Option value="Science">Science</Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-sm-4">
                      <Form.Item
                        label="Department"
                        name={`Department_${field.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Department is required!",
                          },
                        ]}
                      >
                        <Select
                          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                          placeholder="Department"
                          style={{ width: "100%" }}
                          // className="inputbox"
                        >
                          <option value="Primary">Primary</option>
                          <option value="Secondary">Secondary</option>
                          <option value="HigherSecondary">
                            Higher Secondary
                          </option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div
                      className="col-md-12"
                      // span={24}
                      style={{
                        textAlign: "right",
                        position: "relative",
                        top: "20px",
                      }}
                    >
                      <div>
                        <MinusCircleOutlined
                          style={{ fontSize: "20px", color: "red" }}
                          onClick={() => removeField(field.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}

                {/* Add Button */}
                <Row className="justify-content-end">
                  <div
                    className="addiconreg"
                    style={{ marginRight: "50px", marginTop: " -20px" }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{
                        fontSize: "20px",
                        color: "#00ADC4",
                        cursor: "pointer",
                      }}
                      onClick={addField}
                    />
                  </div>
                </Row>
                {/* </Form> */}
              </div>
            </div>
          </div>

          {type === "edit" ? (
            <Form.Item
              label="Allow Access"
              name="access"
              style={{ marginTop: "20px", marginLeft: "20px" }}
              valuePropName="checked"
            >
              <Switch defaultChecked={record?.access} />
            </Form.Item>
          ) : null}
          <div className="form-buttons">
            <Form.Item>
              {type !== "view" && (
                <Button
                  type="primary"
                  className="modifybutton"
                  htmlType="submit"
                  style={{ marginRight: "10px", width: "90px" }}
                >
                  {type === "edit" ? "Update" : "Add"}
                </Button>
              )}

              <Button
                disabled={isFormDisabled}
                htmlType="button"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default Academicopr;
