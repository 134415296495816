// import React, { useState, Children, useEffect } from "react";
// import { Layout, Menu, Typography, Tabs } from "antd";
// import { Link, Outlet } from "react-router-dom";
// import Navbar from "../base/Navbar";
// import { getUserData, useQuery } from "../../helper";
// import logo2 from "../../Assets/png/logo2.png";
// import logo from "../../Assets/png/logo.png";
// import { useDispatch } from "react-redux";
// import { breadcrum } from "../../action/Subbreadcrumaction";
// import { RightOutlined, LeftOutlined } from "@ant-design/icons";
// import Slider from "../../Assets/Svg/slider.svg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { ReactComponent as Event } from "../../Assets/Svg/event.svg";
// // import { ReactComponent as Circular } from "../../Assets/Svg/Circular.svg";
// // import { ReactComponent as Report } from "../../Assets/Svg/Report.svg";
// // import { ReactComponent as Parent } from "../../Assets/Svg/parent.svg";
// import { Report, Event, Parent, Circular } from "../../Assets/Svg/Svg.jsx";

// const { Sider } = Layout;
// const { SubMenu } = Menu;
// const { TabPane } = Tabs;

// const menuItems = [
//   {
//     key: "1",
//     label: "Dashboard",
//     icon: (
//       <span className="menu-icon">
//         <i class="fas fa-th-large fa-lg"></i>
//       </span>
//     ),
//     link: "/",
//     permissions: ["super-admin", roles.SCHOOL_ADMIN, "drivers", "teacher"],
//   },
//   {
//     key: "2",
//     label: "Academic Employee",
//     icon: (
//       <span className="menu-icon">
//         <i class="fas fa-chalkboard-teacher"></i>
//       </span>
//     ),

//     link: "/academic-employee",
//     permissions: ["super-admin", roles.SCHOOL_ADMIN, "teacher"],
//   },
//   {
//     key: "3",
//     label: "Students",
//     icon: (
//       <span className="menu-icon">
//         <i class="fas fa-graduation-cap"></i>
//       </span>
//     ),
//     link: "/students",
//     permissions: ["super-admin", roles.SCHOOL_ADMIN, "drivers"],
//   },
//   {
//     key: "4",
//     label: "Parents",
//     icon: (
//       <span className="menu-icon">
//         <Parent />
//       </span>
//     ),
//     link: "/parent",
//     permissions: ["super-admin", roles.SCHOOL_ADMIN, "teacher"],
//   },

//   {
//     key: "5",
//     label: "Drivers",
//     icon: (
//       <span className="menu-icon">
//         <i class="fas fa-user-alt"></i>
//       </span>
//     ),
//     link: "/drivers",
//     permissions: ["super-admin", roles.SCHOOL_ADMIN, "drivers", "teacher"],
//   },
//   {
//     key: "6",
//     label: "Events",
//     icon: (
//       <span className="menu-icon">
//         <Event />
//       </span>
//     ),

//     link: "/notices",
//     permissions: ["super-admin", roles.SCHOOL_ADMIN, "drivers", "teacher"],
//   },

//   {
//     key: "7",
//     label: "Circular",
//     icon: (
//       <span className="menu-icon">
//         {/* <i class="fas fa-th-large fa-lg"></i> */}
//         <Circular />
//       </span>
//     ),

//     link: "/circular",
//     permissions: ["super-admin", "drivers", roles.SCHOOL_ADMIN, "teacher"],
//   },
//   {
//     key: "8",
//     label: "Fees",
//     icon: (
//       <span className="menu-icon">
//         <i class="fas fa-tag"></i>
//       </span>
//     ),

//     link: "/fees",
//     permissions: ["super-admin", roles.SCHOOL_ADMIN, "teacher"],
//   },
//   {
//     key: "9",
//     label: "User Management",
//     icon: (
//       <span className="menu-icon">
//         <i class="fas fa-users"></i>
//       </span>
//     ),

//     link: "/user-management",
//     permissions: ["super-admin", roles.SCHOOL_ADMIN],
//   },
//   {
//     key: "10",
//     label: "Reports",
//     icon: (
//       <span className="menu-icon">
//         {/* <i class="fas fa-th-large fa-lg"></i> */}
//         <Report />
//       </span>
//     ),
//     link: "/reports",
//     permissions: ["student", "super-admin", roles.SCHOOL_ADMIN, "drivers", "teacher"],
//   },
//   {
//     key: "11",
//     label: "Department Admin",
//     icon: (
//       <span className="menu-icon">
//         <i class="fas fa-user-cog"></i>
//       </span>
//     ),

//     link: "/department-admin",
//     permissions: ["super-admin", roles.SCHOOL_ADMIN],
//   },

//   {
//     key: "12",
//     label: "Settings",
//     icon: (
//       <sapn className="menu-icon">
//         <i class="fas fa-cog"></i>
//       </sapn>
//     ),
//     link: "/setting",

//     permissions: ["super-admin", roles.SCHOOL_ADMIN, "drivers", "teacher"],
//   },
// ];

// const Sidebar = (props) => {
//   const dispatch = useDispatch();

//   const userData = getUserData();
//   const query = useQuery();
//   const [value, setvalue] = useState("");
//   const [collapsed, setCollapsed] = useState(true);
//   const [slidervisibility, setSlidervisibility] = useState(false);
//   const [sliderfixed, setSliderFixed] = useState(false);
//   const [sidebarVisible, setSidebarVisible] = useState(true);
//  const [isMenuActive, setIsMenuActive] = useState(null);

//   const menuItemStyle = {
//     color: "white",
//     fontSize: "14px",
//     fontFamily: "Nunito",
//   };
//   const iconStyle = { width: "16px", height: "16px", filter: "invert(1)" };
//   const [isMobile, setIsMobile] = useState(false);

//   const handleClickOutside = (event) => {
//     const sider = document.getElementsByClassName(
//       "ant-layout-sider-collapsed"
//     )[0];
//     const burgerIcon = document.querySelector(".fa-bars");

//     // Close the sidebar if the click is outside the sidebar or burger icon
//     if (
//       sider &&
//       !sider.contains(event.target) &&
//       !burgerIcon.contains(event.target)
//     ) {
//       sider.style.display = "none"; // Hide the sidebar
//     }
//   };

//   useEffect(() => {
//     const mediaQuery = window.matchMedia("(max-width: 770px)");
//     const handleResize = (e) => setIsMobile(e.matches);

//     handleResize(mediaQuery); // Set initial state
//     mediaQuery.addListener(handleResize); // Listen for changes

//     return () => mediaQuery.removeListener(handleResize);
//   }, []);

//   useEffect(() => {
//     if (isMobile) {
//       document.addEventListener("click", handleClickOutside);
//     } else {
//       document.removeEventListener("click", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, [isMobile]);

//   useEffect(() => {
//     const mediaQuery = window.matchMedia("(max-width: 770px)");
//     const handleResize = (e) => setCollapsed(e.matches);

//     handleResize(mediaQuery);
//     mediaQuery.addListener(handleResize);

//     return () => mediaQuery.removeListener(handleResize);
//   }, [query]);

//   const headerNameOnChange = () => {
//     if (query) {
//       // setvalue(query)
//       dispatch(breadcrum(query));
//     }
//   };

//   const toggleSidebar = () => {
//     setCollapsed(!collapsed);
//     const toggleIcon = document.getElementsByClassName("toggleicon")[0];
//     if (toggleIcon) {
//       // Add or remove a class for rotation
//       toggleIcon.classList.toggle("rotated");
//     }
//   };

//   const mouseenter = () => {
//     setSlidervisibility(true);
//     //  const rotaede = document.getElementsByClassName("toggleicon");
//     //   rotaede.style.rotate = "30deg";
//   };
//   const mouseleave = () => {
//     setSlidervisibility(false);
//   };

//   return (
//     <>
//       <Layout style={{ minHeight: "100vh" }}>
//         <Sider
//           width={collapsed ? 80 : 250}
//           collapsedWidth={80}
//           collapsible
//           collapsed={collapsed}
//           onCollapse={(value) => setCollapsed(value)}
//           style={{
//             // background: "#00ADC4",
//             color: "#fff",
//             borderTopRightRadius: "5px",
//             zIndex: "1250",
//             position: "sticky",
//             height: "100vh",
//             top: 0,
//           }}
//           trigger={null}
//         >
//           <div className="headerslide">
//             <div>
//               <Typography style={{}} className="logotypo">
//                 {/* <img src={logo2} alt="logo2" width={100} /> */}
//                 {collapsed ? (
//                   <img src={logo2} alt="logo2" width={40} />
//                 ) : (
//                   <img src={logo} alt="logo2" width={100} />
//                 )}
//               </Typography>
//             </div>
//           </div>
//           <div
//             onClick={toggleSidebar}
//             className="toggleicon"
//             style={{
//               fontSize: "20px",
//               color: "#D9D9D9",
//             }}
//           >
//             <i class="fa fa-indent" aria-hidden="true"></i>

//             {/* {collapsed ? (
//               <img src={Slider} width={20} alt="Slider Icon" />
//             ) : (
//               <img src={Slider} width={20} alt="Slider Icon" />
//             )} */}
//           </div>
//           <Menu className="siderscroll" mode="inline">
//             {menuItems
//               .filter((item) => item.permissions.includes(userData.role))
//               .map(({ key, label, icon, link, submenu }) => {
//                 if (submenu) {
//                   return (
//                     <SubMenu
//                       key={key}
//                       title={label}
//                       icon={icon}
//                       style={menuItemStyle}
//                       className="siderscrol"
//                     >
//                       {submenu
//                         .filter((subItem) =>
//                           subItem.permissions.includes(userData.role)
//                         )
//                         .map(
//                           ({ key: subKey, label: subLabel, link: subLink }) => (
//                             <Menu.Item key={subKey} style={menuItemStyle}>
//                               <Link
//                                 to={subLink}
//                                 style={{
//                                   color: "inherit",
//                                   textDecoration: "none",
//                                 }}
//                                 onClick={() => dispatch(breadcrum(subLabel))}
//                               >
//                                 {subLabel}
//                               </Link>
//                             </Menu.Item>
//                           )
//                         )}
//                     </SubMenu>
//                   );
//                 }

//                 return (
//                   <Menu.Item key={key} icon={icon} style={menuItemStyle}>
//                     <Link
//                       to={link}
//                       style={{ color: "inherit", textDecoration: "none" }}
//                       onClick={() => dispatch(breadcrum(label))}
//                     >
//                       {label}
//                     </Link>
//                   </Menu.Item>
//                 );
//               })}
//           </Menu>
//         </Sider>
//         <div className="table-size" style={{ width: "100%" }}>
//           <div
//             style={{
//               width: "100%",
//               zIndex: "1021",
//               left: "0px",
//               position: "sticky",
//               top: "0",
//             }}
//           >
//             <Navbar userData={userData} />
//           </div>
//           <div className="outletcontainer">
//             <div className="outletinnercontainer">
//               <div>
//                 <Outlet />
//               </div>
//             </div>
//           </div>
//         </div>
//       </Layout>
//     </>
//   );
// };

// export default Sidebar;

import React, { useState, useEffect } from "react";
import { Layout, Menu, Typography } from "antd";
import { Link, Outlet } from "react-router-dom";
import Navbar from "../base/Navbar";
import { getUserData, token, decodeToken } from "../../helper";
import logo2 from "../../Assets/png/logo2.png";
import logo from "../../Assets/png/logo.png";
import { useDispatch } from "react-redux";
import { breadcrum } from "../../action/Subbreadcrumaction";
import {
  AcademicEmployee,
  Student,
  Fees,
  Driver,
  Parent,
  UserManagement,
  Settings,
  Event,
  Circular,
  DepartmentAdmin,
  // Report,
  Coursemanagemant,
  Studentattenance,
  Grade,
  Leave,
  Classroom,
} from "../../Assets/Svg/Svg.jsx";
import roles from "../../constants/roles.js";

const { Sider } = Layout;
const { SubMenu } = Menu;
const userRole = decodeToken(token)?.role;
const menuItems = [
  {
    key: "1",
    label: "Dashboard",
    icon: <i className="fas fa-th-large fa-lg"></i>,
    link: "/",
    permissions: ["super-admin", roles.SCHOOL_ADMIN, roles.TEACHER],
  },
  {
    key: "2",
    label: "Academic Employee",
    icon: <AcademicEmployee />,
    link: "/academic-employee",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "3",
    label: "Students",
    icon: <Student />,
    link: "/students",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "4",
    label: "Parents",
    icon: <Parent />,
    link: "/parent",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "5",
    label: "Drivers",
    icon: <Driver />,
    link: "/drivers",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "6",
    label: "Events",
    icon: <Event />,
    link: "/events",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "7",
    label: "Circular",
    icon: <Circular />,
    link: "/circular",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "8",
    label: "Fees",
    icon: <Fees />,
    link: "/fees",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "9",
    label: "User Management",
    icon: <UserManagement />,
    link: "/user-management",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "10",
    label: "Reports",
    icon: <i className="fas fa-th-large fa-lg"></i>,
    link: "/reports",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "11",
    label: "Department Admin",
    icon: <DepartmentAdmin />,
    link: "/department-admin",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "12",
    label: "Settings",
    icon: <Settings />,
    link: "/settings",
    permissions: ["super-admin", roles.SCHOOL_ADMIN],
  },
  {
    key: "13",
    label: "classroom",
    icon: <Classroom />,
    link: "/classroom",
    permissions: [roles.TEACHER],
  },
  {
    key: "14",
    label: "Grades",
    icon: <Grade />,
    link: "/grade",
    permissions: [roles.TEACHER],
  },
  {
    key: "15",
    label: "Leave",
    icon: <Leave />,
    link: "/leave",
    permissions: [roles.TEACHER],
  },
  {
    key: "16",
    label: "Student Attendance",
    icon: <Studentattenance />,
    link: "/studentattenance",
    permissions: [roles.TEACHER],
  },
  {
    key: "17",
    label: "Course Management",
    icon: <Coursemanagemant />,
    link: "/coursemanagemant",
    permissions: [roles.TEACHER],
  },
  // {
  //   key: "18",
  //   label: "Settings",
  //   icon: <i className="fas fa-cog"></i>,
  //   link: "/settings",
  //   permissions: [TEACHER, "super-admin"],
  // },
];

const Sidebar = () => {
  const dispatch = useDispatch();
  const userData = getUserData();
  const [collapsed, setCollapsed] = useState(true);
  const [isMenuActive, setIsMenuActive] = useState(null);
  const [themeColor, setThemeColor] = useState("#001529");
  const [userImage, setUserImage] = useState("");

  const menuItemStyle = {
    color: "white",
    fontSize: "14px",
    fontFamily: "Nunito",
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = decodeToken(token);
        const imageUrl = decodedToken.image;
        setUserImage(imageUrl || "");
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [token]);

  const iconStyle = { width: "16px", height: "16px", filter: "invert(1)" };
  const [isMobile, setIsMobile] = useState(false);

  const handleClickOutside = (event) => {
    const sider = document.getElementsByClassName(
      "ant-layout-sider-collapsed"
    )[0];
    const burgerIcon = document.querySelector(".fa-bars");

    // Close the sidebar if the click is outside the sidebar or burger icon
    if (
      sider &&
      !sider.contains(event.target) &&
      !burgerIcon.contains(event.target)
    ) {
      sider.style.display = "none"; // Hide the sidebar
    }
  };

  useEffect(() => {
    // Retrieve theme color from local storage
    const storedColor = localStorage.getItem("themeColor");
    if (storedColor) {
      setThemeColor(storedColor);
    }
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 765px)");
    const handleResize = (e) => setIsMobile(e.matches);

    handleResize(mediaQuery); // Set initial state
    mediaQuery.addListener(handleResize); // Listen for changes

    return () => mediaQuery.removeListener(handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobile]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 770px)");
    const handleResize = (e) => setCollapsed(e.matches);

    handleResize(mediaQuery);
    mediaQuery.addListener(handleResize);

    return () => mediaQuery.removeListener(handleResize);
  }, []);

  const handleMenuClick = (key, label) => {
    setIsMenuActive(key);
    dispatch(breadcrum(label)); // Update the navbar text
  };

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(max-width: 770px)");
  //   const handleResize = (e) => setCollapsed(e.matches);

  //   handleResize(mediaQuery); // Set initial state
  //   mediaQuery.addListener(handleResize);

  //   return () => mediaQuery.removeListener(handleResize);
  // }, []);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    const toggleIcon = document.getElementsByClassName("toggleicon")[0];
    if (toggleIcon) {
      // Add or remove a class for rotation
      toggleIcon.classList.toggle("rotated");
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={collapsed ? 80 : 250}
        collapsedWidth={80}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          color: "#fff",
          borderTopRightRadius: "5px",
          zIndex: "1250",
          position: "sticky",
          height: "100vh",
          top: 0,
          // border: "solid 1px red",
          backgroundColor: themeColor,
        }}
        trigger={null}
      >
        <div className="headerslide">
          <Typography className="logotypo">
            {collapsed ? (
              <img src={logo2} alt="logo2" width={40} />
            ) : (
              <img src={logo} alt="logo2" width={100} />
            )}
          </Typography>
        </div>
        <div
          onClick={toggleSidebar}
          className="toggleicon"
          style={{
            fontSize: "20px",
            color: "#D9D9D9",
          }}
        >
          <i class="fa fa-indent" aria-hidden="true"></i>

          {/* {collapsed ? (
              <img src={Slider} width={20} alt="Slider Icon" />
            ) : (
              <img src={Slider} width={20} alt="Slider Icon" />
            )} */}
        </div>
        <Menu
          mode="inline"
          className="siderscroll"
          style={{ backgroundColor: themeColor }}
        >
          {menuItems
            .filter((item) => item.permissions.includes(userRole))
            .map(({ key, label, icon, link }, idx) => (
              <Menu.Item
                key={key}
                icon={
                  <span
                    className="menu-icon"
                    style={{
                      color: isMenuActive === key ? "#00ADC4" : "white",
                    }}
                  >
                    {icon}
                  </span>
                }
                style={menuItemStyle}
                onClick={() => handleMenuClick(key, label)} // Pass label here
              >
                <Link
                  to={link}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  {label}
                </Link>
              </Menu.Item>
            ))}
        </Menu>
      </Sider>
      <div className="table-size" style={{ width: "100%" }}>
        <div
          style={{
            width: "100%",
            zIndex: "1021",
            left: "0px",
            position: "sticky",
            top: "0",
          }}
        >
          <Navbar userData={userData} />
        </div>
        <div className="outletcontainer">
          <div className="outletinnercontainer">
            <Outlet />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Sidebar;
