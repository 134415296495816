import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Upload,
  DatePicker,
  message,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { UploadOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import avatar from "../../Assets/png/avatar.jpg";
import { fetchApi } from "../../helper";
// import { Color } from "antd/es/color-picker";
import { getClassSectionDetails } from "../../store/settings/class";
import { useDispatch, useSelector } from "react-redux";
import { getInstituteDeatils } from "../../store/settings/institute";
import {
  addStudent,
  getStudentDetails,
  resetStudentErrorAndMessage,
  updateStudent,
} from "../../store/studentDetails/studentDetails";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

// Country phone codes
const countryPhoneCodes = {
  "Select Country": { code: "+1", maxLength: 10 }, // Default
  Afghanistan: { code: "+93", maxLength: 9 },
  Albania: { code: "+355", maxLength: 8 },
  Algeria: { code: "+213", maxLength: 9 },
  Andorra: { code: "+376", maxLength: 6 },
  Argentina: { code: "+54", maxLength: 10 },
  Australia: { code: "+61", maxLength: 9 },
  Bangladesh: { code: "+880", maxLength: 10 },
  Belgium: { code: "+32", maxLength: 9 },
  Brazil: { code: "+55", maxLength: 11 },
  Canada: { code: "+1", maxLength: 10 },
  China: { code: "+86", maxLength: 11 },
  Egypt: { code: "+20", maxLength: 10 },
  France: { code: "+33", maxLength: 9 },
  Germany: { code: "+49", maxLength: 11 },
  India: { code: "+91", maxLength: 10 },
  Indonesia: { code: "+62", maxLength: 10 },
  Italy: { code: "+39", maxLength: 10 },
  Japan: { code: "+81", maxLength: 10 },
  Kenya: { code: "+254", maxLength: 9 },
  Malaysia: { code: "+60", maxLength: 10 },
  Mexico: { code: "+52", maxLength: 10 },
  Nepal: { code: "+977", maxLength: 10 },
  Nigeria: { code: "+234", maxLength: 10 },
  Pakistan: { code: "+92", maxLength: 10 },
  Philippines: { code: "+63", maxLength: 10 },
  Russia: { code: "+7", maxLength: 10 },
  Saudi: { code: "+966", maxLength: 9 },
  SouthAfrica: { code: "+27", maxLength: 9 },
  Spain: { code: "+34", maxLength: 9 },
  UK: { code: "+44", maxLength: 10 },
  USA: { code: "+1", maxLength: 10 },
};

const nationality = [
  { code: "Afghan", name: "Afghan" },
  { code: "Albanian", name: "Albanian" },
  { code: "Algerian", name: "Algerian" },
  { code: "Andorran", name: "Andorran" },
  { code: "Angolan", name: "Angolan" },
  { code: "Antiguan", name: "Antiguan" },
  { code: "Argentine", name: "Argentine" },
  { code: "Armenian", name: "Armenian" },
  { code: "Australian", name: "Australian" },
  { code: "Austrian", name: "Austrian" },
  { code: "Azerbaijani", name: "Azerbaijani" },
  { code: "Bahamian", name: "Bahamian" },
  { code: "Bahraini", name: "Bahraini" },
  { code: "Bangladeshi", name: "Bangladeshi" },
  { code: "Barbadian", name: "Barbadian" },
  { code: "Belarusian", name: "Belarusian" },
  { code: "Belgian", name: "Belgian" },
  { code: "Belizean", name: "Belizean" },
  { code: "Beninese", name: "Beninese" },
  { code: "Bhutanese", name: "Bhutanese" },
  { code: "Bolivian", name: "Bolivian" },
  { code: "Bosnian", name: "Bosnian" },
  { code: "Botswanan", name: "Botswanan" },
  { code: "Brazilian", name: "Brazilian" },
  { code: "Bruneian", name: "Bruneian" },
  { code: "Bulgarian", name: "Bulgarian" },
  { code: "Burkinabe", name: "Burkinabe" },
  { code: "Burundian", name: "Burundian" },
  { code: "Cambodian", name: "Cambodian" },
  { code: "Cameroonian", name: "Cameroonian" },
  { code: "Canadian", name: "Canadian" },
  { code: "Cape Verdean", name: "Cape Verdean" },
  { code: "Central African", name: "Central African" },
  { code: "Chadian", name: "Chadian" },
  { code: "Chilean", name: "Chilean" },
  { code: "Chinese", name: "Chinese" },
  { code: "Colombian", name: "Colombian" },
  { code: "Comorian", name: "Comorian" },
  { code: "Congolese", name: "Congolese" },
  { code: "Costa Rican", name: "Costa Rican" },
  { code: "Croatian", name: "Croatian" },
  { code: "Cuban", name: "Cuban" },
  { code: "Cypriot", name: "Cypriot" },
  { code: "Czech", name: "Czech" },
  { code: "Danish", name: "Danish" },
  { code: "Djiboutian", name: "Djiboutian" },
  { code: "Dominican", name: "Dominican" },
  { code: "Ecuadorian", name: "Ecuadorian" },
  { code: "Egyptian", name: "Egyptian" },
  { code: "Salvadoran", name: "Salvadoran" },
  { code: "Equatorial Guinean", name: "Equatorial Guinean" },
  { code: "Eritrean", name: "Eritrean" },
  { code: "Estonian", name: "Estonian" },
  { code: "Ethiopian", name: "Ethiopian" },
  { code: "Fijian", name: "Fijian" },
  { code: "Finnish", name: "Finnish" },
  { code: "French", name: "French" },
  { code: "Gabonese", name: "Gabonese" },
  { code: "Gambian", name: "Gambian" },
  { code: "Georgian", name: "Georgian" },
  { code: "German", name: "German" },
  { code: "Ghanaian", name: "Ghanaian" },
  { code: "Greek", name: "Greek" },
  { code: "Grenadian", name: "Grenadian" },
  { code: "Guatemalan", name: "Guatemalan" },
  { code: "Guinean", name: "Guinean" },
  { code: "Bissau-Guinean", name: "Bissau-Guinean" },
  { code: "Guyanese", name: "Guyanese" },
  { code: "Haitian", name: "Haitian" },
  { code: "Honduran", name: "Honduran" },
  { code: "Hungarian", name: "Hungarian" },
  { code: "Icelandic", name: "Icelandic" },
  { code: "Indian", name: "Indian" },
  { code: "Indonesian", name: "Indonesian" },
  { code: "Iranian", name: "Iranian" },
  { code: "Iraqi", name: "Iraqi" },
  { code: "Irish", name: "Irish" },
  { code: "Israeli", name: "Israeli" },
  { code: "Italian", name: "Italian" },
  { code: "Jamaican", name: "Jamaican" },
  { code: "Japanese", name: "Japanese" },
  { code: "Jordanian", name: "Jordanian" },
  { code: "Kazakh", name: "Kazakh" },
  { code: "Kenyan", name: "Kenyan" },
  { code: "I-Kiribati", name: "I-Kiribati" },
  { code: "North Korean", name: "North Korean" },
  { code: "South Korean", name: "South Korean" },
  { code: "Kuwaiti", name: "Kuwaiti" },
  { code: "Kyrgyz", name: "Kyrgyz" },
  { code: "Lao", name: "Lao" },
  { code: "Latvian", name: "Latvian" },
  { code: "Lebanese", name: "Lebanese" },
  { code: "Basotho", name: "Basotho" },
  { code: "Liberian", name: "Liberian" },
  { code: "Libyan", name: "Libyan" },
  { code: "Liechtenstein", name: "Liechtenstein" },
  { code: "Lithuanian", name: "Lithuanian" },
  { code: "Luxembourgish", name: "Luxembourgish" },
  { code: "Malagasy", name: "Malagasy" },
  { code: "Malawian", name: "Malawian" },
  { code: "Malaysian", name: "Malaysian" },
  { code: "Maldivian", name: "Maldivian" },
  { code: "Malian", name: "Malian" },
  { code: "Maltese", name: "Maltese" },
  { code: "Marshallese", name: "Marshallese" },
  { code: "Mauritanian", name: "Mauritanian" },
  { code: "Mauritian", name: "Mauritian" },
  { code: "Mexican", name: "Mexican" },
  { code: "Micronesian", name: "Micronesian" },
  { code: "Moldovan", name: "Moldovan" },
  { code: "Monégasque", name: "Monégasque" },
  { code: "Mongolian", name: "Mongolian" },
  { code: "Montenegrin", name: "Montenegrin" },
  { code: "Moroccan", name: "Moroccan" },
  { code: "Mozambican", name: "Mozambican" },
  { code: "Namibian", name: "Namibian" },
  { code: "Nauruan", name: "Nauruan" },
  { code: "Nepalese", name: "Nepalese" },
  { code: "Dutch", name: "Dutch" },
  { code: "New Zealander", name: "New Zealander" },
  { code: "Nicaraguan", name: "Nicaraguan" },
  { code: "Nigerien", name: "Nigerien" },
  { code: "Nigerian", name: "Nigerian" },
  { code: "Norwegian", name: "Norwegian" },
  { code: "Omani", name: "Omani" },
  { code: "Pakistani", name: "Pakistani" },
  { code: "Palauan", name: "Palauan" },
  { code: "Panamanian", name: "Panamanian" },
  { code: "Papua New Guinean", name: "Papua New Guinean" },
  { code: "Paraguayan", name: "Paraguayan" },
  { code: "Peruvian", name: "Peruvian" },
  { code: "Filipino", name: "Filipino" },
  { code: "Polish", name: "Polish" },
  { code: "Portuguese", name: "Portuguese" },
  { code: "Qatari", name: "Qatari" },
  { code: "Romanian", name: "Romanian" },
  { code: "Russian", name: "Russian" },
  { code: "British", name: "British" },
  { code: "American", name: "American" },
  { code: "Uruguayan", name: "Uruguayan" },
  { code: "Uzbek", name: "Uzbek" },
  { code: "Vietnamese", name: "Vietnamese" },
  { code: "Yemeni", name: "Yemeni" },
  { code: "Zambian", name: "Zambian" },
  { code: "Zimbabwean", name: "Zimbabwean" },
];

// TODO and CHECKS----------------------------
// is image needed for the parents, in db there is a field.
// in Ui there is not.

const AddStudentForm = ({ selectedId, onClose, record, type }) => {
  // console.log(selectedId, type, record, "accadamic");
  const dispatch = useDispatch();
  const classSections = useSelector((state) => state.class);
  const instituteDetails = useSelector((state) => state.institute);
  const studentDetails = useSelector((state) => state.students);

  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [imgsrc, setImgsrc] = useState(avatar);
  const [imgFile, setImgFile] = useState(null);
  const [isNewImg, setIsNewImg] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isAddressFather, setIsAddressFather] = useState(false);
  const [isAddressmother, setIsAddressmother] = useState(false);
  const [isAddressguardian, setIsAddressguardian] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const [isFatherChecked, setIsFatherChecked] = useState(false);
  const [isMotherChecked, setIsMotherChecked] = useState(false);
  const [isGuardianChecked, setIsGuardianChecked] = useState(false);
  const [error, setError] = useState(false);
  const [localFormDisabled, setLocalFormDisabled] = useState(false);
  const [selectedCountryFather, setSelectedCountryFather] = useState("+91");
  const [selectedCountryMother, setSelectedCountryMother] = useState("+91");
  const [selectedCountryGuardian, setSelectedCountryGuardian] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumbermother, setPhoneNumbermother] = useState("");
  const [phoneNumberguardian, setPhoneNumberguardian] = useState("");
  const [sectionOptions, setSectionOptions] = useState([]);
  const [schoolLocation, setSchoolLocation] = useState([]);
  const [isAvailableInChecked, setIsAvailableInChecked] = useState(false);
  const handleCountryChange1 = (value) => {
    setSelectedCountryFather(value);
  };

  const handleCountryChange2 = (value) => {
    setSelectedCountryMother(value);
  };

  const handleCountryChange3 = (value) => {
    setSelectedCountryGuardian(value);
  };

  useEffect(() => {
    if (Object.keys(record).length === 0) {
      setIsLoading(false);
    } else {
      setImgsrc(record.image);

      if (record.contactDetails) {
        record.contactDetails.forEach((contact) => {
          if (contact.type === "father") {
            form.setFieldValue("fatherfullName", contact.full_name);
            form.setFieldValue("fatherEmailId", contact.email_id);
            form.setFieldValue("fatherOccupation", contact.occupation);
            form.setFieldValue("fatherAddress", contact.address);
            form.setFieldValue("fatherAadharNo", contact.aadhar_no);

            if (contact?.address === record?.student_address) {
              setIsAddressFather(true);
            }
            if (contact?.contact_number) {
              const countryCode = contact.contact_number.slice(0, 3);
              const phoneNumber = contact.contact_number.slice(3);

              setSelectedCountryFather(countryCode);
              setPhoneNumber(phoneNumber);
            }

            if (contact?.is_primary) {
              setIsFatherChecked(contact.is_primary);
              setSelectedParent("father");
            }
          } else if (contact.type === "mother") {
            form.setFieldValue("motherfullName", contact.full_name);
            form.setFieldValue("motherEmailId", contact.email_id);
            form.setFieldValue("motherOccupation", contact.occupation);
            form.setFieldValue("motherAddress", contact.address);
            form.setFieldValue("motherAadharNo", contact.aadhar_no);

            if (contact?.address === record?.student_address) {
              setIsAddressmother(true);
            }
            if (contact?.contact_number) {
              const countryCode = contact.contact_number.slice(0, 3);
              const phoneNumber = contact.contact_number.slice(3);

              setSelectedCountryMother(countryCode);
              setPhoneNumbermother(phoneNumber);
            }

            if (contact?.is_primary) {
              setIsMotherChecked(contact.is_primary);
              setSelectedParent("mother");
            }
          } else if (contact.type === "guardian") {
            form.setFieldValue("guardianFullName", contact.full_name);
            form.setFieldValue("guardianEmailId", contact.email_id);
            form.setFieldValue("guardianOccupation", contact.occupation);
            form.setFieldValue("guardianAddress", contact.address);
            form.setFieldValue("guardianAadharNo", contact.aadhar_no);

            if (contact?.address === record?.student_address) {
              setIsAddressguardian(true);
            }
            if (contact?.contact_number) {
              const countryCode = contact.contact_number.slice(0, 3);
              const phoneNumber = contact.contact_number.slice(3);
              record.guardianMobileNumber = phoneNumber;
              setSelectedCountryGuardian(countryCode);
              setPhoneNumberguardian(phoneNumber);
            }

            if (contact?.is_primary) {
              setIsGuardianChecked(contact.is_primary);
              setSelectedParent("guardian");
            }
          }
        });
      } else {
        getContactDetails(record.student_id).then((data) => {
          data.forEach((contact) => {
            if (contact.type === "father") {
              form.setFieldValue("fatherfullName", contact.full_name);
              form.setFieldValue("fatherEmailId", contact.email_id);
              form.setFieldValue("fatherOccupation", contact.occupation);
              form.setFieldValue("fatherAddress", contact.address);
              form.setFieldValue("fatherAadharNo", contact.aadhar_no);

              if (contact?.address === record?.student_address) {
                setIsAddressFather(true);
              }
              if (contact?.contact_number) {
                const countryCode = contact.contact_number.slice(0, 3);
                const phoneNumber = contact.contact_number.slice(3);

                setSelectedCountryFather(countryCode);
                setPhoneNumber(phoneNumber);
              }

              if (contact?.is_primary) {
                setIsFatherChecked(contact.is_primary);
                setSelectedParent("father");
              }
            } else if (contact.type === "mother") {
              form.setFieldValue("motherfullName", contact.full_name);
              form.setFieldValue("motherEmailId", contact.email_id);
              form.setFieldValue("motherOccupation", contact.occupation);
              form.setFieldValue("motherAddress", contact.address);
              form.setFieldValue("motherAadharNo", contact.aadhar_no);

              if (contact?.address === record?.student_address) {
                setIsAddressmother(true);
              }
              if (contact?.contact_number) {
                const countryCode = contact.contact_number.slice(0, 3);
                const phoneNumber = contact.contact_number.slice(3);

                setSelectedCountryMother(countryCode);
                setPhoneNumbermother(phoneNumber);
              }

              if (contact?.is_primary) {
                setIsMotherChecked(contact.is_primary);
                setSelectedParent("mother");
              }
            } else if (contact.type === "guardian") {
              form.setFieldValue("guardianFullName", contact.full_name);
              form.setFieldValue("guardianEmailId", contact.email_id);
              form.setFieldValue("guardianOccupation", contact.occupation);
              form.setFieldValue("guardianAddress", contact.address);
              form.setFieldValue("guardianAadharNo", contact.aadhar_no);

              if (contact?.address === record?.student_address) {
                setIsAddressguardian(true);
              }
              if (contact?.contact_number) {
                const countryCode = contact.contact_number.slice(0, 3);
                const phoneNumber = contact.contact_number.slice(3);
                record.guardianMobileNumber = phoneNumber;
                setSelectedCountryGuardian(countryCode);
                setPhoneNumberguardian(phoneNumber);
              }

              if (contact?.is_primary) {
                setIsGuardianChecked(contact.is_primary);
                setSelectedParent("guardian");
              }
            }
          });
        });
      }

      setIsLoading(false);
    }
  }, [record]);

  useEffect(() => {
    if (classSections?.data) {
      setSectionOptionsList();
    } else {
      dispatch(getClassSectionDetails(1));
    }
  }, [classSections?.data, dispatch]);

  useEffect(() => {
    if (instituteDetails?.data) {
      // console.log(instituteDetails);
      let option = (
        <Option
          key={instituteDetails?.data?.data?.branchaddress}
          value={instituteDetails?.data?.data?.branchaddress}
        >
          {instituteDetails?.data?.data?.branchaddress}
        </Option>
      );
      setSchoolLocation(option);
    } else {
      dispatch(getInstituteDeatils());
    }
  }, [instituteDetails?.data, dispatch]);

  useEffect(() => {
    if (
      studentDetails.message &&
      !studentDetails.isLoading &&
      !studentDetails.isError
    ) {
      message.success(studentDetails.message);
      dispatch(getStudentDetails({ _start: 0, _limit: 10 }));
      onClose();
    }

    dispatch(resetStudentErrorAndMessage());
    setIsFormDisabled(false);
    setIsLoading(false);
  }, [studentDetails.message, studentDetails.error]);

  const setSectionOptionsList = () => {
    let options = [];

    classSections?.data?.data?.forEach((classSection) => {
      options.push(
        <Option key={classSection.class_id} value={classSection.section}>
          {classSection.section}
        </Option>
      );
    });

    setSectionOptions(options);
  };

  const getContactDetails = async (studentId) => {
    const payload = {
      method: "GET",
      url: `/contactdetails?studentId=${studentId}`,
    };

    return await fetchApi(payload)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        message.error("Error fetching contact details data!");
        console.log("Error: ", error);
      });
  };

  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const handleImageUpload = ({ fileList }) => {
    if (fileList.length > 0) {
      const selectedFile = fileList[0].originFileObj;
      setImgFile(selectedFile);
      setIsNewImg(true);

      // Read image using FileReader
      const reader = new FileReader();

      reader.onloadend = () => {
        setImgsrc(reader.result); // Set preview URL
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setImgsrc(null);
      setImgFile(null);
    }
  };

  const uploadImage = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
    const formData = new FormData();

    formData.append("file", imgFile);

    const payload = {
      method: "post",
      url: "/upload",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    return await fetchApi(payload)
      .then((response) => {
        if (response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formatDataForReq = async (inputValues) => {
    const student = {
      first_name: inputValues.first_name,
      middle_name: inputValues.middle_name,
      last_name: inputValues.last_name,
      date_of_birth: inputValues.date_of_birth,
      gender: inputValues.gender,
      nationality: inputValues.nationality,
      date_of_admission: inputValues.date_of_admission,
      admission_no: inputValues.admission_no,
      no_of_siblings: inputValues.no_of_siblings,
      selected_class: inputValues.selected_class,
      roll_no: inputValues.roll_no,
      register_no: inputValues.register_no,
      school_location: inputValues.school_location,
      address: inputValues.student_address,
      blood_group: inputValues.blood_group,
      religion: inputValues.religion,
      section: inputValues.section,
      department: inputValues.department,
      emis: "test",
      gov_id: inputValues.gov_id,
      contactDetails: [],
    };

    if (inputValues?.fatherfullName) {
      const fatherDetails = {
        full_name: inputValues.fatherfullName || null,
        contact_number: inputValues.fathermobileNumber
          ? `${selectedCountryFather}${inputValues.fathermobileNumber}`
          : null,
        type: "father",
        email_id: inputValues.fatherEmailId || null,
        occupation: inputValues.fatherOccupation || null,
        address: inputValues.fatherAddress || inputValues.student_address,
        aadhar_no: inputValues.fatherAadharNo || null,
        is_primary: isFatherChecked,
        image: "https://example.com/path/to/image.jpg",
      };
      student.contactDetails.push(fatherDetails);
    }

    if (inputValues?.motherfullName) {
      const motherDetails = {
        full_name: inputValues.motherfullName || null,
        contact_number: inputValues.motherMobileNumber
          ? `${selectedCountryFather}${inputValues.motherMobileNumber}`
          : null,
        type: "mother",
        email_id: inputValues.motherEmailId || null,
        occupation: inputValues.motherOccupation || null,
        address: inputValues.motherAddress || inputValues.student_address,
        aadhar_no: inputValues.motherAadharNo || null,
        is_primary: isMotherChecked,
        image: "https://example.com/path/to/image.jpg",
      };
      student.contactDetails.push(motherDetails);
    }

    if (inputValues?.guardianFullName) {
      const guardianDetails = {
        full_name: inputValues.guardianFullName || null,
        contact_number: inputValues.guardianMobileNumber
          ? `${selectedCountryFather}${inputValues.guardianMobileNumber}`
          : null,
        type: "guardian",
        email_id: inputValues.guardianEmailId || null,
        occupation: inputValues.guardianOccupation || null,
        address: inputValues.guardianAddress || inputValues.student_address,
        aadhar_no: inputValues.guardianAadharNo || null,
        is_primary: isGuardianChecked,
        image: "https://example.com/path/to/image.jpg",
      };

      student.contactDetails.push(guardianDetails);
    }

    if (type === "add") {
      student.image = await uploadImage();
    } else if (type === "edit" && isNewImg) {
      student.image = await uploadImage();
    } else if (type === "edit" && !isNewImg) {
      student.image = imgsrc;
    }

    return { student };
  };

  const onFinish = async (values) => {
    if (!isFatherChecked && !isMotherChecked && !isGuardianChecked) {
      setError(true);
      message.error("Please select at least one Primary Parent.");
      return;
    }

    // console.log("raw form values: ", values);
    if (type === "add" && !imgFile) {
      message.error("Please upload a picture file.");
      return;
    }

    setIsLoading(true);
    setIsFormDisabled(true);

    const data = await formatDataForReq(values);
    // console.log("Formatted student data: ", data);

    if (type === "add") dispatch(addStudent(data));
    else dispatch(updateStudent({ data, selectedId }));
  };

  const handleCheckboxChange = (parentType, setChecked) => {
    setSelectedParent((prev) => (prev === parentType ? null : parentType));
    setChecked((prev) => !prev);
    setError(false);
  };

  const handleClassChange = (value) => {
    let departmentValue = "";

    if (value >= 1 && value <= 5) {
      departmentValue = "Primary";
    } else if (value >= 6 && value <= 10) {
      departmentValue = "Secondary";
    } else if (value >= 11 && value <= 12) {
      departmentValue = "Higher secondary";
    }

    form.setFieldsValue({ department: departmentValue });

    setIsLoading(true);
    setSectionOptions([]);
    dispatch(getClassSectionDetails(value));
    setIsLoading(false);
  };

  const handleBeforeUpload = (file) => {
    const MAX_FILE_SIZE = 500 * 1024; // 500 KB in bytes
    if (file.size > MAX_FILE_SIZE) {
      message.error("File size exceeds 500 KB. Please upload a smaller file.");
      return Upload.LIST_IGNORE; // Prevent upload
    }
    return false;
  };

  return (
    <div>
      <div className="Accodamicwholeopr">
        <Spin spinning={isLoading}>
          <Form
            layout="vertical"
            form={form}
            ref={formRef}
            onFinish={onFinish}
            disabled={type === "view" || isFormDisabled}
            initialValues={
              Object.keys(record).length === 0
                ? {
                    department: "Primary",
                    selected_class: 1,
                  }
                : record
            }
          >
            <div className="imagewholecontainer">
              <div className="imagedivcon">
                <div className="imagedivinnercon">
                  <img
                    src={imgsrc}
                    crossOrigin="anonymous | use-credentials"
                    alt="student"
                  />
                </div>
              </div>
              {type !== "view" && (
                <div className="upload">
                  <Upload
                    maxCount={1}
                    beforeUpload={handleBeforeUpload}
                    accept="image/*"
                    onChange={handleImageUpload}
                    showUploadList={false}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      className="uploadbackground"
                    >
                      Upload Image
                    </Button>
                  </Upload>
                </div>
              )}
            </div>

            <h3 className="textformat mt-4 mb-3">Student Information</h3>

            <div className="whitebagcontainer">
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter First Name!",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item name="middle_name" label="Middle Name">
                    <Input placeholder="Middle Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Last Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="date_of_birth"
                    label="Date of Birth"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Birth!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="inputbox"
                      disabledDate={disableFutureDates} // Restrict future dates
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[
                      { required: true, message: "Please enter Gender!" },
                    ]}
                  >
                    <Select
                      placeholder="Gender"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="Male">Male</Option>
                      <Option value="FeMale">Female</Option>
                    </Select>
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    name="nationality"
                    label="Nationality"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Nationality!",
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      showSearch
                      onSearch={onSearch}
                      name="nationality"
                      placeholder="Nationality"
                      className="inputbox"
                    >
                      {nationality.map((item) => (
                        <Option key={item.code}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="date_of_admission"
                    label="Date of Admission"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Date of Admission!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="inputbox"
                      disabledDate={disableFutureDates}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="no_of_siblings"
                    label="No Of Siblings"
                    rules={[
                      {
                        required: true,
                        message: "Please enter No Of Siblings!",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      maxLength="1"
                      placeholder="No Of Siblings"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="register_no"
                    label="Register No"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Register No!",
                      },
                    ]}
                  >
                    <Input placeholder="Register No" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    label="Admission No"
                    name="admission_no"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Admission No!",
                      },
                    ]}
                  >
                    <Input placeholder="Admission No" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="gov_id"
                    label="Government ID"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Government ID!",
                      },
                    ]}
                  >
                    <Input placeholder="Government ID" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="school_location"
                    label="School Location"
                    rules={[
                      {
                        required: true,
                        message: "Please enter School Location!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="School Location"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      {schoolLocation}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    label="Class"
                    name="selected_class"
                    rules={[{ required: true, message: "Please enter Class!" }]}
                  >
                    <Select
                      placeholder="Select class"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                      onChange={handleClassChange}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <Option key={i + 1} value={i + 1}>
                          {i + 1}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="section"
                    label="Section"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Section!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select section"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      {sectionOptions}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-md-4 department_select">
                  <Form.Item
                    name="department"
                    label="Department"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Department!",
                      },
                    ]}
                  >
                    <Select
                      className="inputbox"
                      id="department_select"
                      open={false} // Disable dropdown arrow
                      // defaultValue="Primary" // Automatically update department
                      disabled
                    >
                      <Option value="Primary">Primary</Option>
                      <Option value="Secondary">Secondary</Option>
                      <Option value="Higher Secondary">Higher Secondary</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    label="Roll No"
                    name="roll_no"
                    rules={[
                      { required: true, message: "Please enter Roll No!" },
                    ]}
                  >
                    <Input placeholder="Roll No" className="inputbox" />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    label="Blood Group"
                    name="blood_group"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Blood Group!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Blood Group"
                      className="inputbox"
                      suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    >
                      <Option value="A+">A+</Option>
                      <Option value="A-">A-</Option>
                      <Option value="B+">B+</Option>
                      <Option value="B-">B-</Option>
                      <Option value="AB+">AB+</Option>
                      <Option value="AB-">AB-</Option>
                      <Option value="O+">O+</Option>
                      <Option value="O-">O-</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="religion"
                    label="Religion"
                    rules={[
                      { required: true, message: "Please enter Religion!" },
                    ]}
                  >
                    <Input placeholder="Religion" className="inputbox" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Form.Item
                    name="student_address"
                    label="Address"
                    rules={[
                      { required: true, message: "Please Enter Address!" },
                    ]}
                  >
                    <TextArea
                      placeholder="Address"
                      className="inputboxempadd"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <h3 className="textformat">Father Information</h3>

            <div id="father_container" className="whitebagcontainer">
              <div className="d-flex justify-content-end p-1">
                <Checkbox
                  id="father"
                  checked={isFatherChecked}
                  onChange={() =>
                    handleCheckboxChange("father", setIsFatherChecked)
                  }
                  style={{
                    display:
                      selectedParent && selectedParent !== "father"
                        ? "none"
                        : "inline-flex",
                  }}
                >
                  Primary Parent
                </Checkbox>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="fatherfullName"
                    label="Full name"
                    rules={
                      isFatherChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Full name!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input placeholder="Full name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="fatherAadharNo"
                    label="Father Aadhar No"
                    rules={
                      isFatherChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Father Aadhar No!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input
                      minLength="12"
                      maxLength="12"
                      placeholder="Father Aadhar No"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="fathermobileNumber"
                    label="Mobile Number"
                    rules={
                      isFatherChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Mobile Number!",
                            },
                          ]
                        : []
                    }
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* Country Code Dropdown */}
                      <Select
                        style={{ width: "30%" }}
                        value={selectedCountryFather}
                        onChange={handleCountryChange1}
                      >
                        {Object.keys(countryPhoneCodes).map((country) => (
                          <Option
                            key={country}
                            value={countryPhoneCodes[country].code}
                          >
                            {`${countryPhoneCodes[country].code} `}
                          </Option>
                        ))}
                      </Select>

                      {/* Phone Number Input */}
                      <Input
                        style={{ flex: 1, marginLeft: 8 }}
                        placeholder="Enter mobile number"
                        maxLength={
                          countryPhoneCodes[selectedCountryFather]?.maxLength ||
                          10
                        }
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="fatherEmailId"
                    label="Email ID"
                    rules={
                      isFatherChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Email ID!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input
                      pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                      placeholder="Email  ID"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="fatherOccupation"
                    label="Occupation"
                    rules={
                      isFatherChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Occupation!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input placeholder="Occupation" className="inputbox" />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  {!isAddressFather && (
                    <Form.Item
                      name="fatherAddress"
                      label="Address"
                      rules={
                        isFatherChecked
                          ? [
                              {
                                required: true,
                                message: "Please enter Address!",
                              },
                            ]
                          : []
                      }
                    >
                      <Input placeholder="Address" className="inputbox" />
                    </Form.Item>
                  )}
                </div>
              </div>

              <div className="addrescheckbox">
                <Checkbox
                  onChange={(e) => setIsAddressFather(e.target.checked)}
                  checked={isAddressFather}
                >
                  Address Same as Student
                </Checkbox>
              </div>
            </div>

            <h3 className="textformat">Mother Information</h3>

            <div id="mother_container" className="whitebagcontainer">
              <div className="d-flex justify-content-end p-1">
                <Checkbox
                  id="mother"
                  checked={isMotherChecked}
                  onChange={() =>
                    handleCheckboxChange("mother", setIsMotherChecked)
                  }
                  style={{
                    display:
                      selectedParent && selectedParent !== "mother"
                        ? "none"
                        : "inline-flex",
                  }}
                >
                  Primary Parent
                </Checkbox>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="motherfullName"
                    label="Full Name"
                    rules={
                      isMotherChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Full Name!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input placeholder="Full Name" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherAadharNo"
                    label="Mother Aadhar No"
                    rules={
                      isMotherChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Mother Aadhar No!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input
                      minLength="12"
                      maxLength="12"
                      placeholder="Mother Aadhar No"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherMobileNumber"
                    label="Mobile Number"
                    rules={
                      isMotherChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Mobile Number!",
                            },
                          ]
                        : []
                    }
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* Country Code Dropdown */}
                      <Select
                        style={{ width: "30%" }}
                        value={selectedCountryMother}
                        onChange={handleCountryChange2}
                      >
                        {Object.keys(countryPhoneCodes).map((country) => (
                          <Option
                            key={country}
                            value={countryPhoneCodes[country].code}
                          >
                            {`${countryPhoneCodes[country].code} `}
                          </Option>
                        ))}
                      </Select>

                      {/* Phone Number Input */}
                      <Input
                        style={{ flex: 1, marginLeft: 8 }}
                        placeholder="Enter mobile number"
                        maxLength={
                          countryPhoneCodes[selectedCountryMother]?.maxLength ||
                          10
                        }
                        value={phoneNumbermother}
                        onChange={(e) => setPhoneNumbermother(e.target.value)}
                      />
                    </div>
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="motherEmailId"
                    label="Email ID"
                    rules={
                      isMotherChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Email ID!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input
                      pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                      placeholder="Email Id"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    name="motherOccupation"
                    label="Occupation"
                    rules={
                      isMotherChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Occupation!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input placeholder="Occupation" className="inputbox" />
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  {!isAddressmother && (
                    <Form.Item
                      name="motherAddress"
                      label="Address"
                      rules={
                        isMotherChecked
                          ? [
                              {
                                required: true,
                                message: "Please enter Address!",
                              },
                            ]
                          : []
                      }
                    >
                      <Input placeholder="Address" className="inputbox" />
                    </Form.Item>
                  )}
                </div>
              </div>

              <div className="addrescheckbox">
                <Checkbox
                  onChange={(e) => setIsAddressmother(e.target.checked)}
                  checked={isAddressmother}
                >
                  Address Same as Student
                </Checkbox>
              </div>
            </div>

            <h3 className="textformat">Guardian Information</h3>

            <div id="guardian_container" className="whitebagcontainer">
              <div className="d-flex justify-content-end p-1">
                <Checkbox
                  id="guardian"
                  checked={isGuardianChecked}
                  onChange={() =>
                    handleCheckboxChange("guardian", setIsGuardianChecked)
                  }
                  style={{
                    display:
                      selectedParent && selectedParent !== "guardian"
                        ? "none"
                        : "inline-flex",
                  }}
                >
                  Primary Parent
                </Checkbox>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="guardianFullName"
                    label="Full Name"
                    rules={
                      isGuardianChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Full Name!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input placeholder="Full Name" className="inputbox" />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    name="guardianAadharNo"
                    label="Guardian Aadhar No"
                    rules={
                      isGuardianChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Aadhar No!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input
                      minLength="12"
                      maxLength="12"
                      placeholder="Aadhar No"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    name="guardianMobileNumber"
                    label="Mobile Number"
                    rules={
                      isGuardianChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Mobile Number!",
                            },
                          ]
                        : []
                    }
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Select
                        style={{ width: "30%" }}
                        value={selectedCountryGuardian}
                        onChange={handleCountryChange3}
                      >
                        {Object.keys(countryPhoneCodes).map((country) => (
                          <Option
                            key={country}
                            value={countryPhoneCodes[country].code}
                          >
                            {`${countryPhoneCodes[country].code} `}
                          </Option>
                        ))}
                      </Select>
                      <Input
                        style={{ flex: 1, marginLeft: 8 }}
                        placeholder="Enter mobile number"
                        maxLength={
                          countryPhoneCodes[selectedCountryGuardian]
                            ?.maxLength || 10
                        }
                        value={phoneNumberguardian}
                        onChange={(e) => setPhoneNumberguardian(e.target.value)}
                      />
                    </div>
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    name="guardianEmailId"
                    label="Email ID"
                    rules={
                      isGuardianChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Email ID!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input
                      pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                      placeholder="Email ID"
                      className="inputbox"
                    />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  <Form.Item
                    name="guardianOccupation"
                    label="Occupation"
                    rules={
                      isGuardianChecked
                        ? [
                            {
                              required: true,
                              message: "Please enter Occupation!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input placeholder="Occupation" className="inputbox" />
                  </Form.Item>
                </div>

                <div className="col-md-4">
                  {!isAddressguardian && (
                    <Form.Item
                      name="guardianAddress"
                      label="Address"
                      rules={
                        isGuardianChecked
                          ? [
                              {
                                required: true,
                                message: "Please enter Address!",
                              },
                            ]
                          : []
                      }
                    >
                      <Input placeholder="Address" className="inputbox" />
                    </Form.Item>
                  )}
                </div>
              </div>

              <div className="addrescheckbox">
                <Checkbox
                  onChange={(e) => setIsAddressguardian(e.target.checked)}
                >
                  Address Same as Student
                </Checkbox>
              </div>
            </div>
            <div className="available-checkbox pt-4">
              <Checkbox
                onChange={(e) => setIsAvailableInChecked(e.target.checked)}
              >
                Available In
              </Checkbox>
            </div>
            {isAvailableInChecked && (
              <div className="whitebagcontainer">
                {/* New Checkbox to Show Student Address Form */}

                {/* Show student address form if checkbox is checked */}

                <div className="row">
                  <div className="d-flex justify-content-end">
                    <Checkbox
                    // onChange={(e) => setIsAddressguardian(e.target.checked)}
                    >
                      Address Same as Student
                    </Checkbox>
                  </div>
                  <div className="col-md-12">
                    <Form.Item
                      name="student_address"
                      label="Address"
                      rules={[
                        { required: true, message: "Please Enter Address!" },
                      ]}
                    >
                      <TextArea
                        placeholder="Address"
                        className="inputboxempadd"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}

            {error && (
              <p style={{ color: "red" }}>
                Please select at least one Primary Parent.
              </p>
            )}

            <div className="form-buttons">
              <Form.Item>
                {type !== "view" && (
                  <Button
                    type="primary"
                    className="modifybutton"
                    htmlType="submit"
                    style={{ marginRight: "10px", width: "90px" }}
                  >
                    {type === "edit" ? "Update" : "Add"}
                  </Button>
                )}
                <Button
                  htmlType="button"
                  onClick={onClose}
                  disabled={localFormDisabled}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default AddStudentForm;
