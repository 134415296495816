import React, { useEffect, useRef, useState } from "react";
import {
  Upload,
  Button,
  Form,
  message,
  Spin,
  DatePicker,
  Input,
  Select,
  Space,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import avatar from "../../Assets/png/avatar.jpg";
import { fetchApi } from "../../helper";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getvehicles } from "../../store/settings/vehicle";
import {
  addDriver,
  getDriverDetails,
  resetDriverErrorAndMessage,
  updateDriver,
} from "../../store/driverDetails/driverDetails";

const { Option } = Select;

const countryPhoneCodes = {
  "Select Country": { code: "+1", maxLength: 10 }, // Default
  Afghanistan: { code: "+93", maxLength: 9 },
  Albania: { code: "+355", maxLength: 8 },
  Algeria: { code: "+213", maxLength: 9 },
  Andorra: { code: "+376", maxLength: 6 },
  Argentina: { code: "+54", maxLength: 10 },
  Australia: { code: "+61", maxLength: 9 },
  Bangladesh: { code: "+880", maxLength: 10 },
  Belgium: { code: "+32", maxLength: 9 },
  Brazil: { code: "+55", maxLength: 11 },
  Canada: { code: "+1", maxLength: 10 },
  China: { code: "+86", maxLength: 11 },
  Egypt: { code: "+20", maxLength: 10 },
  France: { code: "+33", maxLength: 9 },
  Germany: { code: "+49", maxLength: 11 },
  India: { code: "+91", maxLength: 10 },
  Indonesia: { code: "+62", maxLength: 10 },
  Italy: { code: "+39", maxLength: 10 },
  Japan: { code: "+81", maxLength: 10 },
  Kenya: { code: "+254", maxLength: 9 },
  Malaysia: { code: "+60", maxLength: 10 },
  Mexico: { code: "+52", maxLength: 10 },
  Nepal: { code: "+977", maxLength: 10 },
  Nigeria: { code: "+234", maxLength: 10 },
  Pakistan: { code: "+92", maxLength: 10 },
  Philippines: { code: "+63", maxLength: 10 },
  Russia: { code: "+7", maxLength: 10 },
  Saudi: { code: "+966", maxLength: 9 },
  SouthAfrica: { code: "+27", maxLength: 9 },
  Spain: { code: "+34", maxLength: 9 },
  UK: { code: "+44", maxLength: 10 },
  USA: { code: "+1", maxLength: 10 },
};

function Driveropr({ record, onClose, type, selectedId }) {
  // console.log(selectedId, type, record);
  const dispatch = useDispatch();
  const driver = useSelector((state) => state.driver);
  const vehicle = useSelector((state) => state.vehicle);

  const formRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(avatar);
  const [imgFile, setImgFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [vehicleDetails, setVechileDetails] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("+91");

  useEffect(() => {
    if (Object.keys(record).length > 0) {
      setImageSrc(record.image);
      setLoader(false);
    }
  }, [record]);

  useEffect(() => {
    if (vehicle?.data) {
      setVehicleList();
    } else {
      dispatch(getvehicles());
    }
  }, [dispatch, vehicle?.data]);

  useEffect(() => {
    if (driver.message && !driver.isLoading && !driver.isError) {
      message.success(driver.message);
      dispatch(getDriverDetails({ _start: 0, _limit: 10 }));
      onClose();
    }

    dispatch(resetDriverErrorAndMessage());
    setIsFormDisabled(false);
    setLoader(false);
  }, [driver.message, driver.error]);

  const setVehicleList = async () => {
    const vechileDetailsArr = vehicle?.data?.data.map((veh) => (
      <Option key={veh.vehicle_id} value={veh.vehiclenumber}>
        {veh.vehiclenumber}
      </Option>
    ));

    setVechileDetails(vechileDetailsArr);
  };

  const handleImageUpload = ({ fileList }) => {
    if (fileList.length > 0) {
      const selectedFile = fileList[0].originFileObj;
      setImgFile(selectedFile);
      // Read image using FileReader
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageSrc(reader.result); // Set preview URL
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setImageSrc(null);
      setImgFile(null);
    }
  };

  const handleBeforeUpload = (file) => {
    const MAX_FILE_SIZE = 500 * 1024; // 500 KB in bytes
    if (file.size > MAX_FILE_SIZE) {
      message.error("File size exceeds 500 KB. Please upload a smaller file.");
      return Upload.LIST_IGNORE; // Prevent upload
    }
    return false;
  };

  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf("day");
  };

  // handle image upload error
  const uploadImage = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
    const formData = new FormData();

    formData.append("file", imgFile);

    const payload = {
      method: "post",
      url: "/upload",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    return await fetchApi(payload)
      .then((response) => {
        if (response.status === 201) {
          return response.data;
        } else if (response.status === 400) {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formatDataForReq = async (inputValues) => {
    const driverData = {};

    if (Object.keys(record).length === 0) {
      driverData.status = "Active";
    } else {
      driverData.status = record.status;
    }

    driverData.full_name = inputValues.full_name;
    driverData.dob = inputValues.dob;
    driverData.email_id = inputValues.email_id;
    driverData.address = inputValues.address;
    driverData.assignedvehicle = inputValues.assignedvehicle;
    driverData.doj = inputValues.doj;
    driverData.licenseno = inputValues.licenseno;
    driverData.Aadhar_number = inputValues.aadhar_number;
    driverData.assigned_route = inputValues.assigned_route;
    driverData.staffmobilenumber = `${selectedCountry}${inputValues.staffmobilenumber}`;
    driverData.transportation_staff = inputValues.transportation_staff;

    if (type === "add") {
      driverData.image = await uploadImage();
    } else if (type === "edit" && imgFile) {
      driverData.image = await uploadImage();
    } else if (type === "edit") {
      driverData.image = imageSrc;
    }

    return driverData;
  };

  const onFinish = async (values) => {
    if (type === "add" && !imgFile) {
      message.error("Please upload a picture file.");
      return;
    }

    setLoader(true);
    setIsFormDisabled(true);

    const data = await formatDataForReq(values);

    if (type === "add") dispatch(addDriver(data));
    else dispatch(updateDriver({ data, selectedId }));
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  return (
    <>
      <div className="Accodamicwholeopr">
        {/* <h2>Add Driver</h2> */}
        <Spin spinning={loader}>
          <Form
            layout="vertical"
            style={{ borderRadius: "7px" }}
            ref={formRef}
            onFinish={onFinish}
            disabled={type === "view" || isFormDisabled}
            initialValues={record || {}}
          >
            <div className="imagewholecontainer">
              <div className="imagedivcon">
                <div className="imagedivinnercon">
                  <img
                    src={imageSrc}
                    crossOrigin="anonymous | use-credentials"
                    alt="driver pic"
                  />
                </div>
              </div>
              {type !== "view" && (
                <div className="upload">
                  <Upload
                    maxCount={1}
                    beforeUpload={handleBeforeUpload}
                    accept="image/*"
                    onChange={handleImageUpload}
                    showUploadList={false}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      className="uploadbackground"
                    >
                      Upload Image
                    </Button>
                  </Upload>
                </div>
              )}
            </div>
            {/* <div className="imagedivcon">
              <div className="imagedivinnercon">
                <div>
                  <img
                    src={"https://i.pravatar.cc/40?img=12"}
                    alt="ii"
                    style={{ width: 212 }}
                  />
                  
                </div>
              </div>
            </div> */}
            <h3 className="textformat">Driver Information</h3>

            <div className="Driverform">
              <div className="driverrowform">
                <div className="row">
                  <div className="col-md-4">
                    <Form.Item
                      name="full_name"
                      label="FullName"
                      style={{ marginTop: "20px" }}
                      rules={[
                        { required: true, message: "Please Enter FullName!" },
                      ]}
                    >
                      <Input placeholder="FullName" className="inputcon" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="dob"
                      label="Date Of Birth"
                      style={{ marginTop: "20px" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter Date of Birth!",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        className="inputbox"
                        disabledDate={disableFutureDates} // Restrict future dates
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="email_id"
                      label="Email ID"
                      style={{ marginTop: "20px" }}
                      rules={[
                        { required: true, message: "Please Enter Email ID!" },
                      ]}
                    >
                      <Input
                        pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                        placeholder="Email Id"
                        className="inputcon"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="address"
                      label="Address"
                      rules={[
                        { required: true, message: "Please Enter Address!" },
                      ]}
                    >
                      <Input placeholder="Address" className="inputcon" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="assignedvehicle"
                      label="Assigend Vehicle"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Assigend Vechcle!",
                        },
                      ]}
                    >
                      <Select
                        suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                        placeholder="Select Assigend Vechcle"
                        className="inputbox"
                        style={{ width: "100%" }}
                      >
                        {vehicleDetails}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      name="doj"
                      label="Date of Joining"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Date of Joining!",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="Date of Joining"
                        className="inputbox"
                        disabledDate={disableFutureDates} // Restrict future dates
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3 className="textformat">Other Information</h3>
              <div className="Driverform">
                <div className="driverrowform">
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Item
                        name="licenseno"
                        label="License Number "
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter License Number !",
                          },
                        ]}
                      >
                        <Input
                          maxLength="16"
                          placeholder="License Number"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="aadhar_number"
                        label="Aadhar Number"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Aadhar Number!",
                          },
                        ]}
                      >
                        <Input
                          minLength="12"
                          maxLength="12"
                          placeholder="Aadhar Number"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="assigned_route"
                        label=" Assigned Route"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Assigned Route!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Assigned Route"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-4" style={{ marginTop: "20px" }}>
                      <Form.Item
                        label="Staff Mobile Number"
                        name="staffmobilenumber"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Staff Mobile Number!",
                          },
                        ]}
                      >
                        <Space.Compact>
                          {/* Country Code Dropdown */}
                          <Select
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            style={{ width: "30%" }}
                          >
                            {Object.keys(countryPhoneCodes).map((country) => (
                              <Option key={country} value={country}>
                                {countryPhoneCodes[country].code}
                              </Option>
                            ))}
                          </Select>

                          {/* Mobile Number Input */}
                          <Input
                            placeholder="Enter Mobile Number"
                            style={{ width: "70%" }}
                            maxLength={
                              // countryPhoneCodes[selectedCountry]?.maxLength ||
                              10
                            }
                          />
                        </Space.Compact>
                      </Form.Item>
                    </div>
                    <div className="col-md-4">
                      <Form.Item
                        name="transportation_staff"
                        label="Transportation Staff"
                        style={{ marginTop: "20px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Transportation Staff!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Transportation Staff"
                          className="inputcon"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-buttons">
              {type !== "view" && (
                <Button
                  type="primary"
                  className="modifybutton"
                  htmlType="submit"
                  style={{ marginRight: "10px", width: "90px" }}
                >
                  {type === "edit" ? "Update" : "Add"}
                </Button>
              )}
              <Button disabled={isFormDisabled} onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </>
  );
}

export default Driveropr;
