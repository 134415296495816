import React, { useState } from "react";
import {
  Table,
  Avatar,
  Tag,
  // Typography,
  Select,
  Space,
  Layout,
  Card,
  Typography,
  Button,
} from "antd";
import TableSortArrows from "../../Component/tableSortArrows.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Table } from "antd";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ViewEnterMark from "../../Component/Grades/ViewEnterMark.jsx";
// import { customPagination } from "../../helper";
import Pagination from "../../Component/pagination/index.jsx";

const cardData = {
  AnnualLeave: "3/12",
  MedicalLeave: "2/6",
  CasualLeave: "1/6",
};

const data = [
  {
    key: "1",
    name: "John Doe",
    Class: "10-B",
    Rollno: "01",
    Admission: "Emp001",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: "2",
    name: "Jane Smith",
    Class: "11-A",
    Rollno: "02",
    Admission: "Emp002",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: "3",
    name: "Alice Johnson",
    Class: "12-A",
    Rollno: "03",
    Admission: "Emp003",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: "4",
    name: "Alice Johnson",
    Class: "12-A",
    Rollno: "04",
    Admission: "Emp004",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: "5",
    name: "Alice Johnson",
    Class: "12-A",
    Rollno: "05",
    Admission: "Emp005",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: "6",
    name: "Alice Johnson",
    Class: "12-A",
    Rollno: "06",
    Admission: "Emp006",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: "7",
    name: "Alice Johnson",
    Class: "12-A",
    Rollno: "07",
    Admission: "Emp007",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
  {
    key: "8",
    name: "Alice Johnson",
    Class: "12-A",
    Rollno: "08",
    Admission: "Emp008",
    avatarUrl: "https://i.pravatar.cc/40?img=3",
  },
];

const Classs = ({ setIsOpen, isOpen, settabVisible }) => {
  const [userArray, setUserArray] = useState(data);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [teb, setTeb] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  const openOnChange = (value, teb) => {
    // settabVisible(false);
    if (teb === "view") {
      setModuleOpen(true);
      setSelectedDate(value);
    } else {
      setModuleOpen(false);
      setSelectedDate({});
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space>
          {record.avatarUrl ? (
            <Avatar src={record.avatarUrl} />
          ) : (
            <Avatar size={40} style={{ backgroundColor: record.color }}>
              {record.logo}
            </Avatar>
          )}
          {text}
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    { title: "Admission", dataIndex: "Admission", key: "Admission" },
    { title: "Class", dataIndex: "Class", key: "Class" },
    { title: "Roll No", dataIndex: "Rollno", key: "Rollno" },
    {
      title: "Action",
      dataIndex: "status",
      render: (_, record) => (
        <div>
          <button
            // icon={faEye}
            size="lg"
            style={{
              color: "#fff",
              backgroundColor: "#00ADC4",
              border: "none",
              padding: "1px 10px",
            }}
            onClick={() => openOnChange(record, "view")}
          >
            Enter Marks
          </button>
        </div>
      ),
    },
  ];
  console.log(isOpen, "isopen");

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const objectLength = Object.keys(userArray).length;

  return (
    <div>
      <>
        {!moduleOpen ? (
          <div className="studenttable">
            {/* <button onClick={() => setIsOpen(!isOpen)}>Back1</button> */}
            {/* <h4>Student Table</h4> */}

            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
            />
            <Pagination
              data={userArray}
              onPageChange={(data) => {
                setTableData(data);
              }}
              initialItemsPerPage={10}
            />
          </div>
        ) : (
          <ViewEnterMark
            data={selectedDate}
            isOpen={moduleOpen}
            setIsOpen={setModuleOpen}
            // settabVisible={settabVisible}
            pagination={false}
          />
        )}
      </>
    </div>
  );
};
export default Classs;
