import React, { useState } from "react";
import { Spin, Table, Tag } from "antd";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accdamicopr from "../../Component/Acadamicemployeecev/Acadamicopr";
import Pagination from "../pagination/index";
import { TEACHER } from "../../constants/roles";
import moment from "moment";

function Teacher({
  setIsOpen,
  isOpen,
  settabVisible,
  loader,
  alldatas,
  teacherFullData,
}) {
  const [userArray, setUserArray] = useState(alldatas || []);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [type, setType] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [tableData, setTableData] = useState(alldatas || []);

  const openOnChange = (value, type) => {
    if (type === "view") {
      const curTeacher = teacherFullData.find((teacher) => {
        if (teacher.id === value.key) {
          teacher.dob = moment(teacher.dob);
          teacher.joiningat = moment(teacher.joiningat);
          return teacher;
        }
      });

      setType(type);
      setModuleOpen(true);
      setSelectedDate(curTeacher || []);
      settabVisible(false);
    } else {
      setModuleOpen(false);
      setSelectedDate({});
    }
  };

  const onClose = () => {
    setselectedId("");
    setModuleOpen(false);
    settabVisible(true);

    setSelectedDate({});
  };

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    {
      title: TEACHER,
      dataIndex: "teachername",
      align: "center",
    },
    {
      title: "Teacher ID",
      dataIndex: "employeeID",
      align: "center",
    },
    {
      title: "Assigned class",
      dataIndex: "classInfo",
      align: "center",
    },
    {
      title: "Subject",
      dataIndex: "tags",
      align: "center",
      render: (tags) => (
        <>
          {Array.isArray(tags) && tags.length > 0 ? (
            tags.map((tag) => {
              let color = tag.length > 5 ? "geekblue" : "green";
              if (tag === "loser") {
                color = "volcano";
              }
              return (
                <Tag
                  key={tag}
                  style={{
                    border: "1px dashed #00ADC4",
                    background: "#fff",
                    display: "inline-block",
                    padding: "5px 10px",
                    margin: "5px",
                  }}
                >
                  {tag.toUpperCase()}
                </Tag>
              );
            })
          ) : (
            <span>No tags available</span>
          )}
        </>
      ),
    },

    {
      title: "Action",
      dataIndex: "status",
      align: "center",

      render: (_, record) => (
        <div>
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
        </div>
      ),
    },
  ];

  console.log(moduleOpen, "moduleOpen");

  return (
    <div>
      {!moduleOpen ? (
        <div className="tablecontainer">
          <Spin spinning={loader}>
            <Table
              columns={columns}
              dataSource={tableData}
              tableLayout="fixed"
              className=""
              pagination={false}
            />
          </Spin>
          <Pagination
            data={userArray}
            onPageChange={(data) => {
              setTableData(data);
            }}
            initialItemsPerPage={10}
          />
        </div>
      ) : (
        <Accdamicopr
          data={selectedDate}
          isOpen={moduleOpen}
          type={type}
          setIsOpen={setModuleOpen}
          onClose={onClose}
          record={selectedDate}
          selectedId={selectedId}
          settabVisible={settabVisible}
        />
      )}
    </div>
  );
}

export default Teacher;
