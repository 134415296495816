import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi } from "../../helper";

export const getEventDetails = createAsyncThunk(
  "events/getEventDetails",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    let payload = {
      method: "get",
      url: `/event`,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

export const createNewEvent = createAsyncThunk(
  "events/createNewEvent",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    let payload = {
      method: "post",
      url: `/event`,
      data,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

const incorpSlice = createSlice({
  name: "events",
  initialState: {
    status: "idle",
    isLoading: false,
    data: null,
    isError: false,
    error: null,
    message: null,
  },
  reducers: {
    resetEventErrorAndMessage: (state) => {
      state.error = null;
      state.isError = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    // get events
    builder.addCase(getEventDetails.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
    });

    builder.addCase(getEventDetails.fulfilled, (state, action) => {
      // console.log(action.payload, "fullfilled");
      state.status = "success";
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload.data;
    });

    builder.addCase(getEventDetails.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });

    // add events
    builder.addCase(createNewEvent.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
      state.message = null;
    });

    builder.addCase(createNewEvent.fulfilled, (state, action) => {
      // console.log(action.payload, "fullfilled");
      state.status = "success";
      state.isLoading = false;
      // console.log(action.payload);
      switch (action.payload.status) {
        case 201:
          state.message = action.payload.message;
          state.isError = false;
          break;
        case 208:
          state.error = action.payload.message;
          state.isError = true;
          break;
        default:
          state.error = "Failed to process request!";
          state.isError = true;
      }
    });

    builder.addCase(createNewEvent.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });
  },
});

export const { resetEventErrorAndMessage } = incorpSlice.actions;
export default incorpSlice.reducer;
