import React from "react";
import { Card } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
// import "bootstrap/dist/css/bootstrap.min.css";

const data = [
  { name: "CT-1", obtained: 45, total: 100 },
  { name: "CT-2", obtained: 60, total: 100 },
  { name: "CT-3", obtained: 40, total: 100 },
  { name: "Model", obtained: 65, total: 100 },
  { name: "Quarterly", obtained: 80, total: 100 },
];

const ExamPerformance = () => {
  return (
    <div className="containers">
      <div className="d-md-flex align-items-center justify-content-between">
        <h5 className="fw-bold">Exam Performance</h5>
        <span className="text-muted">Term - 1</span>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          barSize={40}
          margin={{ top: 20, right: 30, left: 0, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="obtained" stackId="a" fill="#0096c7" />
          <Bar dataKey="total" stackId="a" fill="#90e0ef" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ExamPerformance;
