import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  notification,
  Button,
  Typography,
  Tag,
} from "antd";
import CourseSection from "../../Component/Departmentadmin/courseSection.jsx";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { UserOutlined, CalendarOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import courseheader from "../../Assets/png/courseheader.png";
// import "./Course.css";
import { P } from "@antv/g2plot";
import Viewcourse from "./Viewcourse";

const { Title, Paragraph } = Typography;

const Coursemanagement = () => {
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [view, setView] = useState(false);
  const [status, setStatus] = useState("");

  const courses = [
    {
      id: 1,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 2,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },
    {
      id: 3,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 4,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 5,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 6,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 7,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2025",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },
    {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        " pending Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },
    {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },
    {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },
    {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },

    // Add more courses here...
  ];
  const openOnChange = (value, teb) => {
    if (teb === "view-yet") {
      setStatus("Pending");
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb === "view") {
      setStatus("Approved");
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    }
  };

  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);
    setSelectedDate({});
  };

  return (
    <div>
      {!editModuleOpen ? (
        <div>
          <div
            style={{ padding: "20px", width: "100%", fontFamily: "poppins" }}
          >
            {/* Filter Section */}
            <div className="class-search-sec">
              <div className="row">
                <div className="col-md-3">
                  <p>Department Head</p>
                  <Input
                    placeholder="Department Head"
                    style={{ width: "100%", background: "#D9D9D9" }}
                  />
                </div>
                <div className="col-md-3">
                  <p>Department </p>
                  <Input
                    placeholder="Department"
                    style={{ width: "100%", background: "#D9D9D9" }}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-3 mt-3">
                <div className="search-id">
                  <Input
                    placeholder="Search by Course Title "
                    prefix={<SearchOutlined />}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            {/* Approved Courses Section */}
            <CourseSection />
          </div>
        </div>
      ) : (
        <div>
          <Viewcourse status={status} onClose={onClose} />
        </div>
      )}
    </div>
  );
};

export default Coursemanagement;
