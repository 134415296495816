import React, { useState, useEffect } from "react";
import { getEmployee } from "../store/employeeDetails/employeeDetailsSlice";
import { useSelector, useDispatch } from "react-redux";
import { breadcrum } from "../action/Subbreadcrumaction";
import {
  Modal,
  Table,
  Input,
  Avatar,
  Select,
  Space,
  Tag,
  Spin,
  Popover,
  message,
  Pagination,
} from "antd";
// import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import Academicopr from "../Component/Acadamicemployeecev/Acadamicopr";
import TableSortArrows from "../Component/tableSortArrows";
import EmptyDataPage from "../Component/EmptyDataPage";
import { fetchApi, getRandomColor, customPagination } from "../helper";
import { TEACHER } from "../constants/roles";
import moment from "moment";

const { confirm } = Modal;
// const { Option } = Select;

function Acadamicemployee({ isEdit }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);

  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [empFullData, setEmpFullData] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [currentPage, setCurrentPage] = useState(1);
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [tab, setTab] = useState("");
  const [userArray, setUserArray] = useState([]);
  const [totalcount, setTotalcount] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [openUploaddataListing, setOpenUploaddataListing] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalLoader, setModalLoader] = useState(false);

  useEffect(() => {
    dispatch(getEmployee(queryParams));
  }, [queryParams, dispatch]);

  useEffect(() => {
    if (employee?.data) {
      getEmployeeList();
    }
  }, [employee?.data]);

  useEffect(() => {
    if (employee.isError) {
      message.error(employee.error);
    }
  }, [employee.isError]);

  useEffect(() => {
    if (searchQuery.length === 0) searchOnChange(searchQuery);
    if (searchQuery.length < 3) return;

    const delaySearch = setTimeout(() => {
      searchOnChange(searchQuery);
    }, 100);

    return () => clearTimeout(delaySearch);
  }, [searchQuery]);

  const getEmployeeList = async () => {
    const employeData = employee?.data?.data.map((emp) => {
      let name = `${emp.firstname}`;

      if (emp.lastname) {
        name += `  ${emp.lastname}`;
      }

      return {
        key: emp.id,
        name,
        employeeID: emp.empid,
        role: TEACHER,
        "Grade&sec": `Test`,
        phoneNumber: emp.mobilenumber,
        status: emp.status,
        avatarUrl: emp.image,
      };
    });

    await asyncForEach(employeData, async (oneDoc) => {
      if (!oneDoc.avatarUrl) {
        let color = getRandomColor();
        let logo = oneDoc?.name[0].toUpperCase();
        oneDoc["color"] = color;
        oneDoc["logo"] = logo;
      }
    });

    setEmpFullData(employee?.data?.data);
    setUserArray(employeData);
    setCount(employee?.data?.count);
    setTotalcount(employee?.data?.totalCount);
    setIsLoading(false);
  };

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const openOnChange = (value, tab) => {
    if (tab === "edit" || tab === "view") {
      let curEmpData = empFullData.find((emp) => emp.id === value.key);

      curEmpData = JSON.parse(JSON.stringify(curEmpData));
      curEmpData.dob = moment(curEmpData.dob);
      curEmpData.joiningat = moment(curEmpData.joiningat);

      setSelectedData(curEmpData);
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTab(tab);
    } else if (tab === "add") {
      seteditModuleOpen(true);
      setTab(tab);
      setSelectedData({});
    } else {
      confirmDelete([]);
    }
  };

  const confirmDelete = (record) => {
    const isActive = record.status === "Active";
    const status = record.status === "Active" ? "Inactive" : "Active";

    confirm({
      title: isActive ? "Confirm to make Inactive?" : "Confirm to make Active?",
      content: isActive
        ? "Are you sure you want to make this record inactive?"
        : "Are you sure you want to make this record active?",

      className: "custom-confirm",
      centered: true,
      okText: "Confirm",
      async onOk() {
        await updateEmpStatus(record.key, status);
      },
      onCancel() {},
      okButtonProps: {
        className: "custom-save-button",
      },
      cancelButtonProps: {
        className: "custom-cancel-button",
      },
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Unselect all
      setSelectedRows([]);
      setSelectedRowsData([]);
      setOpen(false);
    } else {
      // Select all
      const allKeys = userArray.map((item) => item.key);
      setSelectedRows(allKeys);
      setSelectedRowsData(userArray);
      setOpen(true); // Show modal with all rows
    }
    setSelectAll(!selectAll);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const updateEmpStatus = async (empId, status) => {
    const payload = {
      method: "patch",
      url: `/employee/${empId}`,
      data: {
        status,
      },
    };

    await fetchApi(payload)
      .then((response) => {
        if (response?.status === 200) {
          message.success("Update successfully!");

          dispatch(getEmployee(queryParams));
        } else {
          message.error("Error updating employee status");
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("Error updating employee status");
      });
  };

  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedData({});
  };

  const columns = [
    ...(isEdit
      ? [
          {
            title: (
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            ),
            dataIndex: "select",
            render: (_, record) => (
              <input
                type="checkbox"
                checked={selectedRows.includes(record.key)}
                onChange={() => handleCheckboxChange(record)}
              />
            ),
          },
        ]
      : []),
    {
      title: "Teacher Name",
      dataIndex: "name",
      width: 350,
      render: (text, record) => (
        <Space>
          {record.avatarUrl ? (
            <Avatar
              src={record.avatarUrl}
              crossOrigin="anonymous | use-credentials"
            />
          ) : (
            <Avatar
              style={{ backgroundColor: record.color }}
              crossOrigin="anonymous | use-credentials"
            >
              {record.logo}
            </Avatar>
          )}
          {text}
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Employee ID",
      dataIndex: "employeeID",

      sorter: (a, b) => a.employeeID.localeCompare(b.employeeID),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Role",
      dataIndex: "role",

      sorter: (a, b) => a.role.localeCompare(b.role),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },

    {
      title: "Status",
      dataIndex: "status",

      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "#EC7C74"}`,
            color: status === "Active" ? "#fff" : "#fff",
            background:
              status === "Active"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  isEdit &&
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange(record, "edit");
              setbreadcrum("Edit Employee Details");
            }}
          />
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange(record, "view");
              setbreadcrum("View Employee Details");
            }}
          />
          <FontAwesomeIcon
            icon={record.status === "Active" ? faTrash : faAdd}
            size="lg"
            style={{
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              confirmDelete(record);
            }}
          />
        </div>
      ),
    });

  const handleCheckboxChange = (record) => {
    let updatedSelectedRows;
    let updatedSelectedData;

    if (selectedRows.includes(record.key)) {
      // Remove row if unchecked
      updatedSelectedRows = selectedRows.filter((key) => key !== record.key);
      updatedSelectedData = selectedRowsData.filter(
        (item) => item.key !== record.key
      );
    } else {
      // Add row if checked
      updatedSelectedRows = [...selectedRows, record.key];
      updatedSelectedData = [...selectedRowsData, record];
    }

    setSelectedRows(updatedSelectedRows);
    setSelectedRowsData(updatedSelectedData);
    setSelectAll(updatedSelectedRows.length === userArray.length);

    // Open modal when at least one row is selected
    setOpen(updatedSelectedRows.length > 0);
  };

  const searchOnChange = (search) => {
    setIsLoading(true);

    let param = { _start: 0, _limit: 10 };
    param.search = search;

    dispatch(getEmployee(param));
    setCurrentPage(1);
  };

  const renderOptions = () => (
    <div className="modelpopover">
      <button
        onClick={() => {
          openOnChange(null, "add");
          dispatch(breadcrum("Add Employee"));
        }}
        className="manualAdd"
      >
        Add Teacher
      </button>
      <button
        onClick={() => setOpenUploaddataListing(true)}
        className="bulkAdd"
      >
        Bulk Upload
      </button>
    </div>
  );

  const handleOk = async () => {
    try {
      if (excelData.length) {
        setModalLoader(true);

        const payload = {
          method: "post",
          url: "/employee/bulk",
          data: { employees: excelData },
        };

        await fetchApi(payload)
          .then((response) => {
            switch (response?.status) {
              case 201:
                message.success("Employees added successfully!");
                // console.log(response, "response");
                // const newEmpData = response?.data.map((emp) => {
                //   let name = `${emp.firstname}`;

                //   if (emp.lastname) {
                //     name += `  ${emp.lastname}`;
                //   }

                //   return {
                //     key: emp.id,
                //     name,
                //     employeeID: emp.empid,
                //     role: emp.role,
                //     "Grade&sec": `Test`,
                //     phoneNumber: emp.mobilenumber,
                //     status: emp.status,
                //     avatarUrl: emp.image,
                //   };
                // });

                // setUserArray((empData) => {
                //   return [newEmpData, ...empData];
                // });

                setOpenUploaddataListing(false);
                setExcelData([]);

                break;
              case 208:
                message.error("Some employee already exist");
                break;
              default:
                message.error("Failed to process request!");
            }
          })
          .catch((err) => {
            console.log(err);
            message.error("Error adding employees!");
          })
          .finally(() => {
            setModalLoader(false);
          });
      } else {
        message.error("No data");
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  const handleCancel = () => {
    setExcelData([]);

    setOpenUploaddataListing(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        if (jsonData.length) {
          setExcelData(jsonData);
        } else {
          message.error(
            "Invalid file format. Please upload a valid .xlsx file."
          );
        }
      } catch (err) {
        console.error("Error processing file:", err);
        message.error("Error processing file. Please try again.");
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const xlsxColumns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    {
      title: "Teacher Name",
      dataIndex: "name",
    },

    {
      title: "Employee ID",
      dataIndex: "employeeid",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];

  const setbreadcrum = (arg) => {
    dispatch(breadcrum(arg));
  };

  const pageSizeChange = async (page, pageSize) => {
    setIsLoading(true);

    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    dispatch(getEmployee(ctr));
    setCurrentPage(page);
    setQueryParams(ctr);
  };

  return (
    <>
      {!editModuleOpen ? (
        <div>
          <div className="tablecontainer">
            <div className="tableinnercontainer">
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                <div className="search-id">
                  <Input
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                    placeholder="Search by Employeeerbv Name or Id"
                    prefix={<SearchOutlined />}
                    className="seachAccadamicemployee"
                  />
                </div>
                <div className="d-lg-flex align-items-center">
                  <div className="d-flex justify-content-center px-3">
                    <div className="btnacccon">
                      {!isEdit ? (
                        <Popover placement="bottom" content={renderOptions()}>
                          <div className="addbtn">
                            <FontAwesomeIcon
                              icon={faPlus}
                              size="lg"
                              className="icon"
                              style={{ fontSize: "22px" }}
                            />
                            <p
                              style={{
                                margin: "0px",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Add
                            </p>
                          </div>
                        </Popover>
                      ) : null}

                      {/* <div>
                        <Select
                          allowClear
                          className="class-select-btn"
                          placeholder="Bulk Select"
                          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                          style={{ width: "150px" }}
                          dropdownStyle={{ width: "150px" }}
                          onChange={(value) =>
                            console.log("Bulk action selected:", value)
                          }
                        >
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                        </Select>
                      </div> */}
                      <div className="pagination_align">
                        <div className=" d-md-flex d-none">
                          <Pagination
                            total={totalcount}
                            defaultCurrent={1}
                            current={currentPage}
                            defaultPageSize={10}
                            pageSize={queryParams._limit}
                            onChange={pageSizeChange}
                            itemRender={customPagination}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Spin spinning={isLoading}>
              <Table
                rowKey="key"
                columns={columns}
                dataSource={userArray}
                tableLayout="fixed"
                className="table-datas"
                pagination={false}
              />
            </Spin>
          </div>
          <div className="pagination_align">
            <div className="d-md-flex d-none">
              <span>{`Showing ${
                queryParams._start + 1
              } - ${count} of ${totalcount}`}</span>
            </div>
            <div>
              <Pagination
                total={totalcount}
                defaultCurrent={1}
                current={currentPage}
                defaultPageSize={10}
                pageSize={queryParams._limit}
                pageSizeOptions={["5", "10", "25", "50"]}
                onChange={pageSizeChange}
                itemRender={customPagination}
                showSizeChanger={true}
              />
            </div>
          </div>
        </div>
      ) : (
        <Academicopr
          record={selectedData}
          setUserArray={setUserArray}
          onClose={onClose}
          type={tab}
          selectedId={selectedId}
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys),
          }}
        />
      )}
      <Modal
        title="Selected Employees"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Apply"
        mask={false}
      >
        <Table
          columns={columns}
          dataSource={selectedRowsData}
          pagination={false}
          rowKey="key"
        />
      </Modal>
      <Modal
        title={"Teacher data"}
        maskClosable={false}
        loading={modalLoader}
        className=""
        zIndex={1000000}
        open={openUploaddataListing}
        width={1000}
        onOk={async () => {
          await handleOk();
        }}
        okText="Save"
        onCancel={handleCancel}
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <>
          <div className="bulkuploadinnerbtn" style={{ marginBottom: "20px" }}>
            <div className="file-upload-wrapper">
              <label
                htmlFor="file-upload"
                className="custom-file-upload-button"
              >
                Upload File
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
            </div>
            <button
              onClick={() => {
                const link = document.createElement("a");
                link.href = "/path/to/your/template.xlsx";
                link.download = "Student_Data_Template.xlsx";
                link.click();
              }}
              className="download-template-button"
              style={{
                backgroundColor: "#00ADC4",
                color: "#fff",
                border: "none",
                padding: "8px 16px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Download Template
            </button>
          </div>
          {excelData?.length ? (
            <Table
              columns={xlsxColumns}
              dataSource={excelData}
              // onChange={handleChange}
              pagination={false}
              className="modelcon"
              scroll={{
                y: 200,
                x: 90,
              }}
              style={{ borderRadius: "8px" }}
            />
          ) : (
            // </div>
            <EmptyDataPage description="No records found" />
          )}
        </>
      </Modal>
    </>
  );
}

export default Acadamicemployee;
