import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Layout, Menu, Typography, Tabs } from "antd";
import "./setting.css";
import Institute from "../Component/Institute/Institute";
import Subject from "../Component/Subject/Subject";
import Class from "../Component/Class/Class";
import Vehicle from "../Component/vehicle/vehicle";
import Department from "../Component/Department/Department";
import Billing from "../Component/Billing/Billing";
import { decodeToken, token } from "../helper";
import { SCHOOL_ADMIN, TEACHER } from "../constants/roles";
const { TabPane } = Tabs;

function Settings() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState("institute");
  const [userRole, setUserRole] = useState(null);

  // const [tab, setTab] = useState(source);
  useEffect(() => {
    const source = searchParams.get("tab");
    if (source) {
      setTab(source);
    }
  }, [searchParams]);

  useEffect(() => {
    // Decode the token to get the role
    const role = decodeToken(token)?.role;
    setUserRole(role);
  }, []);

  const changeTab = (value) => {
    // navigate(`/setting?tab=${value}`);
    setTab(value);
  };

  return (
    <>
      {userRole === SCHOOL_ADMIN && (
        <div className="Tabcontainer">
          <Tabs
            activeKey={tab}
            tabBarGutter={48}
            centered
            onChange={changeTab}
            className="tabviewsubcon"
          >
            <TabPane
              tab={<p style={{ margin: "0px" }}>Institute</p>}
              key="institute"
              className="tabbtn"
            ></TabPane>
            <TabPane tab="Subject" key="subject"></TabPane>
            <TabPane tab="Class" key="class"></TabPane>
            <TabPane tab="Department" key="department"></TabPane>
            <TabPane tab="Vehicles" key="vehicle"></TabPane>
          </Tabs>

          {tab === "institute" ? (
            <Institute />
          ) : tab === "subject" ? (
            <Subject />
          ) : tab === "class" ? (
            <Class />
          ) : tab === "vehicle" ? (
            <Vehicle />
          ) : tab === "department" ? (
            <Department />
          ) : tab === "billing" ? (
            <Billing />
          ) : (
            ""
          )}
        </div>
      )}

      {userRole === TEACHER && (
        <div className="Tabcontainer">
          <Tabs
            defaultActiveKey={tab}
            tabBarGutter={48}
            centered
            onChange={changeTab}
            className="tabviewsubcon"
          >
            <TabPane
              tab={<p style={{ margin: "0px" }}>Institute</p>}
              key="institute"
              className="tabbtn"
            ></TabPane>
            <TabPane tab="Subject" key="subject"></TabPane>
            <TabPane tab="Class" key="class"></TabPane>
            <TabPane tab="Department" key="department"></TabPane>
            <TabPane tab="Vehicles" key="vehicle"></TabPane>
          </Tabs>

          {tab == "institute" ? (
            <Institute />
          ) : tab == "subject" ? (
            <Subject />
          ) : tab == "class" ? (
            <Class />
          ) : tab == "vehicle" ? (
            <Vehicle />
          ) : tab == "department" ? (
            <Department />
          ) : tab == "billing" ? (
            <Billing />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}

export default Settings;
