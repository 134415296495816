import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Select,
  Row,
  notification,
  Form,
  Col,
  message,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import './subject.css'
// import { fetchApi, customPagination } from "../../helper";
import Pagination from "../pagination/index";
import { fetchApi, token } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments } from "../../store/settings/department";

const { confirm } = Modal;

function Department() {
  const dispatch = useDispatch();
  const department = useSelector((state) => state.department);

  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalCount, setTotalCount] = useState(userData.length);
  const [userArray, setUserArray] = useState([]);
  // const [userData, setUserData] = useState(initialData);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [userDataList, setUserDataList] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(true);
  const [modalLoader, setModalLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  // useEffect(() => {
  //   dispatch(getDepartments());
  // }, [dispatch]);

  useEffect(() => {
    if (department?.data) {
      getDepartmentList();
    } else {
      dispatch(getDepartments());
    }
  }, [department?.data]);

  useEffect(() => {
    if (department.isError) {
      message.error(department.error);
    }
  }, [department.isError]);

  const getDepartmentList = async (token) => {
    const departmentData = department?.data?.data.map((department) => {
      return {
        key: department.department_id,
        departmentName: department.department_name,
        description: department.description,
      };
    });

    setUserData(departmentData || []);
    setFilteredData(departmentData || []);
    setTotalCount(departmentData?.length || 0);
    setUserDataList(departmentData || []);
    setLoader(false);

    // }
    // .catch((error) => {
    //   console.log(error);
    //   message.error("Error fetching employee details");
    // });
  };

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const resetModalState = () => {
    setModalLoader(false);
    setEditingRecord(null);
    form.resetFields();
    setOpenModal(false);
  };

  const openEditModal = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      departmentName: record.departmentName,
      description: record.description,
    });
    setOpenModal(true);
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      onOk() {
        setUserData((prev) => prev.filter((item) => item.key !== record.key));
        setTotalCount((prev) => prev - 1);
        notification.success({ message: "Record deleted successfully!" });
      },
    });
  };

  const handleSearch = (value) => {
    const filtered = userData.filter((item) =>
      item.departmentName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setTotalCount(filtered.length);
  };

  const useronChange = (value) => {
    if (value) {
      const filtered = userData.filter(
        (a) => a.Gradesec?.toLowerCase() === value?.toLowerCase()
      );
      setUserDataList(filtered);
      setTotalcount(filtered.length);
      setUserType(value);
    } else {
      setUserDataList(userData);
      setTotalcount(userData.length);
      setUserType("");
    }
  };

  const searchOnChange = (value) => {
    const data = userData.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.admissionID.toLowerCase().includes(value.toLowerCase())
    );
    setUserDataList(data);
    setSearch(value);
    setTotalcount(data.length);
  };

  const handleSave = async (value) => {
    const data = {
      department_name: value.departmentName,
      description: value.description,
    };

    const payload = {
      method: editingRecord ? "put" : "post",
      url: editingRecord ? `/department/${editingRecord?.key}` : "/department",
      headers: { Authorization: `Bearer ${token}` },
      data,
    };

    fetchApi(payload)
      .then((res) => {
        switch (res?.status) {
          case 201:
            setFilteredData((records) => [
              {
                key: res?.data[0]?.department_id,
                departmentName: res?.data[0]?.department_name,
                description: res?.data[0]?.description,
              },
              ...records,
            ]);
            console.log(res);
            message.success("Successfully created subject!");
            resetModalState();
            break;
          case 200:
            setFilteredData((records) =>
              records.map((record) => {
                if (record.key === editingRecord.key) {
                  return {
                    key: record.key,
                    departmentName: data.department_name,
                    description: data.description,
                  };
                }
                return record;
              })
            );
            // console.log(res);
            message.success("Successfully updated subject!");
            resetModalState();
            break;
        }
      })
      .catch((err) => {
        message.error("Error processing the request!");
        console.error("Add Subject Api Error : ", err);
      });

    // if (editingRecord) {
    //   // Edit logic
    //   setUserData((prev) =>
    //     prev.map((item) =>
    //       item.key === editingRecord.key ? { ...item, ...values } : item
    //     )
    //   );
    //   notification.success({ message: "Record updated successfully!" });
    // } else {
    //   // Add logic
    //   const newRecord = { ...values, key: Date.now() };
    //   setUserData((prev) => [...prev, newRecord]);
    //   notification.success({ message: "Record added successfully!" });
    // }
    // resetModalState();
  };

  const handleSubmit = async () => {
    setModalLoader(true);

    await form
      .validateFields()
      .then((data) => {
        handleSave(data);
      })
      .catch((err) => {
        message.error("Enter correct values!");
        setModalLoader(false);
      });
  };

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    { title: "Department Name", dataIndex: "departmentName" },
    { title: "Description", dataIndex: "description" },
    {
      title: "Actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => openEditModal(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => confirmDelete(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
        </div>
      ),
    },
  ];

  // const objectLength = Object.keys(initialData).length;

  return (
    <>
      <div className="tablecontainer">
        <div className="tableinnercontainer">
          <div className="d-md-flex justify-content-between align-items-center ">
            <div className="search-id">
              <Input
                placeholder="Search Department"
                prefix={<SearchOutlined />}
                onChange={(e) => handleSearch(e.target.value)}
                className="seachAccadamicemployee"
              />
            </div>

            <div className="d-lg-flex align-items-center ">
              <div className="d-flex justify-content-center px-3">
                <div className="btnacccon">
                  <div className="addbtn" onClick={() => setOpenModal(true)}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="lg"
                      className="icon"
                      style={{ fontSize: "22px" }}
                    />
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Add
                    </p>
                  </div>
                  <Pagination
                    customClass="d-none d-xl-flex"
                    // class-page-show.style.display="none";
                    onlyPagtination={true}
                    data={userDataList}
                    onPageChange={(data) => {
                      setTableData(data);
                    }}
                    initialItemsPerPage={10}
                  />
                </div>
              </div>
              {/* <Pagination
                className="custom-pagination"
                total={objectLength}
                defaultCurrent={1}
                defaultPageSize={10}
                current={currentPage}
                pageSize={pageSizeTotal}
                onChange={pageSizeChange}
                itemRender={customPagination}
              /> */}
            </div>
          </div>
        </div>
        <Spin spinning={loader}>
          <Table
            columns={columns}
            dataSource={tableData}
            // pagination={{
            //   className: "custom-pagination",
            //   defaultCurrent: 1,
            //   total: objectLength,
            //   current: currentPage,
            //   pageSize: pageSizeTotal,
            //   onChange: pageSizeChange,
            //   showSizeChanger: true,
            //   pageSizeOptions: ["5", "10", "20", "50"],
            //   itemRender: customPagination,
            //   showTotal: (total, range) => (
            //     <div
            //       className=" d-sm-flex d-none"
            //       style={{
            //         left: 0,
            //         fontSize: "14px",
            //         color: "#595959",
            //         position: "absolute",
            //       }}
            //     >
            //       Showing {range[1]} of {total}
            //     </div>
            //   ),
            // }}
            pagination={false}
            style={{ marginTop: "20px", borderRadius: "8px" }}
          />
        </Spin>
        <Pagination
          data={filteredData}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>

      <Modal
        open={openModal}
        onCancel={resetModalState}
        confirmLoading={modalLoader}
        onOk={async () => {
          await handleSubmit();
        }}
        maskClosable={false}
        okText="Save"
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <h3 style={{ color: "#00ADC4", textAlign: "center" }}>
          {editingRecord ? "Edit Department" : "Add Department"}
        </h3>
        <hr />
        <Spin spinning={modalLoader}>
          <Form form={form} layout="vertical" onFinish={handleSave}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Depatment Name"
                  name="departmentName"
                  rules={[
                    { required: true, message: "Please enter Depatment Name!" },
                  ]}
                >
                  <Input className="inputbox" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    { required: true, message: "Please enter Description!" },
                  ]}
                >
                  <Input className="inputbox" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}

export default Department;
