import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Col, Form, Select, Tabs, Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Student from "../Departmentadmin/Student";
import Teacher from "../Departmentadmin/Teacher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { TEACHER } from "../../constants/roles";
import { fetchApi } from "../../helper";

const { TabPane } = Tabs;

const Classinfo = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [tab, setTab] = useState("class");
  const [isOpen, setIsOpen] = useState(false);
  const [searchEnable, setSearchEnable] = useState(false);
  const [tabVisible, settabVisible] = useState(true);
  const [loader, setLoader] = useState(true);
  const [alldatas, setAllDatas] = useState([]);
  const [allTeacherFullData, setAllTeacherFullData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

    getTeachersData(token);
  }, []);

  const getTeachersData = async (token) => {
    const payload = {
      method: "GET",
      url: `/employee?role=${TEACHER}&_start=${0}&_limit=${10}`,
      headers: { Authorization: `Bearer ${token}` },
    };

    await fetchApi(payload)
      .then((response) => {
        const teacherData = response?.data?.data.map((teacher) => {
          return {
            key: teacher?.id,
            teachername: `${teacher?.firstname} ${teacher?.lastname}`,
            employeeID: teacher?.empid,
            role: TEACHER,
            phoneNumber: `${teacher?.countrycode} ${teacher?.mobilenumber}`,
            status: teacher?.status,
            classInfo: `${teacher?.assignedclass} - ${teacher?.section}`,
            tags: [teacher?.specialization],
            avatarUrl: teacher?.image,
          };
        });

        setAllDatas(teacherData);
        setAllTeacherFullData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        message.error("Error fetching employee details");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // const handleGradeChange = (e) => {
  //   setSelectedGrade(e.target.value);
  // };
  const changeTab = (value) => {
    navigate(`/department-admin?${value}`);
    setTab(value);
    // console.log(value, "hsagdhsg");
  };
  console.log(tab);
  return (
    <div>
      <div className="tablecontainers">
        {tabVisible && (
          <div className="tableinnercontainer" style={{ display: "flex" }}>
            <div className="classinfocontainer">
              <Tabs
                defaultActiveKey={tab}
                centered
                className="classinfotab"
                onChange={changeTab}
              >
                <TabPane
                  tab={<p className="classbtn">Class</p>}
                  key="class"
                ></TabPane>
                <TabPane
                  tab={<p className="classbtn">Teacher</p>}
                  key="Teacher"
                ></TabPane>
              </Tabs>
              <div className="wholeselect">
                <Select
                  // value={userType || undefined}
                  // onChange={openOnChange}
                  className="selectdiv"
                  allowClear
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Select Grade"
                  style={{ width: "150px" }}
                  dropdownStyle={{ width: "150px" }}
                  options={[
                    {
                      value: "10-A",
                      label: "10-A",
                    },
                    {
                      value: "12-B",
                      label: "12-B",
                    },
                  ]}
                />
              </div>
              <div>
                {/* {tab === "class" && tab === "student"? (<> <div className="search-id"> */}
                {searchEnable && (
                  <Input
                    // onChange={(e) => searchOnChange(e.target.value)}
                    // value={search}
                    placeholder="Search by Student  Name or ID"
                    prefix={<SearchOutlined />}
                    className="seachAccadamicemployee"
                  />
                )}
                {/* </div></>):''} */}
              </div>
            </div>
          </div>
        )}
        {tab === "class" ? (
          <Student
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            pagination={false}
            setSearchEnable={setSearchEnable}
            settabVisible={settabVisible}
            loader={loader}
            alldatas={alldatas}
          />
        ) : tab === "Teacher" ? (
          <Teacher
            settabVisible={settabVisible}
            loader={loader}
            alldatas={alldatas}
            teacherFullData={allTeacherFullData}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Classinfo;
