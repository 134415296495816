import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Typography, Avatar, Dropdown, Menu, message } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import logoImg from "../../Assets/png/comLOGO.png";

// import "./Navbar.css";
import axios from "axios";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { checkLogged } from "../../store/auth/authSlice";
import { sendNotify, getUserData, getRandomColor } from "../../helper";
import { faSortDesc } from "@fortawesome/free-solid-svg-icons";
const { Header } = Layout;

{
  /* <i class="fa-solid fa-bars"></i>; */
}
const Navbar = ({ headerName }) => {
  let token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [logoColor, setLogoColor] = useState("");
  const [logoText, setlogoText] = useState("");
  //   const navigate = useNavigate();

  const [userdata, setUserData] = useState({});

  //   const Subbreadcrum =
  //     useSelector((state) => state?.subBreadCrum?.subBreadCrum) || "Welcome back";
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate("/login-profile"); // Navigate to the profile page
  };

  const authToken = localStorage.getItem("schoolAuth");

  useEffect(() => {
    if (!authToken) {
      setIsAuthenticated(false);
    }
    findUserData();
  }, [authToken]);

  const handleLogout = () => {
    sendNotify("success", "Logout successfully");
    localStorage.clear(process.env.REACT_APP_JWT_TOKEN);
    sessionStorage.clear();
    // setIsAuthenticated(false);
    dispatch(checkLogged());
    window.location.href = "/";
  };

  const findUserData = () => {
    let data = getUserData();
    let color = getRandomColor();
    console.log(color, "color");
    setUserData(data);
    let logo = data?.username[0].toUpperCase();
    setlogoText(logo);
    setLogoColor(color);
  };

  //   const toggleSidebar = () => {
  //     // Get the sidebar and burger icon elements
  //     const sider = document.getElementsByClassName(
  //       "ant-layout-sider-collapsed"
  //     )[0];
  //     const burgerIcon = document.querySelector(".fa-bars");
  //     const rootbody = document.getElementById("root");
  //     // Toggle the display of the sidebar
  //     if (sider) {
  //       // If the sidebar is visible, hide it, otherwise show it
  //       if (sider.style.display === "none") {
  //         sider.style.display = "block"; // Show the sidebar
  //         // sider.style.width ="25%";
  //         // rootbody.style.width ="87%";
  //       } else {
  //         sider.style.display = "none"; // Hide the sidebar
  //         // rootbody.style.width ="100%";
  //       }
  //     }
  //   };
  const menu = [
    {
      key: "1",
      label: "Profile",
      icon: <UserOutlined />,
      onClick: navigateToProfile,
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: handleLogout,
    },
  ];
  // console.log(userdata, "ftfghbj");

  return (
    <Header
      className="Navbar_header"
      style={{
        background: "#ECF0F3",
        // padding: "50px 50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        // background: "#F1F8FF",
        border: "1px solid #F0F7FF",
        boxShadow: "4px 4px 8px 0px #E0E2E4 , -4px -4px -8px 0px #F8F9FA",
      }}
    >
      {/* Title Section */}

      {/* <span className="d-md-none d-flex">
        <i className="fa fa-bars" onClick={toggleSidebar}></i>
      </span> */}
      <p1
        style={{
          fontSize: "20px",
          color: "#333",
          fontFamily: "Poppins",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          gap: "10px", // Adds space between image and text if needed
        }}
        // className="welcomeheader"
      >
        <img src={logoImg} alt="Logo" />
      </p1>
      <div
        className="profileconainer"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Dropdown menu={{ items: menu }} trigger={["click"]}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              gap: "10px",
            }}
          >
            <Avatar
              size={40}
              style={{
                backgroundColor: logoColor,
                boxShadow:
                  "4px 4px 8px 0px #E0E2E4 inset, -4px -4px 8px 0px #F8F9FA inset",
                border: "none",
              }}
            >
              {logoText}
            </Avatar>
            <div
              style={{
                background: "#ECF0F3",
                width: "111px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
                boxShadow: "4px 4px 8px #E0E2E4, -4px -4px 8px #F8F9FA",
                borderRadius: "5px",
              }}
            >
              <div className="navheader">
                <Typography.Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {userdata.username}
                </Typography.Text>

                <Typography.Text
                  style={{
                    fontSize: "12px",
                    color: "#888",
                  }}
                >
                  {userdata.role}
                </Typography.Text>
              </div>
              <div>
                {/* <faSortDesc /> */}
                <FontAwesomeIcon
                  icon={faSortDesc}
                  //   onClick={() => openEditModal(record)}
                  style={{
                    cursor: "pointer",
                    color: "##37B497",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default Navbar;
