import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Col, Form, Select, Tabs, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Attendance from "../../Component/Departmentadmin/Attendance.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import LeaveRequest from "../../Component/Departmentadmin/LeaveRequest.jsx";

const { TabPane } = Tabs;

const StudentAttendance = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [tab, setTab] = useState("Attendance");
  const [isOpen, setIsOpen] = useState(false);
  const [searchEnable, setSearchEnable] = useState(false);
  const [tabVisible, settabVisible] = useState(true);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // const handleGradeChange = (e) => {
  //   setSelectedGrade(e.target.value);
  // };
  const changeTab = (value) => {
    navigate(`/studentattenance?${value}`);
    setTab(value);
    // console.log(value, "hsagdhsg");
  };
  console.log(tab);
  return (
    <div>
      <div className="tablecontainers">
        {tabVisible && (
          <div className="tableinnercontainer" style={{ display: "flex" }}>
            <div className="classinfocontainer">
              <Tabs
                defaultActiveKey={tab}
                centered
                className="classinfotab"
                onChange={changeTab}
                style={{ margin: "0px" }}
              >
                <TabPane
                  tab={<p className="classbtn">Attendance</p>}
                  key="Attendance"
                ></TabPane>
                <TabPane
                  tab={<p className="classbtn">Leave Request</p>}
                  key="LeaveRequest"
                ></TabPane>
              </Tabs>
              <div className="wholeselect">
                <Select
                  // value={userType || undefined}
                  // onChange={openOnChange}
                  className="selectdiv"
                  allowClear
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Select Grade"
                  style={{ width: "150px" }}
                  dropdownStyle={{ width: "150px" }}
                  options={[
                    {
                      value: "10-A",
                      label: "10-A",
                    },
                    {
                      value: "12-B",
                      label: "12-B",
                    },
                  ]}
                />
              </div>
              <div className="wholeselect">
                <input
                  // value={userType || undefined}
                  // onChange={openOnChange}
                  style={{
                    width: "150px",
                    border: "none",
                    backgroundColor: "#ECF0F3",
                    boxShadow:
                      "8px 8px 18px 0px #D1D9E6, -8px -8px 18px 0px #FFFFFF",
                    width: "166px",
                    height: "43px",
                    padding: "0px 10px",
                    borderRadius: "5px",
                  }}
                  type="date"
                  className="selectdiv"
                />
              </div>
              <div>
                {/* {tab == "class" && tab == "student"? (<> <div className="search-id"> */}
                {searchEnable && (
                  <Input
                    // onChange={(e) => searchOnChange(e.target.value)}
                    // value={search}
                    placeholder="Search by Student  Name or ID"
                    prefix={<SearchOutlined />}
                    className="seachAccadamicemployee"
                  />
                )}
                {/* </div></>):''} */}
              </div>
            </div>
          </div>
        )}
        {tab == "Attendance" ? (
          <Attendance
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            pagination={false}
            setSearchEnable={setSearchEnable}
            settabVisible={settabVisible}
          />
        ) : tab == "LeaveRequest" ? (
          <LeaveRequest settabVisible={settabVisible} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default StudentAttendance;
