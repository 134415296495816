import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Avatar,
  Select,
  Space,
  notification,
  Tag,
  message,
  Pagination,
} from "antd";
import {
  faPlus,
  faPenToSquare,
  faEye,
  faTrash,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { breadcrum } from "../action/Subbreadcrumaction";
import { customPagination, fetchApi } from "../helper/index.js";
import { getDriverDetails } from "../store/driverDetails/driverDetails.js";
import { DRIVER } from "../constants/roles.js";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import TableSortArrows from "../Component/tableSortArrows";
import Driveropr from "../Component/Driver/driveropr.jsx";
import moment from "moment";

const { confirm } = Modal;
const { Option } = Select;
function DriverDetails({ isEdit }) {
  const dispatch = useDispatch();
  const driver = useSelector((state) => state.driver);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fullDriverData, setFullDriverData] = useState([]);
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [teb, setTeb] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getDriverDetails(queryParams));
  }, [queryParams, dispatch]);

  useEffect(() => {
    if (driver?.data) {
      getDriverList();
    }
  }, [driver?.data]);

  useEffect(() => {
    if (driver.isError) {
      message.error(driver.error);
    }
  }, [driver.isError]);

  useEffect(() => {
    if (searchQuery.length === 0) searchOnChange(searchQuery);
    if (searchQuery.length < 3) return;

    const delaySearch = setTimeout(() => {
      searchOnChange(searchQuery);
    }, 100);

    return () => clearTimeout(delaySearch);
  }, [searchQuery]);

  const getDriverList = () => {
    const driverData = driver?.data?.data.map((driver) => {
      return {
        key: driver.driver_id,
        name: driver.full_name,
        admissionNo: driver.admission_no,
        role: DRIVER,
        phoneNumber: driver.staffmobilenumber,
        assignedvehicle: driver.assignedvehicle,
        status: driver.status,
        avatarUrl: driver.image,
        email: driver.email_id,
      };
    });

    setFullDriverData(driver?.data?.data);
    setAllData(driverData);
    setCount(driver?.data?.count);
    setTotalcount(driver?.data?.totalCount);
    setIsLoading(false);
  };

  const pageSizeChange = async (page, pageSize) => {
    setIsLoading(true);

    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    dispatch(getDriverDetails(ctr));
    setCurrentPage(page);
    setQueryParams(ctr);
  };

  const openOnChange = (value, teb) => {
    if (teb === "edit" || teb === "view") {
      let curDriverData = fullDriverData.find(
        (driver) => driver.driver_id === value.key
      );

      curDriverData = JSON.parse(JSON.stringify(curDriverData));

      curDriverData.dob = moment(curDriverData.dob);
      curDriverData.doj = moment(curDriverData.doj);

      setSelectedData(curDriverData);
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
    } else if (teb === "add") {
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedData({});
    } else {
      confirmDelete();
    }
  };

  const confirmDelete = (record) => {
    const isActive = record.status === "Active";
    const status = record.status === "Active" ? "Inactive" : "Active";

    confirm({
      title: isActive ? "Confirm to make Inactive?" : "Confirm to make Active?",
      content: isActive
        ? "Are you sure you want to make this record inactive?"
        : "Are you sure you want to make this record active?",

      className: "custom-confirm",
      centered: true,
      okText: "Confirm",
      async onOk() {
        await updateDriverStatus(record.key, status);
      },
      onCancel() {},
      okButtonProps: {
        className: "custom-save-button",
      },
      cancelButtonProps: {
        className: "custom-cancel-button",
      },
    });
  };

  const updateDriverStatus = async (driverId, status) => {
    const payload = {
      method: "patch",
      url: `/driver/${driverId}`,
      data: {
        status,
      },
    };

    await fetchApi(payload)
      .then((response) => {
        if (response?.status === 200) {
          notification.open({
            type: "success",
            message: "Update successfully!",
            className: "",
          });

          setAllData((drivers) => {
            return drivers.map((driver) =>
              driver.key === driverId ? { ...driver, status } : driver
            );
          });
        } else {
          message.error("Error updating driver status");
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("Error updating driver status");
      });
  };

  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedData({});
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Unselect all
      setSelectedRows([]);
      setSelectedRowsData([]);
      setOpen(false);
    } else {
      // Select all
      const allKeys = allData.map((item) => item.key);
      setSelectedRows(allKeys);
      setSelectedRowsData(allData);
      setOpen(true); // Show modal with all rows
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (record) => {
    let updatedSelectedRows;
    let updatedSelectedData;

    if (selectedRows.includes(record.key)) {
      // Remove row if unchecked
      updatedSelectedRows = selectedRows.filter((key) => key !== record.key);
      updatedSelectedData = selectedRowsData.filter(
        (item) => item.key !== record.key
      );
    } else {
      // Add row if checked
      updatedSelectedRows = [...selectedRows, record.key];
      updatedSelectedData = [...selectedRowsData, record];
    }

    setSelectedRows(updatedSelectedRows);
    setSelectedRowsData(updatedSelectedData);
    setSelectAll(updatedSelectedRows.length === allData.length);

    // Open modal when at least one row is selected
    setOpen(updatedSelectedRows.length > 0);
  };

  const columns = [
    ...(isEdit
      ? [
          {
            title: (
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            ),
            dataIndex: "select",
            render: (_, record) => (
              <input
                type="checkbox"
                checked={selectedRows.includes(record.key)}
                onChange={() => handleCheckboxChange(record)}
              />
            ),
          },
        ]
      : []),
    {
      title: "Driver Name",
      dataIndex: "name",
      width: 200,
      render: (text, record) => (
        <Space>
          <Avatar
            crossOrigin="anonymous | use-credentials"
            src={record.avatarUrl}
          />
          {text}
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Email ID",
      dataIndex: "email",
      width: 100,
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width: 200,
    },
    {
      title: "Assigned vehicle",
      dataIndex: "assignedvehicle",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 200,
      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${status === "Active" ? "#00ADC4" : "#EC7C74"}`,
            color: status === "Active" ? "#fff" : "#fff",
            background:
              status === "Active"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  isEdit &&
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      width: 200,
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange(record, "edit");
              setbreadcrum("Edit Driver");
            }}
          />
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange(record, "view");
              setbreadcrum("View Driver");
            }}
          />
          <FontAwesomeIcon
            icon={record.status === "Active" ? faTrash : faAdd}
            size="lg"
            style={{
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => confirmDelete(record)}
          />
        </div>
      ),
    });

  // const useronChange = async (value) => {
  //   if (value) {
  //     let finded = allData.filter((a) => a.role == value);
  //     // setDriverDataList(finded);
  //     setTotalcount(finded.length);
  //     setUserType(value);
  //   } else {
  //     // setDriverDataList(allData);
  //     setTotalcount(allData.length);
  //   }
  // };

  const searchOnChange = (value) => {
    setIsLoading(true);

    let param = { _start: 0, _limit: 10 };
    param.search = value;

    dispatch(getDriverDetails(param));
    setCurrentPage(1);
  };

  const setbreadcrum = (arg) => {
    dispatch(breadcrum(arg));
  };

  const hideModal = () => {
    setOpen(false);
  };

  return (
    <div>
      {!editModuleOpen ? (
        <>
          <div className="tablecontainer">
            <div className="tableinnercontainer">
              <div className="d-md-flex justify-content-between align-items-center ">
                <div className="search-id">
                  <Input
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                    placeholder="Search by Driver Name"
                    prefix={<SearchOutlined />}
                    className="seachAccadamicemployee"
                  />
                </div>

                <div className="nav-sectionBar">
                  <div className="d-flex align-items-center gap-5">
                    <div className="btnacccon">
                      {!isEdit ? (
                        <div
                          className="addbtn"
                          onClick={() => {
                            openOnChange(null, "add");
                            setbreadcrum("Add Driver");
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            size="lg"
                            className="icon"
                            style={{ fontSize: "22px" }}
                          />
                          <p
                            style={{
                              margin: "0px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Add
                          </p>
                        </div>
                      ) : null}
                    </div>
                    {isEdit ? (
                      <div>
                        <Select
                          allowClear
                          className="class-select-btns"
                          placeholder="Bulk Select"
                          suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                          style={{ width: "150px" }}
                          dropdownStyle={{ width: "150px" }}
                          onChange={(value) =>
                            console.log("Bulk action selected:", value)
                          }
                        >
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                        </Select>
                      </div>
                    ) : null}
                    <div className="pagination_align">
                      <div className=" d-md-flex d-none">
                        <Pagination
                          total={totalcount}
                          defaultCurrent={1}
                          current={currentPage}
                          defaultPageSize={10}
                          pageSize={queryParams._limit}
                          onChange={pageSizeChange}
                          itemRender={customPagination}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Table
              rowKey="key"
              columns={columns}
              dataSource={allData}
              tableLayout="fixed"
              className="table-datas"
              loading={isLoading}
              pagination={false}
            />
          </div>

          <div className="pagination_align">
            <div className="d-md-flex d-none">
              <span>{`Showing ${
                queryParams._start + 1
              } - ${count} of ${totalcount}`}</span>
            </div>
            <div>
              <Pagination
                total={totalcount}
                defaultCurrent={1}
                current={currentPage}
                defaultPageSize={10}
                pageSize={queryParams._limit}
                pageSizeOptions={["5", "10", "25", "50"]}
                onChange={pageSizeChange}
                itemRender={customPagination}
                showSizeChanger={true}
              />
            </div>
          </div>
        </>
      ) : (
        <Driveropr
          record={selectedData}
          onClose={onClose}
          type={teb}
          selectedId={selectedId}
        />
      )}
      <Modal
        title="Selected Drivers"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Apply"
        mask={false}
      >
        <Table
          columns={columns}
          dataSource={selectedRowsData}
          pagination={false}
          rowKey="key"
        />
      </Modal>
    </div>
  );
}

export default DriverDetails;
