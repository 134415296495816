import React, { useEffect, useState } from "react";
import { Modal, Table, Input, Form, Select, Space, message, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Component/pagination/index";
import TableSortArrows from "../tableSortArrows";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import bin from "../../Assets/Svg/bin.png";
import eye from "../../Assets/Svg/eye.png";
import edit from "../../Assets/Svg/edit.png";
import { fetchApi } from "../../helper";
import { useForm } from "antd/es/form/Form";

const { confirm } = Modal;
const { Option } = Select;

function Transport() {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [userType, setUserType] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  const [userDataList, setUserDataList] = useState("");
  const [totalCount, setTotalcount] = useState("");
  const [type, setType] = useState("");
  const [selectedId, setselectedId] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [modalLoader, setModalLoader] = useState(false);
  // const [editingRecord, setEditingRecord] = useState(null)
  const [form] = useForm();

  useEffect(() => {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

    getTransportFee(token);
  }, []);

  const getTransportFee = async (token) => {
    const payload = {
      method: "GET",
      url: `/transport?_start=${0}&_limit=${10}`,
      headers: { Authorization: `Bearer ${token}` },
    };

    await fetchApi(payload)
      .then((response) => {
        console.log(response);
        const transportFeeData = response?.data?.data.map((fee) => {
          return {
            key: fee.transportfees_id,
            transportfees_name: fee.transportfees_name,
            transporttotal_fees: fee.transporttotal_fees,
            class: fee.class,
          };
        });

        setTableData(transportFeeData);
        setFilteredData(transportFeeData);
        // setTotalCount(transportFeeData?.length || 0);
        // setUserDataList(subjectData);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Error fetching transport fee details!");
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setOpenModal(false);
    setModalLoader(false);
  };

  const handleOk = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);
    setModalLoader(true);

    await form
      .validateFields()
      .then((data) => {
        const payload = {
          method: selectedData ? "put" : "post",
          url: selectedData ? `/transport/${selectedData?.key}` : "/transport",
          headers: { Authorization: `Bearer ${token}` },
          data,
        };
        fetchApi(payload)
          .then((res) => {
            switch (res?.status) {
              case 201:
                setFilteredData((records) => [
                  {
                    key: res?.data[0]?.transportfees_id,
                    transportfees_name: res?.data[0]?.transportfees_name,
                    transporttotal_fees: res?.data[0]?.transporttotal_fees,
                    class: res?.data[0]?.class,
                  },
                  ...records,
                ]);
                console.log(res);
                message.success("Successfully created trasport fee!");
                resetModalState();
                break;
              case 200:
                setFilteredData((records) =>
                  records.map((record) => {
                    if (record.key === selectedData.key) {
                      return {
                        key: record.transportfees_id,
                        transportfees_name: data?.transportfees_name,
                        transporttotal_fees: data?.transporttotal_fees,
                        class: data?.class,
                      };
                    }
                    return record;
                  })
                );
                message.success("Successfully updated trasport fee!");
                resetModalState();
                break;
            }
          })
          .catch((err) => {
            message.error("Error processing the request!");
            console.error("Add trasport fee api Error : ", err);
          });
      })
      .catch((err) => {
        message.error("Enter correct values!");
        setModalLoader(false);
      });
  };

  // const openEditModal = (record) => {
  //   // setEditingRecord(record);
  //   form.setFieldsValue({
  //     subject_name: record.subject_name,
  //     subject_code: record.subject_code,
  //   });
  //   setOpenModal(true);
  // };

  const openOnChange = (value, type) => {
    if (type === "edit" || type === "view") {
      form.setFieldsValue({
        transportfees_name: value.transportfees_name,
        transporttotal_fees: value.transporttotal_fees,
        class: value.class,
      });

      setOpenModal(true);
      setselectedId(value.key);
      setSelectedData(value);
    } else if (type == "add") {
      form.resetFields();
      setOpenModal(true);
      setSelectedData(null);
    }
    setType(type);
  };

  const resetModalState = () => {
    setModalLoader(false);
    setSelectedData(null);
    form.resetFields();
    setOpenModal(false);
  };

  // const [form] = Form.useForm();
  // const [department, setDepartment] = useState("Primary"); // Default department for class 1

  // const handleClassChange = (value) => {
  //   let departmentValue = "";

  //   if (value >= 1 && value <= 5) {
  //     departmentValue = "Primary";
  //   } else if (value >= 6 && value <= 10) {
  //     departmentValue = "Secondary";
  //   } else if (value >= 11 && value <= 12) {
  //     departmentValue = "Higher secondary";
  //   }

  //   // Update department field dynamically
  //   setDepartment(departmentValue);
  //   form.setFieldsValue({ department: departmentValue });
  // };

  const useronChange = async (value) => {
    if (value) {
      let finded = tableData.filter((a) => a.Gradesec == value);
      setUserDataList(finded);
      setTotalcount(finded.length);
      setUserType(value);
    } else {
      setUserDataList(tableData);
      setTotalcount(tableData.length);
      setUserType("");
    }
  };

  const onClose = (action) => {
    setselectedId("");

    setSelectedData({});
  };

  console.log(tableData, "table data  ");

  const columns = [
    {
      title: <input type="checkbox" />,
      dataIndex: "checkbox",
      render: () => <input type="checkbox" />,
    },
    {
      title: "Fee Name",
      dataIndex: "transportfees_name",

      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Class",
      dataIndex: "class",

      sorter: (a, b) => a.employeeID.localeCompare(b.employeeID),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Fees Structure",
      dataIndex: "transporttotal_fees",

      sorter: (a, b) => a.role.localeCompare(b.role),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <>
          <img
            src={edit}
            alt="Edit"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange(record, "edit");
            }}
          />

          <img
            src={eye}
            alt="View"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
            onClick={() => {
              openOnChange(record, "view");
            }}
          />

          <img
            src={bin}
            alt="Delete"
            style={{
              width: "20px",
              height: "20px",
              color: "#00ADC4",
              marginLeft: "8px",
              marginTop: "6px",
              cursor: "pointer",
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      {/* {!modelOpen ? ( */}
      <div className="tablecontainer">
        <div className="tableinnercontainer ">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div className="search-id">
              <Input
                // onChange={(e) => searchOnChange(e.target.value)}
                // value={search}
                placeholder="Search Transport fee"
                prefix={<SearchOutlined />}
                className="seachAccadamicemployee"
              />
            </div>

            <div className="nav-sectionBar">
              <div className="d-flex align-items-center gap-3">
                <div className="wholeselect">
                  <Select
                    value={userType || undefined}
                    onChange={useronChange}
                    className="selectdiv"
                    allowClear
                    suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                    placeholder="Acadamic year"
                    style={{ width: "150px", height: "40px" }}
                    dropdownStyle={{ width: "150px" }}
                    options={[
                      {
                        value: "2020",
                        label: "2020",
                      },
                      {
                        value: "2022",
                        label: "2022",
                      },
                    ]}
                  />
                </div>

                <div className="btnaligwitselect">
                  <div>
                    <div
                      className="addbtn"
                      onClick={() => {
                        openOnChange({}, "add");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="lg"
                        className="icon"
                        style={{ fontSize: "22px" }}
                      />
                      <p
                        style={{
                          margin: "0px",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Add
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Spin spinning={loader}>
          <Table
            columns={columns}
            dataSource={filteredData}
            tableLayout="fixed"
            className=""
            pagination={false}
          />
        </Spin>

        <Pagination
          data={filteredData}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>
      <Modal
        open={openModal}
        maskClosable={false}
        onCancel={handleCancel}
        confirmLoading={modalLoader}
        onOk={async () => {
          await handleOk();
        }}
        okText="Save"
        cancelText={type === "view" ? "Close" : "Cancel"}
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <Spin spinning={modalLoader}>
          <Form form={form} style={{ padding: "20px" }}>
            <div className="row">
              <div className="col-md-6 ">
                <Form.Item name="transportfees_name" label="Fee Name">
                  <Input placeholder="" />
                </Form.Item>
              </div>

              <div className="col-md-6 ">
                <Form.Item
                  label="Class"
                  className="Class_alignment"
                  name="class"
                  rules={[{ required: true, message: "Please enter Class!" }]}
                  initialValue={1}
                  style={{
                    width: "100%",
                  }}
                >
                  <Select>
                    {Array.from({ length: 12 }, (_, i) => (
                      <Option key={i + 1} value={i + 1}>
                        {i + 1}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6 ">
                <Form.Item name="transporttotal_fees" label="Total Fees">
                  <Input
                    type="number"
                    maxLength="10"
                    className="no-arrows"
                    placeholder=""
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </Spin>
      </Modal>{" "}
      {/* ) : ( */}
      {/* )} */}
    </div>
  );
}

export default Transport;
