import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tabs, Breadcrumb } from "antd";
import Parent from "./Parent.jsx";
import EmployeeDetails from "../Component/EmployeeDetails";
import Studentdetails from "./Studentdetails.jsx";
import DriverDetails from "./DriverDetails.jsx";
import { DRIVER, PARENT, STUDENT, TEACHER } from "../constants/roles.js";

const { TabPane } = Tabs;

function Usermanagementcon() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState(TEACHER);

  useEffect(() => {
    const source = searchParams.get("tab");
    if (source) {
      setTab(source);
    }
  }, [searchParams]);

  const changeTab = (value) => {
    navigate(`/user-management?tab=${value}`);
    setTab(value);
  };

  const breadcrumbItems = [
    { path: "/department-admin", label: "User Management" },
    { path: `/department-admin?tab=${tab}`, label: tab },
    // { label: activeTab === "class" ? "Class" : TEACHER, path: "" },
  ];

  return (
    <div>
      <Breadcrumb
        separator=">>"
        style={{ marginBottom: "16px" }}
        className="custom-breadcrumb"
      >
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>
            {index < breadcrumbItems.length - 1 ? (
              <a href={item.path}>{item.label}</a>
            ) : (
              item.label
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <div className="Tabcontainer">
        <Tabs
          activeKey={tab}
          tabBarGutter={48}
          centered
          onChange={changeTab}
          className="tabviewsubcon"
        >
          <TabPane
            tab={<p style={{ margin: "0px" }}>Teacher</p>}
            key={TEACHER}
          ></TabPane>
          <TabPane tab="Student" key={STUDENT}></TabPane>
          <TabPane tab="Parent" key={PARENT}></TabPane>
          <TabPane tab="Driver" key={DRIVER}></TabPane>
        </Tabs>

        {tab === TEACHER ? (
          <EmployeeDetails isEdit={true} />
        ) : tab === STUDENT ? (
          <Studentdetails isEdit={true} />
        ) : tab === PARENT ? (
          <Parent isEdit={true} />
        ) : tab === DRIVER ? (
          <DriverDetails isEdit={true} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Usermanagementcon;
