import React from "react";
import "./Driver.css";
import DriverDetails from "./DriverDetails.jsx";

function Drivers() {
  return ( 
    <div>
      <DriverDetails isEdit={false} />
    </div>
  );
}

export default Drivers;
