import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  notification,
  Button,
  Typography,
  Tag,
} from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { UserOutlined, CalendarOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import courseheader from "../../Assets/png/courseheader.png";
import "./Course.css";
import { P } from "@antv/g2plot";
import Viewcourse from "./Viewcourse";

const { Title, Paragraph } = Typography;

const CourseSection = () => {
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [teb, setTeb] = useState("");
  const [view, setView] = useState(false);
  const [status, setStatus] = useState("");

  const courses = [
    {
      id: 1,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 2,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },
    {
      id: 3,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 4,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 5,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 6,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Approved",
    },
    {
      id: 7,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2025",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },
    {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        " pending Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },
    {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },
    {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },
    {
      id: 8,
      title: "Algebra",
      level: "Beginner",
      teacher: "Samuel",
      date: "16 Aug 2024",
      description:
        "Algebra is a branch of mathematics that uses symbols and letters to represent numbers and operations, solving equations and exploring relationships between variables.",
      status: "Pending",
    },

    // Add more courses here...
  ];
  const openOnChange = (value, teb) => {
    if (teb === "view-yet") {
      setStatus("Pending");
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    } else if (teb === "view") {
      setStatus("Approved");
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTeb(teb);
      setSelectedDate(value);
    }
  };

  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted",
      className: "",
    });
  };
  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);
    setSelectedDate({});
  };

  return (
    <div>
      {!editModuleOpen ? (
        <div>
          <div style={{ width: "100%", fontFamily: "poppins" }}>
            {/* Filter Section */}
            {/* <div className="class-search-sec">
              <div className="row">
                <div className="col-md-3">
                  <p>Department Head</p>
                  <Input
                    placeholder="Department Head"
                    style={{ width: "100%", background: "#D9D9D9" }}
                  />
                </div>
                <div className="col-md-3">
                  <p>Department </p>
                  <Input
                    placeholder="Department"
                    style={{ width: "100%", background: "#D9D9D9" }}
                  />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-3 mt-3">
                <div className="search-id">
                  <Input
                    placeholder="Search by Course Title "
                    prefix={<SearchOutlined />}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div> */}
            {/* Approved Courses Section */}
            <div style={{ width: "100%" }}>
              <div className="row">
                <div className="col-md-12">
                  <Title
                    level={4}
                    className="cardtype"
                    style={{ marginBottom: "16px" }}
                  >
                    Approved Courses
                  </Title>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "auto", // Enables horizontal scrolling
                      scrollSnapType: "x mandatory", // Ensures smooth scrolling
                      gap: "16px", // Space between cards
                      padding: "8px 0",
                      width: "100%",
                      // border: 'solid 1px red',
                      scrollbarWidth: "none", // Hides scrollbar in Firefox
                    }}
                    className="card-slider"
                  >
                    {courses
                      .filter((course) => course.status === "Approved")
                      .map((course) => (
                        <div className="col-10 col-sm-6 col-md-4">
                          <Card
                            key={course.id}
                            hoverable
                            style={{
                              flex: "0 0 auto", // Prevents shrinking; maintains size
                              // width: "350px",
                              borderRadius: "8px",
                              overflow: "hidden",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              scrollSnapAlign: "start", // Aligns card neatly during scroll
                            }}
                            cover={
                              <img
                                alt={`${course.title} Thumbnail`}
                                src={courseheader}
                                style={{
                                  height: "180px",
                                  objectFit: "cover",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                }}
                              />
                            }
                          >
                            <div
                              className="d-flex flex-lg-row text-left align-items-center d-lg-flex text-lg-center flex-column"
                              style={{
                                marginBottom: "8px",
                                width: "100%",
                              }}
                            >
                              <Title
                                level={4}
                                style={{
                                  margin: "0",
                                  color: "#00ADC4",
                                }} // Start of flex
                              >
                                {course.title}
                              </Title>
                              <p
                                style={{
                                  margin: "0",
                                  fontSize: "10px",
                                  color: "#ADADAD",
                                  textAlign: "center",
                                  flex: "1", // Center of flex
                                }}
                              >
                                {course.level}
                              </p>
                              <div
                                className="statusdiv"
                                style={{
                                  textAlign: "right",
                                  padding: "4px",
                                  color:
                                    course.status === "Approved"
                                      ? "#00ADC4"
                                      : "gold",
                                }}
                              >
                                {course.status}
                              </div>
                            </div>
                            <div className="row flex-lg-row flex-column">
                              <div
                                className="col-md-5"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  marginBottom: "8px",
                                }}
                              >
                                <i
                                  className="fas fa-cloud-upload-alt"
                                  style={{
                                    color: "#8c8c8c",
                                  }}
                                ></i>
                                <span>-</span>
                                <span
                                  style={{
                                    color: "#595959",
                                  }}
                                >
                                  {course.teacher}
                                </span>
                              </div>
                              <div
                                className="col-md-6"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  marginBottom: "8px",
                                }}
                              >
                                <i className="fas fa-calendar-alt"></i>
                                <span>-</span>
                                <span
                                  style={{
                                    color: "#595959",
                                  }}
                                >
                                  {course.date}
                                </span>
                              </div>
                            </div>
                            <Paragraph className="courseparadesc">
                              {course.description}
                            </Paragraph>
                            {/* <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            > */}
                            <div className="row justify-content-end">
                              <Button
                                type="primary"
                                className="col-md-3 viewbtncourse"
                                style={{ borderRadius: "4px" }}
                                onClick={() => openOnChange(null, "view")}
                              >
                                View
                              </Button>
                              <Button
                                style={{
                                  borderRadius: "4px",
                                  marginLeft: "8px",
                                }}
                                className="col-md-3 cancelbtncourse"
                              >
                                Delete
                              </Button>
                            </div>
                            {/* </div> */}
                          </Card>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px", width: "100%" }}>
              <div className="row">
                <div className="col-md-12">
                  <Title
                    level={4}
                    className="cardtype"
                    style={{ marginBottom: "16px" }}
                  >
                    Courses Yet to be Approved
                  </Title>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "auto", // Enables horizontal scrolling
                      scrollSnapType: "x mandatory", // Ensures smooth scrolling
                      gap: "16px", // Space between cards
                      padding: "8px 0",
                      width: "100%",
                      // border: 'solid 1px red',
                      scrollbarWidth: "none", // Hides scrollbar in Firefox
                    }}
                    className="card-slider"
                  >
                    {courses
                      .filter((course) => course.status !== "Approved")
                      .map((course) => (
                        <div className="col-10 col-sm-6 col-md-4">
                          <Card
                            key={course.id}
                            hoverable
                            style={{
                              flex: "0 0 auto", // Prevents shrinking; maintains size
                              // width: "350px",
                              borderRadius: "8px",
                              overflow: "hidden",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              scrollSnapAlign: "start", // Aligns card neatly during scroll
                            }}
                            cover={
                              <img
                                alt={`${course.title} Thumbnail`}
                                src={courseheader}
                                style={{
                                  height: "180px",
                                  objectFit: "cover",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                }}
                              />
                            }
                          >
                            <div
                              className="row  flex-column text-left align-items-center  text-lg-center flex-md-row"
                              style={{
                                marginBottom: "8px",
                                width: "100%",
                              }}
                            >
                              <Title
                                level={5}
                                style={{
                                  width: "fit-content",
                                  margin: "0",
                                  color: "#00ADC4",
                                }} // Start of flex
                              >
                                {course.title}
                              </Title>
                              <p
                                style={{
                                  width: "fit-content",
                                  margin: "0",
                                  fontSize: "10px",
                                  color: "#ADADAD",
                                  textAlign: "center",
                                  flex: "1", // Center of flex
                                }}
                              >
                                {course.level}
                              </p>
                              <div
                                className="statusdiv"
                                style={{
                                  width: "fit-content",
                                  textAlign: "right",
                                  padding: "4px",
                                  color:
                                    course.status === "Approved"
                                      ? "#00ADC4"
                                      : "gold",
                                }}
                              >
                                {course.status}
                              </div>
                            </div>
                            <div className="row flex-lg-row flex-column">
                              <div
                                className="col-md-5"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  marginBottom: "8px",
                                }}
                              >
                                <i
                                  className="fas fa-cloud-upload-alt"
                                  style={{
                                    color: "#8c8c8c",
                                  }}
                                ></i>
                                <span>-</span>
                                <span
                                  style={{
                                    color: "#595959",
                                  }}
                                >
                                  {course.teacher}
                                </span>
                              </div>
                              <div
                                className="col-md-6"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  marginBottom: "8px",
                                }}
                              >
                                <i className="fas fa-calendar-alt"></i>
                                <span>-</span>
                                <span
                                  style={{
                                    color: "#595959",
                                  }}
                                >
                                  {course.date}
                                </span>
                              </div>
                            </div>
                            <Paragraph className="courseparadesc">
                              {course.description}
                            </Paragraph>
                            <div className="row justify-content-end">
                              <Button
                                type="primary"
                                className="col-md-3 viewbtncourse"
                                style={{ borderRadius: "4px" }}
                                onClick={() =>
                                  openOnChange(
                                    { key: null, status: "pending" },
                                    "view-yet"
                                  )
                                }
                              >
                                View
                              </Button>
                              <Button
                                style={{
                                  borderRadius: "4px",
                                  marginLeft: "8px",
                                }}
                                className="col-md-3 cancelbtncourse"
                              >
                                Delete
                              </Button>
                            </div>
                          </Card>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Viewcourse status={status} onClose={onClose} />
        </div>
      )}
    </div>
  );
};

export default CourseSection;
