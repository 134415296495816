import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Select,
  Row,
  notification,
  Form,
  Col,
  message,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { fetchApi, customPagination } from "../../helper";
import {
  faFilter,
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../pagination/index";
import { useDispatch, useSelector } from "react-redux";
import { getSubjects } from "../../store/settings/subject";
import { getClassDetails } from "../../store/settings/class";

// import { fetchApi, customPagination } from "../../helper";

const { confirm } = Modal;

function Class() {
  const dispatch = useDispatch();
  const subject = useSelector((state) => state.subject);
  const classList = useSelector((state) => state.class);

  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [tableData, setTableData] = useState([]);
  // const [userArray, setUserArray] = useState([]);
  // const [userData, setUserData] = useState([]);
  const [userType, setUserType] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [search, setSearch] = useState("");
  const [userDataList, setUserDataList] = useState([]);
  const [classdetails, setClassDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [loader, setLoader] = useState(true);
  const [subjectDetails, setSubjectDetails] = useState([]);
  const [subjectOption, setSubjectOption] = useState([]);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });

  useEffect(() => {
    dispatch(getClassDetails(queryParams));
  }, [queryParams, dispatch]);

  useEffect(() => {
    if (classList?.data) {
      setClassOption();
    }
  }, [classList?.data]);

  useEffect(() => {
    if (subject?.data) {
      setSubjectOptions();
    } else {
      dispatch(getSubjects());
    }
  }, [subject?.data]);

  useEffect(() => {
    if (classList.isError) {
      message.error(classList.error);
    }
  }, [classList.isError]);

  const setClassOption = () => {
    console.log(classList, "classlist");
    const classData = classList?.data?.data.map((classDetails) => {
      return {
        key: classDetails?.class_id,
        class_name: classDetails?.class_name,
        section: classDetails?.section,
        subjects: classDetails?.subjects.join(" , "),
      };
    });

    setUserData(classData || []);
    setFilteredData(classData || []);
    setTotalCount(classData?.length || 0);
    setUserDataList(classData || []);
    setLoader(false);

    // })
    // .catch((error) => {
    //   // console.log(error);
    //   message.error("Error fetching class details");
    //   setLoader(false);
    // });
  };

  const setSubjectOptions = async () => {
    const data = subject?.data?.data.map((subject) => {
      return {
        value: `${subject?.subject_code}`,
        label: `${subject?.subject_name}`,
      };
    });

    setSubjectOption(data || []);
    setSubjectDetails(subject?.data?.data || []);
    setLoader(false);
    //   }
    // })
    // .catch((error) => {
    //   console.log(error);
    //   message.error("Error fetching subject details");
    // });
  };

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const resetModalState = () => {
    setEditingRecord(null);
    form.resetFields();
    setOpenModal(false);
  };

  const openEditModal = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      name: record.name,
      subjectID: record.subjectID,
      Grade: record.Grade,
      group: record.group,
    });
    setOpenModal(true);
  };

  const confirmDelete = (record) => {
    confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      onOk() {
        setUserData((prev) => prev.filter((item) => item.key !== record.key));
        setTotalCount((prev) => prev - 1);
        notification.success({ message: "Record deleted successfully!" });
      },
    });
  };

  const handleSearch = (value) => {
    const filtered = userData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setTotalCount(filtered.length);
  };

  const useronChange = (value) => {
    if (value) {
      const filtered = userData.filter(
        (a) => a.Gradesec?.toLowerCase() === value?.toLowerCase()
      );
      setUserDataList(filtered);
      setTotalcount(filtered.length);
      setUserType(value);
    } else {
      setUserDataList(userData);
      setTotalcount(userData.length);
      setUserType("");
    }
  };

  const searchOnChange = (value) => {
    const data = userData.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.admissionID.toLowerCase().includes(value.toLowerCase())
    );
    setUserDataList(data);
    setSearch(value);
    setTotalcount(data.length);
  };

  const handleclassChange = (e) => {
    const { name, value } = e.target;
    setClassDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

    await form
      .validateFields()
      .then(async (values) => {
        const payload = {
          method: "post",
          url: "/class",
          headers: { Authorization: `Bearer ${token}` },
          data: values,
        };

        await fetchApi(payload)
          .then((res) => {
            switch (res?.status) {
              case 201:
                // setFilteredData((records) => [
                //   {
                //     key: classDetails?.class_id,
                //     class_name: classDetails?.class_name,
                //     section: classDetails?.section,
                //     subject_id: classDetails?.subjects,
                //   },
                //   ...records,
                // ]);

                message.success("Successfully created subject!");
                resetModalState();
                break;
              case 200:
                setFilteredData((records) =>
                  records.map((record) => {
                    if (record.key === editingRecord.key) {
                      return {
                        key: res.data.subject_id,
                        subject_name: res.data.subject_name,
                        subject_code: res.data.subject_code,
                      };
                    }
                    return record;
                  })
                );
                message.success("Successfully updated subject!");
                resetModalState();
                break;
            }
          })
          .catch((err) => {
            message.error("Error processing the request!");
            console.error("Add Subject Api Error : ", err);
          });

        console.log(values);
      })
      .catch((err) => {
        message.error("Enter correct values!");
      });

    // const handleAddclass = () => {
    //   let payload = {
    //     method: "post",
    //     url: "/class",
    //     headers: { Authorization: `Bearer ${token}` },
    //     data: classdetails,
    //   };

    //   fetchApi(payload)
    //     .then((res) => {
    //       console.log(res, "fetchApisedrftgyhnjkml");
    //     })
    //     .catch((err) => {
    //       console.error("Add Subject Api Error : ", err);
    //     });
    // };

    // console.log("fghj", values);
    // if (editingRecord) {
    //   // Edit logic
    //   setUserData((prev) =>
    //     prev.map((item) =>
    //       item.key === editingRecord.key ? { ...item, ...values } : item
    //     )
    //   );
    //   notification.success({ message: "Record updated successfully!" });
    // } else {
    //   // Add logic
    //   const newRecord = { ...values, key: Date.now() };
    //   setUserData((prev) => [...prev, newRecord]);
    //   notification.success({ message: "Record added successfully!" });
    // }
    // resetModalState();
  };

  const columns = [
    {
      title: <input type="checkbox" />,
      render: () => <input type="checkbox" />,
    },
    { title: "Class Name", dataIndex: "class_name" },
    { title: "Sections", dataIndex: "section" },
    { title: "Subject", dataIndex: "subjects" },
    // { title: "Description", dataIndex: "Description" },
    {
      title: "Actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => openEditModal(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => confirmDelete(record)}
            style={{ cursor: "pointer", color: "#00ADC4", fontSize: "20px" }}
          />
        </div>
      ),
    },
  ];

  const objectLength = Object.keys(userData).length;
  const MAX_COUNT = 8;
  const suffix = (
    <>
      <span>
        {value.length} / {MAX_COUNT}
      </span>
      <DownOutlined />
    </>
  );
  // const objectLength = Object.keys(userArray).length;

  return (
    <>
      <div className="tablecontainer">
        <div className="tableinnercontainer ">
          <div className="d-md-flex justify-content-between align-items-center ">
            <div className="search-id ">
              <Input
                placeholder="Search by Class Name"
                prefix={<SearchOutlined />}
                onChange={(e) => handleSearch(e.target.value)}
                className="seachAccadamicemployee"
              />
            </div>

            <div className="d-lg-flex align-items-center ">
              <div className="d-flex justify-content-center px-3">
                <div className="btnacccon">
                  <div className="addbtn" onClick={() => setOpenModal(true)}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="lg"
                      className="icon"
                      style={{ fontSize: "22px" }}
                    />
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Add
                    </p>
                  </div>

                  <Pagination
                    customClass="d-none d-xl-flex"
                    // class-page-show.style.display="none";
                    onlyPagtination={true}
                    data={userDataList}
                    onPageChange={(data) => {
                      setTableData(data);
                    }}
                    initialItemsPerPage={10}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          loading={loader}
          columns={columns}
          dataSource={tableData}
          className="table-datas"
          pagination={false}
          style={{ marginTop: "20px", borderRadius: "8px" }}
        />
        <Pagination
          data={filteredData}
          onPageChange={(data) => {
            setTableData(data);
          }}
          initialItemsPerPage={10}
        />
      </div>

      <Modal
        // title={editingRecord ? "Edit Subject" : "Add Subject"}
        open={openModal}
        onCancel={resetModalState}
        onOk={async () => await handleSave()}
        maskClosable={false}
        okText="Save"
        okButtonProps={{
          className: "custom-save-button",
        }}
        cancelButtonProps={{
          className: "custom-cancel-button",
        }}
      >
        <h3 style={{ color: "#00ADC4", textAlign: "center" }}>
          {editingRecord ? "Edit Class" : "Add Class"}
        </h3>
        <hr />
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Class"
                name="class_name"
                rules={[
                  { required: true, message: "Please enter class name!" },
                ]}
              >
                <Input
                  style={{ width: "14rem" }}
                  placeholder="Class"
                  className="inputbox"
                  onChange={(e) => handleclassChange(e)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label=" Section"
                name="section"
                rules={[{ required: true, message: "Please enter section!" }]}
              >
                <Input
                  className="inputbox"
                  placeholder="Enter Section"
                  allowClear
                  onChange={(value) =>
                    handleclassChange({
                      target: { name: "total_section", value },
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Subject"
                name="subjects"
                rules={[
                  { required: true, message: "Please select a Subject!" },
                ]}
              >
                <Select
                  className="inputboxfms"
                  suffixIcon={<FontAwesomeIcon icon={faSortDown} />}
                  placeholder="Select Subject"
                  mode="multiple"
                  maxCount={8}
                  allowClear
                  onChange={(value) =>
                    handleclassChange({ target: { name: "subjects", value } })
                  }
                  options={subjectOption}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Class;
