import React, { useState } from "react";
import { Layout, Card, Typography, Calendar, Button, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExamPerformance from "../../layout/base/charts/ExamPerformance";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ClassSubjectView from "../../Component/classroom/ClassSubjectView.jsx";
// import Pagination from "../../Component/pagination/index.jsx";
import { Form, Input, Steps, message, Row, Col, Upload } from "antd";
import Pagination from "../../Component/pagination/index.jsx";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { faPlus, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Radio } from "antd";
// import "./Register.css";

const data = [
  {
    key: "1",
    Class: "11 - A",
    Examname: "CT - 1",
    grade: "A +",

    Mobileno: "7603992425",
    Admission: "Emp001",
  },
  {
    key: "2",
    Class: "11 - A",
    Examname: "CT - 2",
    grade: "A +",
    age: 17,
    Mobileno: "7603992425",
    Admission: "Emp002",
  },
  {
    key: "3",
    Class: "11 - A",
    Examname: "CT - 3",
    grade: "A +",

    Mobileno: "7603992425",
    Admission: "Emp003",
  },
  {
    key: "4",
    Class: "11 - A",
    Examname: "Model",
    grade: "A +",

    Mobileno: "7603992425",
    Admission: "Emp003",
  },
  {
    key: "5",
    Class: "11 - A",
    Examname: "Quarterly",
    grade: "A +",

    Mobileno: "7603992425",
    Admission: "Emp003",
  },
];

const ViewEnterMark = ({ setIsOpen, isOpen, settabVisible }) => {
  const [userArray, setUserArray] = useState(data);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [teb, setTeb] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeTotal, setPageSizeTotal] = useState(10);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);

  const { Text } = Typography;
  const { Step } = Steps;
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [formData, setFormData] = useState({
    schoolName: "",
    primaryEmail: "",
    contactNumber: "",
    schoolAddress: "",
    city: "",
    state: "",
    country: "",
    adminName: "",
    adminEmail: "",
    adminPhoneNumber: "",
    fileName: "",
    schoolType: "",
    password: "",
    confirmPassword: "",
  });
  const addField = () => {
    const newField = {
      id: Date.now(), // Unique ID for the dynamic field
      branchName: "",
      branchAddress: "",
      branchContactNumber: "",
      branchAdminName: "",
      branchAdminEmail: "",
      branchAdminMobileNumber: "",
    };
    setDynamicFields([...dynamicFields, newField]);
  };
  const removeField = (id) => {
    setDynamicFields(dynamicFields.filter((field) => field.id !== id));
  };
  const openOnChange = (value, teb) => {
    // settabVisible(false);
    if (teb === "view") {
      setModuleOpen(true);
      setSelectedDate(value);
    } else {
      setModuleOpen(false);
      setSelectedDate({});
    }
  };

  const columns = [
    { title: "Exam Name", dataIndex: "Examname", key: "Examname" },
    { title: "Admission", dataIndex: "Admission", key: "Admission" },
    { title: "Class", dataIndex: "Class", key: "Class" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    { title: "Mobile No", dataIndex: "Mobileno", key: "Mobileno" },
    {
      title: "Action",
      dataIndex: "status",
      align: "center",
      render: (_, record) => (
        <div>
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "view")}
          />
        </div>
      ),
    },
  ];
  console.log(isOpen, "isopen");

  const pageSizeChange = async (page, pageSize) => {
    setLoader(true);
    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    console.log(ctr, "awsetdiuyil");

    setCurrentPage(page);
    setPageSizeTotal(pageSize);
    setLoader(false);
  };

  const objectLength = Object.keys(userArray).length;

  return (
    <div>
      <>
        {!moduleOpen ? (
          <div className="studenttable">
            <div className="clas-student_Title d-sm-flex align-items-baseline justify-content-between mb-4">
              <div className="class-student_data d-md-flex align-items-center gap-5">
                <div className="class-data_img">
                  <img
                    src={"https://i.pravatar.cc/40?img=12"}
                    alt="ii"
                    // style={{ width: 200, height: 200 }}
                  />
                </div>

                <div className="clas-data_details d-flex flex-column">
                  <p1>Nivatha Thomas</p1>
                  <p2>Grade 11-A</p2>
                  <div>
                    <p3>24/7 , MVP Nagar , Anna Nagar , Chennai -603203</p3>
                  </div>
                </div>
              </div>
              <div className="class-student_fullDetails">
                <button>View Full Details</button>
              </div>
            </div>
            <div className="row mb-4">
              <div className="class-classroom_details col-md-12">
                <div>
                  {/* dynamic field */}
                  <Form layout="vertical">
                    {/* Static Fields */}
                    <div className="row g-3" style={{ marginBottom: "16px" }}>
                      <div className="col-sm-6">
                        <Form.Item
                          label="Exam Title"
                          name="static_branchName"
                          rules={[
                            {
                              required: true,
                              message: "Exam Title is required!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Exam Title"
                            value={formData.branchName}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-sm-6">
                        <Form.Item
                          label="Select Term"
                          name="static_branchAddress"
                          rules={[
                            {
                              required: true,
                              message: "Select Term is required!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Select Term"
                            value={formData.branchAddress}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-sm-4">
                        <Form.Item
                          label="Subject Name"
                          name="branchContactNumber"
                          rules={[
                            {
                              required: true,
                              message: "Subject Name is required!",
                            },
                            // ,
                            // {
                            //   pattern: /^\d{10}$/,
                            //   message: "Enter a valid 10-digit contact number!",
                            // },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Subject Name "
                            value={formData.contactNumber}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-sm-4">
                        <Form.Item
                          label="Marks"
                          name="branchAdminName"
                          rules={[
                            {
                              required: true,
                              message: "Marks is required!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Marks"
                            value={formData.branchAdminName}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-sm-4">
                        <Form.Item
                          label="Grade"
                          name="branchAdminEmail"
                          rules={[
                            {
                              required: true,
                              message: "Grade is required!",
                            },
                            // ,
                            // { type: "email", message: "Enter a valid email!" },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Admin Email"
                            value={formData.branchAdminEmail}
                            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </div>
                      {/* <div className="col-md-6">
                        <Form.Item
                          label="Branch Admin Mobile Number"
                          name="branchAdminMobileNumber"
                          rules={[
                            {
                              required: true,
                              message: "Admin Mobile Number is required!",
                            },
                            {
                              pattern: /^\d{10}$/,
                              message: "Enter a valid 10-digit contact number!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Mobile Number"
                            value={formData.branchAdminMobileNumber}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </div> */}
                    </div>

                    {/* Dynamic Fields */}
                    {dynamicFields.map((field) => (
                      <div
                        className="row g-3"
                        // gutter={16}
                        key={field.id}
                        style={{ marginBottom: "16px" }}
                      >
                        <div className="col-sm-6">
                          <Form.Item
                            label="Exam Title"
                            name={`branchName_${field.id}`}
                            rules={[
                              {
                                required: true,
                                message: "Exam Title is required!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Exam Title"
                              value={formData.branchName}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-sm-6">
                          <Form.Item
                            label="Select Term"
                            name={`branchAddress_${field.id}`}
                            rules={[
                              {
                                required: true,
                                message: "Select Term is required!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Select Term"
                              value={formData.branchAddress}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-sm-4">
                          <Form.Item
                            label="Subject Name"
                            name={`branchContactNumber_${field.id}`}
                            rules={[
                              {
                                required: true,
                                message: "Subject Name is required!",
                              },
                              //   ,
                              //   {
                              //     pattern: /^\d{10}$/,
                              //     message:
                              //       "Enter a valid 10-digit contact number!",
                              //   },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Contact Number"
                              value={formData.branchContactNumber}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-sm-4">
                          <Form.Item
                            label="Marks"
                            name={`branchAdminName_${field.id}`}
                            rules={[
                              {
                                required: true,
                                message: "Marks is required!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Admin Name"
                              value={formData.country}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-sm-4">
                          <Form.Item
                            label="Grade"
                            name={`branchAdminEmail_${field.id}`}
                            rules={[
                              {
                                required: true,
                                message: "Grade is required!",
                              },
                              //   ,
                              //   {
                              //     type: "email",
                              //     message: "Enter a valid email!",
                              //   },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Admin Email"
                              pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                              value={formData.branchAdminEmail}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div>
                        {/* <div className="col-md-6">
                          <Form.Item
                            label="Branch Admin Mobile Number"
                            name={`branchAdminMobileNumber_${field.id}`}
                            rules={[
                              {
                                required: true,
                                message: "Admin Mobile Number is required!",
                              },
                              {
                                pattern: /^\d{10}$/,
                                message:
                                  "Enter a valid 10-digit contact number!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Mobile Number"
                              value={formData.branchAdminMobileNumber}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div> */}
                        <div
                          className="col-md-12"
                          // span={24}
                          style={{
                            textAlign: "right",
                            position: "relative",
                            top: "20px",
                          }}
                        >
                          {/* <div
                            className="addiconreg"
                            style={{ marginLeft: "500px", marginTop: " -38px" }}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              style={{
                                fontSize: "20px",
                                color: "#00ADC4",
                                cursor: "pointer",
                              }}
                              onClick={addField}
                            />
                          </div> */}
                          <div>
                            <MinusCircleOutlined
                              style={{ fontSize: "20px", color: "red" }}
                              onClick={() => removeField(field.id)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* Add Button */}
                    <Row className="justify-content-end">
                      <div
                        className="addiconreg"
                        style={{ marginRight: "50px", marginTop: " -20px" }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{
                            fontSize: "20px",
                            color: "#00ADC4",
                            cursor: "pointer",
                          }}
                          onClick={addField}
                        />
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
              <div className="std-info-submit_btn mt-5">
                <input
                  // className="d-flex justify-content-center"
                  type="submit"
                />
              </div>
            </div>
            {/* <button onClick={() => setIsOpen(!isOpen)}>Back1</button> */}
            {/* <h4>Student Table</h4> */}

            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
            />
            <Pagination
              data={userArray}
              onPageChange={(data) => {
                setTableData(data);
              }}
              initialItemsPerPage={10}
            />
          </div>
        ) : (
          <ClassSubjectView
            data={selectedDate}
            isOpen={moduleOpen}
            setIsOpen={setModuleOpen}
            // settabVisible={settabVisible}
            pagination={false}
          />
        )}
      </>
    </div>
  );
};
export default ViewEnterMark;
