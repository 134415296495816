import React, { useState, useEffect } from "react";
import { Modal, DatePicker, TimePicker, message } from "antd";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewEvent,
  getEventDetails,
  resetEventErrorAndMessage,
} from "../store/eventDetails/eventDetails.js";

function Events() {
  const dispatch = useDispatch();
  const eventDetails = useSelector((state) => state.events);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [eventModelVisibility, setEventModalVisibility] = useState(false);
  const [name, setName] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState({
    start: dayjs("00:00", "HH:mm"),
    end: dayjs("00:00", "HH:mm"),
  });
  const [events, setEvents] = useState([]);
  const [dayEvent, setDayEvent] = useState(["No Event!"]);

  useEffect(() => {
    dispatch(getEventDetails());
  }, [dispatch]);

  useEffect(() => {
    if (eventDetails?.data) {
      getEventList();
    }
  }, [eventDetails?.data]);

  useEffect(() => {
    if (eventDetails.isError) {
      message.error(eventDetails.error);
    }
  }, [eventDetails.isError]);

  useEffect(() => {
    if (
      eventDetails.message &&
      !eventDetails.isLoading &&
      !eventDetails.isError
    ) {
      message.success(eventDetails.message);
      handleCancel();
      dispatch(getEventDetails());
    }

    dispatch(resetEventErrorAndMessage());
    setLoader(false);
  }, [eventDetails.message, eventDetails.error]);

  const twelveHourTime = (time) => {
    return time.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Enables AM/PM format
      timeZone: "Asia/Kolkata", // Ensure it's in UTC
    });
  };

  const getEventList = () => {
    const eventData = eventDetails?.data?.data;
    const eventFormattedData = eventData.map((event) => {
      const day = new Date(event?.date);
      const startDate = new Date(event?.start_time);
      const endDate = new Date(event?.end_time);

      const start_time = twelveHourTime(startDate);
      const end_time = twelveHourTime(endDate);

      return {
        title: event?.eventtitle,
        time: `${start_time} - ${end_time}`,
        date: day.getDate(),
        day: day.toLocaleDateString("en-IN", { weekday: "short" }),
      };
    });
    setEvents(eventFormattedData);
  };

  const handleDatePickerChange = (dateValue) => {
    if (dateValue) {
      setDate(dateValue);
    }
  };

  function getDaysInMonth(year, month) {
    return new Date(year, Number(month), 0).getDate();
  }

  const getCurrentMonth = (type) => {
    const date = new Date();
    return date.toLocaleString("default", { month: type });
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const numberOfDays = getDaysInMonth(
    getCurrentYear(),
    getCurrentMonth("numeric")
  );

  const daysInMonth = Array.from({ length: numberOfDays }, (_, i) => i + 1); // Days for the calendar

  const handleTimePickerChange = (key, timeValue) => {
    if (timeValue) {
      setTime((prev) => ({
        ...prev,
        [key]: timeValue, // Store dayjs object directly
      }));
    }
  };

  const nameOnChange = (value) => {
    setName(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const eventModel = (day) => {
    setEventModalVisibility(true);
    const curEvents = [];

    events.forEach((event) => {
      if (event.date === day) {
        const dayEvent = (
          <div
            style={{
              padding: "10px",
            }}
          >
            <h6>{event?.title}</h6>
            {/* <p>{event?.date}</p> */}
            <p>{event?.time}</p>
          </div>
        );
        curEvents.push(dayEvent);
        setDayEvent(curEvents);
      }
    });

    console.log(day, curEvents);
  };

  const handleCancel = () => {
    clearhandleReload();
    setIsModalVisible(false);
    setEventModalVisibility(false);
    setDayEvent(["No Event!"]);
  };

  const handleOk = async () => {
    setLoader(true);
    const obj = {
      eventtitle: name,
      date: date,
      start_time: time.start,
      end_time: time.end,
    };

    await dispatch(createNewEvent(obj));
  };

  const clearhandleReload = () => {
    setTime({ start: dayjs("00:00", "HH:mm"), end: dayjs("00:00", "HH:mm") });
    setDate(null);
    setName("");
  };

  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <div className="col-md-8">
          <div className="calendar-container border rounded p-3">
            <div className="calendar-header d-flex justify-content-between align-items-center">
              <span
                style={{
                  background: " #ECF0F3",
                  boxshadow:
                    "4px 4px 8px 0px #E0E2E4, -4px -4px 8px 0px #F8F9FA",
                }}
              >
                {getCurrentMonth("long")} {getCurrentYear()}
              </span>
              <h5>Monthly</h5>
              <button
                className="addeventbtn btn btn-primary d-flex align-items-center"
                onClick={showModal}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Add Event
              </button>{" "}
            </div>
            <div className="calendar-grid">
              {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                <div key={day} className=" text-center ">
                  {day}
                </div>
              ))}
              {daysInMonth.map((day) => (
                <div
                  key={day}
                  className="calendar-cell text-center"
                  onClick={() => eventModel(day)}
                >
                  {day}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="events-container ">
            <div className="eventheader  d-flex justify-content-center align-items-center">
              <h5 className="my-3">Events</h5>
            </div>
            {events.map((event, index) => (
              <div key={index} className="eventitemcontainer py-2 mx-5">
                <div className="event-item">
                  <div className="rounded shadow-sm d-flex justify-content-start p-2 gap-3">
                    <div className=" align-items-center ">
                      <p className="fw-bold " style={{ color: "#00ADC4" }}>
                        {event.date}
                      </p>
                      <p className="fw-bold">{event.day}</p>
                    </div>
                    <div className="border border-1"></div>
                    <div className="d-flex align-items-center m-0  flex-column">
                      <p className="mb-0 fw-bold">{event.title}</p>
                      <p className="text-muted small">{event.time}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal open={eventModelVisibility} onCancel={handleCancel} footer={null}>
        {dayEvent}
      </Modal>

      <Modal
        title="Add New Event"
        loading={loader}
        maskClosable={false}
        open={isModalVisible}
        onOk={async () => {
          await handleOk();
        }}
        onCancel={handleCancel}
      >
        <form className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="eventName" className="form-label">
              Event Name
            </label>
            <input
              type="text"
              className="form-control"
              id="eventName"
              placeholder="Enter event name"
              onChange={(e) => nameOnChange(e.target.value)}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label
              htmlFor="eventDate"
              className="form-label"
              placeholder="Enter a Date"
            >
              Event Date
            </label>
            <DatePicker
              style={{ width: "100%", backgroundColor: "#f4f4f4" }}
              onChange={(date) => handleDatePickerChange(date)}
              value={date}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="eventStartTime" className="form-label">
              Start Time
            </label>
            <TimePicker
              onChange={(timeValue) =>
                handleTimePickerChange("start", timeValue)
              }
              value={time.start} // Ensure value is passed
              format="HH:mm"
              getPopupContainer={(triggerNode) => document.body}
            />
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="eventEndTime" className="form-label">
              End Time
            </label>
            <TimePicker
              onChange={(timeValue) => handleTimePickerChange("end", timeValue)}
              value={time.end} // Ensure value is passed
              format="HH:mm"
              getPopupContainer={(triggerNode) => document.body}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Events;
