import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";

export const getCircularDetails = createAsyncThunk(
  "circularDetails/getCircularDetails",
  async (params, { rejectWithValue, fulfillWithValue }) => {
    let query = convertQueryParams(params);

    let payload = {
      method: "get",
      url: `/circular?${query}`,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

const incorpSlice = createSlice({
  name: "circular",
  initialState: {
    status: "idle",
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getCircularDetails.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
    });

    builder.addCase(getCircularDetails.fulfilled, (state, action) => {
      // console.log(action.payload, "fullfilled");
      state.status = "success";
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload.data;
    });

    builder.addCase(getCircularDetails.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });
  },
});

export default incorpSlice.reducer;
