import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi, convertQueryParams } from "../../helper";

export const getStudentDetails = createAsyncThunk(
  "students/getStudentDetails",
  async (params, { rejectWithValue, fulfillWithValue }) => {
    let query = convertQueryParams(params);

    let payload = {
      method: "get",
      url: `/student?${query}`,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

export const addStudent = createAsyncThunk(
  "students/addStudent",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    let payload = {
      method: "post",
      url: `/student`,
      data,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

export const updateStudent = createAsyncThunk(
  "students/updateStudent",
  async ({ data, selectedId }, { rejectWithValue, fulfillWithValue }) => {
    let payload = {
      method: "put",
      url: `/student/${selectedId}`,
      data,
    };

    try {
      let res = await fetchApi(payload);

      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue({
        status: err?.status,
        message: err?.message,
      });
    }
  }
);

const incorpSlice = createSlice({
  name: "students",
  initialState: {
    status: "idle",
    isLoading: false,
    data: null,
    isError: false,
    error: null,
    message: null,
  },
  reducers: {
    resetStudentErrorAndMessage: (state) => {
      state.error = null;
      state.isError = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    // get student details
    builder.addCase(getStudentDetails.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
    });

    builder.addCase(getStudentDetails.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload.data;
    });

    builder.addCase(getStudentDetails.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });

    // add student
    builder.addCase(addStudent.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
      state.message = null;
    });

    builder.addCase(addStudent.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoading = false;
      console.log(action.payload);
      switch (action.payload.status) {
        case 201:
          state.message = action.payload.message;
          state.isError = false;
          break;
        case 208:
          state.error = action.payload.message;
          state.isError = true;
          break;
        default:
          state.error = "Failed to process request!";
          state.isError = true;
      }
    });

    builder.addCase(addStudent.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });

    // update student
    builder.addCase(updateStudent.pending, (state, action) => {
      state.status = "loading";
      state.isLoading = true;
      state.message = null;
    });

    builder.addCase(updateStudent.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoading = false;
      state.data.data = state.data.data.map((data) => {
        if (action.payload.data.student_id === data.student_id)
          return action.payload.data;
        else return data;
      });

      switch (action.payload.status) {
        case 200:
          state.message = action.payload.message;
          state.isError = false;
          break;
        case 404:
          state.error = action.payload.message;
          state.isError = true;
          break;
        default:
          state.error = "Failed to process request!";
          state.isError = true;
      }
    });

    builder.addCase(updateStudent.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload.message;
    });
  },
});

export const { resetStudentErrorAndMessage } = incorpSlice.actions;
export default incorpSlice.reducer;
