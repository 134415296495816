import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  notification,
  Tag,
  message,
  Pagination,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { breadcrum } from "../action/Subbreadcrumaction";
import { getCircularDetails } from "../store/circularDetails/circularDetails.js";
import { customPagination } from "../helper/index.js";
import TableSortArrows from "../Component/tableSortArrows";
import Circularopr from "../Component/circular/Circularopr.jsx";
import moment from "moment";

const { confirm } = Modal;

function Circular() {
  const dispatch = useDispatch();
  const circular = useSelector((state) => state.circular);

  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 10 });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fullCircularData, setFullCircularData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [editModuleOpen, seteditModuleOpen] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [tab, setTab] = useState("");
  const [totalcount, setTotalcount] = useState("");
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    dispatch(getCircularDetails(queryParams));
  }, [queryParams, dispatch]);

  useEffect(() => {
    if (circular?.data) {
      getCircularList();
    }
  }, [circular?.data]);

  useEffect(() => {
    if (circular.isError) {
      message.error(circular.error);
    }
  }, [circular.isError]);

  useEffect(() => {
    if (searchQuery.length === 0) searchOnChange(searchQuery);
    if (searchQuery.length < 3) return;

    const delaySearch = setTimeout(() => {
      searchOnChange(searchQuery);
    }, 100);

    return () => clearTimeout(delaySearch);
  }, [searchQuery]);

  const getCircularList = () => {
    const circularData = circular?.data?.data.map((circular) => {
      return {
        key: circular.circular_id,
        NoticeName: circular.circularname,
        audience: circular.audience,
        publishedOn: moment(circular.publishedOn).format("MM-DD-YYYY"),
        status: capitalizeFirstLetter(circular.status),
      };
    });

    setFullCircularData(circular?.data?.data);
    setAllData(circularData);
    setCount(circular?.data?.count);
    setTotalcount(circular?.data?.totalCount);
    setIsLoading(false);
  };

  const openOnChange = (value, tab) => {
    if (tab === "edit" || tab === "view") {
      const curCircularData = fullCircularData.find(
        (circular) => circular.circular_id === value.key
      );

      setSelectedData(curCircularData);
      setselectedId(value?.key);
      seteditModuleOpen(true);
      setTab(tab);
    } else if (tab === "add") {
      seteditModuleOpen(true);
      setTab(tab);
      setSelectedData({});
    } else {
      confirmDelete();
    }
  };

  const confirmDelete = () => {
    confirm({
      // title: <h5>Confirm Delete</h5>,
      // content: <p>Are you sure you want to Delete?</p>,
      title: "Confirm Delete",
      content: "Are you sure you want to delete this record?",
      className: "custom-confirm",
      centered: true,
      okText: "Delete",
      onOk() {
        deleteonClick();
      },
      onCancel() {},
      okButtonProps: {
        className: "custom-save-button",
      },
      cancelButtonProps: {
        className: "custom-cancel-button",
      },
    });
  };

  const pageSizeChange = async (page, pageSize) => {
    setIsLoading(true);

    let ctr = {};
    ctr._start = page === 1 ? 0 : (page - 1) * pageSize;
    ctr._limit = pageSize;

    dispatch(getCircularDetails(ctr));
    setCurrentPage(page);
    setQueryParams(ctr);
  };

  const deleteonClick = () => {
    notification.open({
      type: "success",
      message: "data deleted successfully",
      className: "",
    });
  };

  const onClose = () => {
    setselectedId("");
    seteditModuleOpen(false);

    setSelectedData({});
  };

  const handleCheckboxChange = (record) => {
    let updatedSelectedRows;
    let updatedSelectedData;

    if (selectedRows.includes(record.key)) {
      // Remove row if unchecked
      updatedSelectedRows = selectedRows.filter((key) => key !== record.key);
      updatedSelectedData = selectedRowsData.filter(
        (item) => item.key !== record.key
      );
    } else {
      // Add row if checked
      updatedSelectedRows = [...selectedRows, record.key];
      updatedSelectedData = [...selectedRowsData, record];
    }

    setSelectedRows(updatedSelectedRows);
    setSelectedRowsData(updatedSelectedData);
    setSelectAll(updatedSelectedRows.length === allData.length);

    // Open modal when at least one row is selected
    setOpen(updatedSelectedRows.length > 0);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Unselect all
      setSelectedRows([]);
      setSelectedRowsData([]);
      setOpen(false);
    } else {
      // Select all
      const allKeys = allData.map((item) => item.key);
      setSelectedRows(allKeys);
      setSelectedRowsData(allData);
      setOpen(true); // Show modal with all rows
    }
    setSelectAll(!selectAll);
  };

  const columns = [
    {
      title: (
        <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
      ),
      dataIndex: "select",
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(record.key)}
          onChange={() => handleCheckboxChange(record)}
        />
      ),
    },

    {
      title: "NoticeName ",
      dataIndex: "NoticeName",
      sorter: (a, b) => a.NoticeName.localeCompare(b.NoticeName),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Audience",
      dataIndex: "audience",
    },
    {
      title: "Published On",
      dataIndex: "publishedOn",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag
          style={{
            border: `1px solid ${
              status === "Published" ? "#00ADC4" : "#EC7C74"
            }`,
            color: status === "Published" ? "#fff" : "#fff",
            background:
              status === "Published"
                ? "linear-gradient(98.22deg, #00ADC4 -13.56%, #00535E 133.98%)"
                : "#EC7C74",
            width: "90px",
            textAlign: "center",
            padding: "5px 0",
            borderRadius: "4px",
            width: "100px",
          }}
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => openOnChange(record, "edit")}
          />
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{ color: "#00ADC4", marginLeft: "8px", marginTop: "6px" }}
            onClick={() => confirmDelete(record)}
          />
        </div>
      ),
    },
  ];

  const searchOnChange = (value) => {
    setIsLoading(true);

    let param = { _start: 0, _limit: 10 };
    param.search = value;

    dispatch(getCircularDetails(param));
    setCurrentPage(1);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const setbreadcrum = (arg) => {
    dispatch(breadcrum(arg));
  };

  return (
    <>
      <div>
        {!editModuleOpen ? (
          <>
            <div className="tablecontainer">
              <div className="tableinnercontainer">
                <div className="d-md-flex justify-content-between align-items-center ">
                  <div className="search-id">
                    <Input
                      onChange={(e) => setSearchQuery(e.target.value)}
                      value={searchQuery}
                      placeholder="Search by Circular Name"
                      prefix={<SearchOutlined />}
                      className="seachAccadamicemployee"
                    />
                  </div>

                  {/* <div className="nav-sectionBar"> */}
                  <div className="d-lg-flex align-items-center ">
                    <div className="d-flex justify-content-center px-3 gap-5">
                      <div className="btnacccon">
                        <div
                          className="addbtn"
                          onClick={() => openOnChange(null, "add")}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            size="lg"
                            className="icon"
                            style={{ fontSize: "22px" }}
                          />
                          <p
                            style={{
                              margin: "0px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Add
                          </p>
                        </div>

                        <Pagination
                          total={totalcount}
                          defaultCurrent={1}
                          current={currentPage}
                          defaultPageSize={10}
                          pageSize={queryParams._limit}
                          onChange={pageSizeChange}
                          itemRender={customPagination}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={allData}
                pagination={false}
                className="table-datas"
              />
            </div>
            <div className="pagination_align">
              <div>
                <span>{`Showing ${
                  queryParams._start + 1
                } - ${count} of ${totalcount}`}</span>
              </div>
              <div>
                <Pagination
                  total={totalcount}
                  defaultCurrent={1}
                  current={currentPage}
                  defaultPageSize={10}
                  pageSize={queryParams._limit}
                  pageSizeOptions={["5", "10", "25", "50"]}
                  onChange={pageSizeChange}
                  itemRender={customPagination}
                  showSizeChanger={true}
                />
              </div>
            </div>
          </>
        ) : (
          <Circularopr
            record={selectedData}
            onClose={onClose}
            type={tab}
            selectedId={selectedId}
          />
        )}

        <Modal
          title="Selected Employees"
          open={open}
          onOk={hideModal}
          onCancel={hideModal}
          okText="Apply"
          mask={false}
        >
          <Table
            columns={columns}
            dataSource={selectedRowsData}
            pagination={false}
            rowKey="key"
          />
        </Modal>
      </div>
    </>
  );
}

export default Circular;
