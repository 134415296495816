import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { store } from "./store/configureStore";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { ConfigProvider } from "antd"; // Import ConfigProvider from antd
import dayjs from "dayjs"; // Import dayjs for date handling
import "dayjs/locale/en";
import "antd/dist/reset.css";
import "./style/variable.css";
import "./style/style.css";
import "./style/antdcss.css";
import axios from "axios";
import "./style/bootstrap.min.css";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Accept"] = "application/json";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider dateAdapter={dayjs} store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
