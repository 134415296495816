import React from "react";
import Studentdetails from "./Studentdetails.jsx";

function Student() {
  return (
    <>
      <Studentdetails isEdit={false} />
    </>
  );
}

export default Student;
