import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Steps,
  message,
  Row,
  Col,
  Upload,
  Spin,
  Image,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { MinusCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Radio, Typography } from "antd";
import "./Register.css";
import { fetchApi } from "../helper";
import { Link, useNavigate } from "react-router-dom";
const { Text } = Typography;
const { Step } = Steps;

const RegistrationForm = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [schoollogo, setSchoolLogo] = useState({});
  const [schoolicon, setSchoolIcon] = useState({});
  const [isLoder, setIsLoder] = useState(false);
  const [dynamicFields, setDynamicFields] = useState([]);
  // const [hasBranches, setHasBranches] = useState(null);

  const [formData, setFormData] = useState({
    school_name: "",
    primaryemail: "",
    contactnumber: "",
    school_address: "",
    city: "",
    state: "",
    country: "",
    area: "",
    adminname: "",
    adminemail: "",
    adminphonenumber: "",
    schooltype: "",
    branchname: "",
    branchaddress: "",
    branchadminemail: "",
    branchadminmobilenumber: "",
    branchadminname: "",
    branchcontactnumber: "",
    password: "",
  });

  const addField = () => {
    const newField = {
      id: Date.now(),
      branchname: "",
      branchaddress: "",
      branchContactNumber: "",
      branchAdminName: "",
      branchadminemail: "",
      branchAdminMobileNumber: "",
    };
    setDynamicFields([...dynamicFields, newField]);
  };

  const removeField = (id) => {
    setDynamicFields(dynamicFields.filter((field) => field.id !== id));
  };

  const onNext = async () => {
    try {
      await form.validateFields();
      setCurrentStep(currentStep + 1);
    } catch (error) {
      message.error("Please fill all required fields!");
    }
  };

  // const handleRadioChange = (e) => {
  //   setHasBranches(e.target.value);
  // };

  // handle image upload error
  const uploadImage = async () => {
    const formData = new FormData();

    formData.append("schoolLogo", schoollogo);
    formData.append("schoolIcon", schoolicon);

    const payload = {
      method: "post",
      url: "/upload/multi",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    return await fetchApi(payload)
      .then((response) => {
        if (response.status === 201) {
          return response.data;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinish = async () => {
    setIsLoder(true);

    // if (schoolicon?.name && schoollogo?.name) {
    //   message.error("Upload School Icon and School Logo!");
    //   setIsLoder(false);
    //   return;
    // }

    let obj = {
      name: formData.school_name ?? null,
      email: formData.primaryemail ?? null,
      password: formData.password ?? null,
      institutename: formData.school_name ?? null,
      school: {
        school_name: formData.school_name ?? null,
        primaryemail: formData.primaryemail ?? null,
        contactnumber: formData.contactnumber ?? null,
        school_address: formData.school_address ?? null,
        city: formData.city ?? null,
        state: formData.state ?? null,
        country: formData.country ?? null,
        area: formData.area ?? null,
        theme: "#00535E",
        adminname: formData.adminname ?? null,
        adminemail: formData.adminemail ?? null,
        adminphonenumber: formData.adminphonenumber ?? null,
        schooltype: formData.schooltype ?? null,
        branchname: formData.branchname ?? null,
        branchaddress: formData.branchaddress ?? null,
        branchcontactnumber: formData.branchcontactnumber ?? null,
        branchadminname: formData.branchadminname ?? null,
        branchadminemail: formData.branchadminemail ?? null,
        branchadminmobilenumber: formData.branchadminmobilenumber ?? null,
      },
    };

    if (schoolicon && schoollogo) {
      const res = await uploadImage();
      obj.school.schoollogo = res?.schoolLogo ?? null;
      obj.school.schoolicon = res?.schoolIcon ?? null;
    }

    let payload = {
      method: "post",
      url: "/register",
      data: obj,
    };

    await fetchApi(payload)
      .then((response) => {
        console.log(response);
        if (response.success) {
          message.success(response?.message || "Registration successful!");
          navigate("/");
        } else {
          message.error(response?.message || "Email already exist");
        }
        // switch (response.status) {
        //   case 201:
        //     message.success(response.message || "Registration successful!");
        //     navigate("/");
        //     break;
        //   case 400:
        //     message.error(response.message || "Email already exist");
        //     break;
        //   default:
        //     message.error("Failed to process the request!");
        // }
      })
      .catch((err) => {
        console.log(err, "error");
        message.error("Failed to process the request!");
      })
      .finally(() => {
        setIsLoder(false);
      });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    console.log({ [id]: value });
    setFormData({ ...formData, ...{ [id]: value } });
  };

  const [logoPreviewURL, setLogoPreviewURL] = useState(null);
  const [iconPreviewURL, setIconPreviewURL] = useState(null);

  const handleBeforeUpload = (file, type) => {
    const MAX_FILE_SIZE = 200 * 1024; // 200 KB in bytes
    const ALLOWED_FILE_TYPES = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
    ];

    // Validate file type
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      message.error(
        "Unsupported file format. Please upload a PNG, JPG, or SVG file."
      );
      return Upload.LIST_IGNORE;
    }

    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      message.error("File size exceeds 200 KB. Please upload a smaller file.");
      return Upload.LIST_IGNORE;
    }

    // Set preview URL
    if (type === "logo") {
      setSchoolLogo(file);
      setLogoPreviewURL(URL.createObjectURL(file));
    }

    if (type === "icon") {
      setSchoolIcon(file);
      setIconPreviewURL(URL.createObjectURL(file));
    }

    return false; // Prevent automatic upload
  };

  return (
    <Spin spinning={isLoder}>
      <div style={{ height: "100vh", width: "100vw", margin: "0" }}>
        <Row style={{ height: "100%" }}>
          <Col
            xs={24}
            sm={12}
            style={{
              backgroundColor: "#f0f2f5",
              height: "100%",
              overflowY: "scroll",
              scrollbarWidth: "none",
              padding: "20px",
            }}
          >
            <div style={{ height: "100%" }}>
              <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                Register Your Account
              </h2>

              <Steps current={currentStep} labelPlacement="vertical">
                <Step title="School Detail" />
                <Step title="Admin Detail" />
                <Step title="Branch Detail" />
              </Steps>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                style={{ marginTop: "20px" }}
              >
                {currentStep === 0 && (
                  <div>
                    <Row gutter={[16, 16]} className="admin-contect">
                      <Col span={12} lg={24}>
                        <Form.Item
                          label="School Name"
                          name="school_name"
                          rules={[
                            {
                              required: true,
                              message: "School Name is required!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter School Name"
                            value={formData.school_name}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="admin-contect">
                      <Col span={12}>
                        <Form.Item
                          label="Primary Email"
                          name="primaryemail"
                          rules={[
                            { required: true, message: "Email is required!" },
                            { type: "email", message: "Enter a valid email!" },
                          ]}
                        >
                          <Input
                            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                            size="large"
                            placeholder="Enter Primary Email"
                            value={formData.primaryemail}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Contact Number"
                          name="contactnumber"
                          rules={[
                            {
                              required: true,
                              message: "Contact Number is required!",
                            },
                            {
                              pattern: /^\d{10}$/,
                              message: "Enter a valid 10-digit contact number!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Contact Number"
                            value={formData.contactnumber}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12} lg={24}>
                        <Form.Item
                          label="School Address"
                          name="school_address"
                          rules={[
                            { required: true, message: "Address is required!" },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter School Address"
                            value={formData.school_address}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col span={7}>
                        <Form.Item
                          label="City"
                          name="city"
                          rules={[
                            { required: true, message: "City is required!" },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter City"
                            value={formData.city}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          label="State"
                          name="state"
                          rules={[
                            { required: true, message: "State is required!" },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter State"
                            value={formData.state}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          label="Country"
                          name="country"
                          rules={[
                            { required: true, message: "Country is required!" },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Country"
                            value={formData.country}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12} lg={24}>
                        <Form.Item
                          label="Area"
                          name="area"
                          rules={[
                            { required: true, message: "Area is required!" },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter your Area"
                            value={formData.area}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}

                {/* Add content for steps 2 and 3 as needed */}
                {currentStep === 1 && (
                  <div>
                    <Row gutter={[16, 16]}>
                      <Col span={12} lg={24}>
                        <Form.Item
                          label="Admin Name"
                          name="adminname"
                          rules={[
                            {
                              required: true,
                              message: "Admin Name is required!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Admin Name"
                            value={formData.adminname}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Admin Email"
                          name="adminemail"
                          rules={[
                            {
                              required: true,
                              message: "Admin Email is required!",
                            },
                            { type: "email", message: "Enter a valid email!" },
                          ]}
                        >
                          <Input
                            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                            size="large"
                            placeholder="Enter Admin Email"
                            value={formData.adminemail}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Admin Phone Number"
                          name="adminphonenumber"
                          rules={[
                            {
                              required: true,
                              message: "Admin Phone Number is required!",
                            },
                            {
                              pattern: /^\d{10}$/,
                              message: "Enter a valid 10-digit contact number!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Contact Number"
                            value={formData.adminphonenumber}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        {schoollogo && (
                          <div style={{ marginTop: 16 }}>
                            <Image
                              src={logoPreviewURL}
                              alt="Icon Preview"
                              width={100}
                              height={100}
                            />
                          </div>
                        )}
                        <Form.Item
                          label="School Logo"
                          name="schoollogo"
                          rules={[
                            {
                              required: true,
                              message: "Logo is required!",
                            },
                          ]}
                        >
                          <div
                            style={{
                              backgroundColor: "white",
                              width: "250px",
                              display: "flex",
                            }}
                          >
                            <Input
                              style={{ width: "150px", border: "none" }}
                              readOnly
                              placeholder="No file chosen"
                              value={schoollogo.name}
                            />
                            <Upload
                              style={{ borderRadius: "8px" }}
                              accept="image/*"
                              beforeUpload={(imgData) =>
                                handleBeforeUpload(imgData, "logo")
                              }
                              // onChange={handleUpload}
                              showUploadList={false}
                            >
                              <Button
                                icon={<UploadOutlined />}
                                className="insaddbtnacc"
                              >
                                Choose file
                              </Button>
                            </Upload>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        {schoolicon && (
                          <div style={{ marginTop: 16 }}>
                            <Image
                              src={iconPreviewURL}
                              alt="Icon Preview"
                              width={100}
                              height={100}
                            />
                          </div>
                        )}
                        <Form.Item
                          label="School Icon"
                          name="schoolicon"
                          rules={[
                            {
                              required: true,
                              message: "Icon is required!",
                            },
                          ]}
                        >
                          <div
                            style={{
                              backgroundColor: "white",
                              width: "250px",
                              display: "flex",
                            }}
                          >
                            <Input
                              style={{ width: "150px", border: "none" }}
                              value={schoolicon.name}
                              readOnly
                              placeholder="No file chosen"
                            />
                            <Upload
                              style={{ borderRadius: "8px" }}
                              accept="image/*"
                              beforeUpload={(imgData) =>
                                handleBeforeUpload(imgData, "icon")
                              }
                              showUploadList={false}
                            >
                              <Button
                                icon={<UploadOutlined />}
                                className="insaddbtnacc"
                              >
                                Choose file
                              </Button>
                            </Upload>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          label="School Type"
                          name="schooltype"
                          rules={[
                            {
                              required: true,
                              message: "School Type is required!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter School Type "
                            value={formData.schooltype}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Password is required!",
                            },
                            {
                              pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                              message: "Enter valid password",
                            },
                          ]}
                        >
                          <Input.Password
                            size="large"
                            placeholder="Enter Password "
                            value={formData.password}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Confirm Password"
                          name="confirmpassword"
                          rules={[
                            {
                              required: true,
                              message: "confirm Password is required!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("Passwords do not match!")
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            size="large"
                            placeholder="Enter confirm Password "
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}

                {currentStep === 2 && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Text strong style={{ fontSize: "16px" }}>
                        Does the School have Branches?
                      </Text>
                      <Radio.Group
                      // onChange={handleRadioChange}
                      >
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </div>

                    {/* dynamic field */}
                    <Form layout="vertical">
                      {/* Static Fields */}
                      <Row gutter={16} style={{ marginBottom: "16px" }}>
                        <Col span={12}>
                          <Form.Item
                            label="Branch Name"
                            name="branchname"
                            rules={[
                              {
                                required: true,
                                message: "Branch Name is required!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Branch Name"
                              value={formData.branchname}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Branch Address"
                            name="branchaddress"
                            rules={[
                              {
                                required: true,
                                message: "Branch Address is required!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Branch Address"
                              value={formData.branchaddress}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Branch Contact Number"
                            name="branchcontactnumber"
                            rules={[
                              {
                                required: true,
                                message: "Branch Contact Number is required!",
                              },
                              {
                                pattern: /^\d{10}$/,
                                message:
                                  "Enter a valid 10-digit contact number!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Contact Number"
                              value={formData.contactnumber}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Branch Admin Name"
                            name="branchadminname"
                            rules={[
                              {
                                required: true,
                                message: "Branch Admin Name is required!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Admin Name"
                              value={formData.branchadminname}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Branch Admin Email"
                            name="branchadminemail"
                            rules={[
                              {
                                required: true,
                                message: "Branch Admin Email is required!",
                              },
                              {
                                type: "email",
                                message: "Enter a valid email!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                              placeholder="Enter Admin Email"
                              value={formData.branchadminemail}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Branch Admin Mobile Number"
                            name="branchadminmobilenumber"
                            rules={[
                              {
                                required: true,
                                message: "Admin Mobile Number is required!",
                              },
                              {
                                pattern: /^\d{10}$/,
                                message:
                                  "Enter a valid 10-digit contact number!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Mobile Number"
                              minLength="10"
                              maxLength="13"
                              value={formData.branchadminmobilenumber}
                              onChange={handleInputChange}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      {/* Dynamic Fields */}
                      {dynamicFields.map((field) => (
                        <Row
                          gutter={16}
                          key={field.id}
                          style={{ marginBottom: "16px" }}
                        >
                          <Col span={12}>
                            <Form.Item
                              label="Branch Name"
                              name="branchname"
                              rules={[
                                {
                                  required: true,
                                  message: "Branch Name is required!",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Enter Branch Name"
                                value={formData.branchname}
                                onChange={handleInputChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Branch Address"
                              name="branchaddress"
                              rules={[
                                {
                                  required: true,
                                  message: "Branch Address is required!",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Enter Branch Address"
                                value={formData.branchaddress}
                                onChange={handleInputChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Branch Contact Number"
                              name="branchcontactnumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Branch Contact Number is required!",
                                },
                                {
                                  pattern: /^\d{10}$/,
                                  message:
                                    "Enter a valid 10-digit contact number!",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Enter Contact Number"
                                value={formData.branchcontactnumber}
                                onChange={handleInputChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Branch Admin Name"
                              name="branchAdminName"
                              rules={[
                                {
                                  required: true,
                                  message: "Branch Admin Name is required!",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Enter Admin Name"
                                value={formData.country}
                                onChange={handleInputChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Branch Admin Email"
                              name="branchadminemail"
                              rules={[
                                {
                                  required: true,
                                  message: "Branch Admin Email is required!",
                                },
                                {
                                  type: "email",
                                  message: "Enter a valid email!",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                                placeholder="Enter Admin Email"
                                value={formData.branchadminemail}
                                onChange={handleInputChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Branch Admin Mobile Number"
                              name="branchadminmobilenumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Admin Mobile Number is required!",
                                },
                                {
                                  pattern: /^\d{10}$/,
                                  message:
                                    "Enter a valid 10-digit contact number!",
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Enter Mobile Number"
                                minLength="10"
                                maxLength="13"
                                value={formData.branchadminmobilenumber}
                                onChange={handleInputChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={24}
                            style={{
                              textAlign: "right",
                              position: "relative",
                              top: "20px",
                            }}
                          >
                            {/* <div
                            className="addiconreg"
                            style={{ marginLeft: "500px", marginTop: " -38px" }}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              style={{
                                fontSize: "20px",
                                color: "#00ADC4",
                                cursor: "pointer",
                              }}
                              onClick={addField}
                            />
                          </div> */}
                            <div>
                              <MinusCircleOutlined
                                style={{ fontSize: "20px", color: "red" }}
                                onClick={() => removeField(field.id)}
                              />
                            </div>
                          </Col>
                        </Row>
                      ))}

                      {/* Add Button */}
                      <Row>
                        <div
                          className="addiconreg"
                          style={{ marginLeft: "500px", marginTop: " -20px" }}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{
                              fontSize: "20px",
                              color: "#00ADC4",
                              cursor: "pointer",
                            }}
                            onClick={addField}
                          />
                        </div>
                      </Row>
                    </Form>
                  </div>
                )}

                <div style={{ marginTop: "10px", textAlign: "center" }}>
                  {currentStep > 0 && (
                    <Button
                      className="regprebtng"
                      style={{}}
                      onClick={() => setCurrentStep(currentStep - 1)}
                    >
                      First
                    </Button>
                  )}
                  {currentStep < 2 && (
                    <>
                      <Button
                        type="primary"
                        onClick={onNext}
                        className="regprebtng"
                      >
                        Next
                      </Button>
                      <Link to="/">
                        <Button type="primary" className="regprebtng">
                          Login
                        </Button>
                      </Link>
                    </>
                  )}
                  {currentStep === 2 && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="regprebtng"
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </Col>

          {/* Right Side */}
          <Col
            className="login-right-img"
            xs={24} // Full width on extra-small screens
            sm={12} // Half width on small screens and above
            style={{
              backgroundColor: " #f0f2f5", // White for right side
              height: "100%",
              padding: "10px",
            }}
          >
            <div
              style={{
                height: "100%",
                background: " #00535E",
                borderRadius: "10px",
                boxshadow: "18px 18px 30px 0px #D1D9E640",
              }}
            ></div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default RegistrationForm;
